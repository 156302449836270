import {environment as defaultEnvironment} from './environment.default';

export const environment = {
  ...defaultEnvironment,
  env: 'dev',
  production: false,
  apiUrlV3:        'https://staging---api-ccx5mqu6oa-ue.a.run.app/api/v3',
  billingApiUrl:   'https://billing-api-ccx5mqu6oa-ue.a.run.app/api',
  apiUrl:          'https://staging-dot-gmb-review-dashboard.uc.r.appspot.com/api',
  coreApiUrl:      'https://core-api-seconday-156411061417.us-east1.run.app',
  queuesEnabled: false,
  saveLocationInChain: false
};
