// dep
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, ReplaySubject, Subscription as subs, forkJoin } from 'rxjs';

// app
import { ReviewsService } from '../../services/reviews.service';
import { DataPicker } from '../../constants/data-picker';
import { LocationService } from '../../services/location.service';
import { Pagination } from '../../constants/api-response';
import { ReportService } from '../../services/report.service';
import { SnackbarService } from '../../services/snackbar.service';
import { EventMessageService } from 'src/app/services/event-message.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { LoadingService } from '../../services/loading.service';
import { LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";
import { EventNames } from '../../constants/event-names';
import { LocationRef } from 'src/app/constants/firestore/location-object';
import { SessionService } from 'src/app/services/session.service';


// TODO: Unused, remove
// export interface ReviewKeywords {
//   id: number;
//   text: string;
//   weight: number;
//   score: number;
// }

@Component({
  selector: 'app-dashboard-reviews',
  templateUrl: './dashboard-reviews.component.html',
  styleUrls:  ['./dashboard-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardReviewsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() reportName: string;
  @Input() isShared = false;
  @Input() lockDates = false;
  @Input() reportType = 'review';
  @Input() viewModeChecked = 'legacy';
  @Input() reportId: string;
  @Input() report
  @Input() minDate = null;
  @Input() maxDate = null;

  public exporting = false;
  public locationId: string;
  public reviewsStats: any = null;
  public dataPicker: DataPicker;
  public isReportListAccordionOpen = false;
  public refreshFilter = new ReplaySubject<DataPicker>(1)
  private resume = new Subject<any>();
  // progress events

  public isProgressResume = true;
  public isProgressDistribution = true;

  public loadingMuchData = false;
  public loadingAvereageAndReview = false; 

  public pagination: Pagination;
  public noData: boolean;

  public rating: any = null;
  public isReport: boolean;
  public differenceTotal: number;
  public differenceAverage: number;
  private first = true;

  public reviewsData = {};
  public averageData = {};

  // subscription by close when OnDestroy
  private googleReviewsWithLastMonth$: subs;
  private ratingDistribution$: subs;

  private keywordsByStat$: subs;
  public selectDate;
  public location;
  public isProgressRating: boolean;
  public total: any;
  public accountId: string;
  public locations: LocationRef[];
  public gid: string;
  public isProgressCSV: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _reviewsS: ReviewsService,
    private _locationS: LocationService,
    private _sessionS : SessionService, 
    private _reportS: ReportService,
    private _cdRef: ChangeDetectorRef,
    private _snackS: SnackbarService,
    private _em: EventMessageService,
    private _modalService: ModalService,
    private _subscriptionService: SubscriptionService,
    private _loadingService: LoadingService
  ) {
    this.locationId = this._route.snapshot.parent.params.locationId;
    this.accountId  = this._route.parent.snapshot.params.accountId;
    this.locations = [{ accountId: this.accountId, 
                       locationId: this.locationId }];
  }

  async ngOnInit() {
    if (this.reportDataPicker) {
      this.gid = this._route.snapshot.params.gid;
      this.isReport = true;
      this.locations = this.reportDataPicker.locations;
      this.reportDataPicker.aggregation = this._reportS.correctDateAggregate(this.reportDataPicker);
      this.dataPicker = this.reportDataPicker;
      this.selectDate = this._reportS.selectDatesFromDataPicker(this.dataPicker);
    } else {
      this.gid = this._sessionS.getSession().gid;
      const dateValidations = await this._locationS.getDateValidations('review', [this.accountId], [this.gid], [this.locationId]);
      const dates = this._locationS.dateValidation(dateValidations);
      this.minDate = dates.minDate;
      this.maxDate = dates.maxDate;

      const {subscriptionType} = (await this._locationS.fetchMultipleLocations(this.gid, 
                                                                              [{accountId  : this.accountId,
                                                                                locationId : this.locationId}], 
                                                                              ['subscriptionType'] as ['subscriptionType']))[0]

      this.selectDate = (
        this.minDate && this.maxDate && (subscriptionType != LOCATION_SUBSCRIPTION_TYPE.FREE && 
                                         subscriptionType != LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) ? 
        { start: this.minDate, 
          end: this.maxDate } :
        this._locationS.buildDatepickerDate(this.reportType, this.maxDate)
      );
    }
    this._em.getEmitter().subscribe(res => {
      if (res.name != EventNames.EVENT_DATEPICKER_SUBSCRIPTION) {
        return;
      }

      const {group} = this._sessionS.getSession();
      const dateDismiss = group.dismissModalDatePicker || new Date();

      if (new Date() >= dateDismiss) {
        this._modalService.openModal(FeaturedDatePickerComponent,
          { 
            accountId: this.accountId,
            locationId: this.locationId
          },
          { onClose : res => {
            if (!res)
              return;
            console.log('DashboardReviewsComponent upgrade suscription');
            this._subscriptionService.flowChangeLocationsPlan([this.locations[0]]);
        }})
      }

    });
  }

  /**
   * this method handle event change in date picker and get data
   */
  handleChange($event) : void {
    if (this.isReport && this.first) {
      this.getReviewData(this.dataPicker);
      this.first = false;
    } else {
      this.dataPicker = $event;
      this.getReviewData(this.dataPicker);  
    }

    this.refreshFilter.next(this.dataPicker);
  }

  // TODO: Refactor against protocol-report.component.ts / resumeManyLocations
  /**
   * this method get all data of charts when change date picker
   */
  getReviewData(dataPicker: DataPicker, refresh = false) : void {
    this.isProgressResume = true;
    this.isProgressDistribution = true;
    this.loadingAvereageAndReview = true;
    
    if (!this.locations || !this.dataPicker) {
      return;
    }

    
    this.dataPicker.aggregation = this.report?.aggregation || dataPicker?.aggregation;
    const url = this.isReport ? this._reviewsS.getRatingsAndReviewsReportData(this.reportId, this.dataPicker) : this._reviewsS.getRatingsAndReviewsData(this.accountId, this.gid, this.locationId, this.dataPicker);
    const accountsId = !this.isReport ? [this.accountId] : this.report.accounts.map(item => item?.accountId);
    const locationsId =! this.isReport ? [this.locationId] : this.report.accounts.flatMap(item => item?.locations.map(location => location.locationId));

    forkJoin([
      this._locationS.getReviewSummary(this.gid, accountsId, locationsId),
      this._reviewsS.ratingDistribution(this.locations).take(2),
      url
    ]).subscribe(
      result => {
        this.reviewsStats = { 
          averageRating    : result[0]?.totalAverageRating,
          totalReviewCount : result[0]?.totalReviewCount,
          answered         : result[0]?.totalAnswered,
          notAnswered      : result[0]?.totalNotAnswered,
          googleResume     : { 
            totalReviewCount : result[0]?.googleResume_totalReviewCount 
          }
        }
        this.differenceAverage = result[0]?.difference_totalAverage;
        this.differenceTotal = result[0]?.difference_totalTotal;
        this.rating = result[1].data;
        this._loadingService.reportAdvanced(1, 'Rating Distribution');
        this.reviewsData = {
          data: result[2].reviews,
          labels: result[2].dates,
          total: result[2].total_reviews
        };
        this.averageData = {
          data: result[2].avg_rating,
          labels: result[2].dates,
          total: result[2].total_avg
        };
        this._loadingService.reportAdvanced(1, 'Review Summary')
        refresh && this._snackS.openSuccess('Finished refreshing! ', 2000);
        this.loadingAvereageAndReview = false;
        this.isProgressDistribution = false;
        this.isProgressResume = false;
      },
      error => {
        this.isProgressDistribution = false;
        this.isProgressResume = false;
      })
  }

  ngAfterViewChecked() : void {
    this.detectChanges();
  }

  detectChanges () : void{
    if (!(this._cdRef as ViewRef).destroyed)
         this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.ratingDistribution$?.unsubscribe();
    this.keywordsByStat$?.unsubscribe();
    this.googleReviewsWithLastMonth$?.unsubscribe();
    // FIXME: use this.resume.complete()
    this.resume.unsubscribe();
  }

  handleRefresh(): void {
    this.getReviewData(this.dataPicker, true);
  }

  isExporting(value : boolean) : void {
    this.exporting = value;
  }

  async exportCSV(event) : Promise<void> {
    if (event != 'csv' && event != 'csv-review') return;

    const reportId = this.isReport ? this.reportId : null;
    const accountId = !this.isReport ? this.accountId : null
    const locationId = !this.isReport ? this.locationId : null
    this.isProgressCSV = true;
    const type = event === 'csv' ? 'review' : 'review_list';

    await this._reportS.handleExportCsvMongo(
      reportId,
      this.gid,
      accountId,
      locationId, 
      this.dataPicker.range.start,
      this.dataPicker.range.end,
      this.dataPicker.aggregation,
      type
    );

    this.isProgressCSV = false;
  }
}
