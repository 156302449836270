import { __awaiter, __generator, __read, __spread } from "tslib";
// dep
import { OnInit, EventEmitter, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
// app
import { PostService } from '../../../services/post.service';
import { GROUP_SUBSCRIPTION_TYPE } from '../../../constants/firestore/account-location';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AlertType } from 'src/app/components/alert.component';
import { ModalService } from 'src/app/services/modal.service';
import { StorageImageService } from 'src/app/services/storage-image.service';
import { PostManagementDeleteDateModalComponent } from 'src/app/modules/post-management/post-management-delete-date-modal/post-management-delete-date-modal.component';
import { SessionService } from 'src/app/services/session.service';
var PostManagementListComponent = /** @class */ (function () {
    function PostManagementListComponent(_sessionS, _postS, _storageImageS, _cdRef, _snackS, _modalS, _dialog) {
        this._sessionS = _sessionS;
        this._postS = _postS;
        this._storageImageS = _storageImageS;
        this._cdRef = _cdRef;
        this._snackS = _snackS;
        this._modalS = _modalS;
        this._dialog = _dialog;
        this.ColumnsSize = [5, 40, 10, 10, 5, 5, 10];
        this.focus = new BehaviorSubject(0);
        this.postDrawer = {};
        this.updateList = false;
        this.isImpersonating = false;
        this.isMember = false;
        this.isSpinner = new EventEmitter();
        this.selected = new EventEmitter();
        this.editElement = new EventEmitter();
        this.displayedColumns = ['image', 'frequency', 'date', 'status', 'action'];
        this.isLoading = false;
        this.loadingPost = true;
        this.isBulkPost = true;
        this.allPostChecked = false;
        this.dataSource = new MatTableDataSource([]);
        this.paginate = { page: 1, size: 10 };
        this.sort = {
            sortBy: 'updatedAt',
            direction: 'desc',
        };
        this.statusFilterValue = 'all';
        this.statusFilter = { status: [], bulkStatus: [] };
        this.ocurrenceFilterValue = 'null';
        this.ocurrenceFilter = null;
        this.noDataMessage = { message: 'No Posts Added Yet', description: null };
        this.GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
        this.statusData = [];
        this.postId = null;
        this.isStatusOpened = false;
        this.postTitle = null;
        this.selectedPosts = [];
        this.session$ = this._sessionS.session$;
    }
    PostManagementListComponent.prototype.ngOnInit = function () {
        this._gid = this._sessionS.getSession().gid;
        this.isBulkPost = (this.dashboardType !== 'LOCAL_POST');
        this.statusFilter = this.allFilter;
        this._cdRef.detectChanges();
        this.getData();
    };
    PostManagementListComponent.prototype.getStatusClass = function (status) {
        status = status.toUpperCase();
        var statusClass = 'status--gray';
        if (status === 'SCHEDULED') {
            statusClass = 'status--blue';
        }
        else if (status === 'PARTIAL' || status === 'FLAGGED_FOR_DELETE' || status === 'CONTACT_SUPPORT') {
            statusClass = 'status--yellow';
        }
        else if (status === 'SUCCESS') {
            statusClass = 'status--green';
        }
        else if (status === 'FAILED' || status === 'REJECTED' || status === 'DELETED' || status === 'FINISHED') {
            statusClass = 'status--red';
        }
        return statusClass;
    };
    PostManagementListComponent.prototype.statusLabel = function (status) {
        return this._postS.getStatusLabel(status);
    };
    PostManagementListComponent.prototype.getData = function () {
        var _this = this;
        this.loadingPost = true;
        this.dataSource = new MatTableDataSource([]);
        this._postS.getPostList(this._gid, this.isBulkPost, this.paginate, this.sort, this.statusFilter, this.ocurrenceFilter, this.accountId, this.locationId).subscribe(function (result) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            (_b = (_a = result) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.items.forEach(function (el) { return el.is_checked = false; });
            _this.dataSource = new MatTableDataSource((_d = (_c = result) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.items);
            _this.pagination = (_e = result) === null || _e === void 0 ? void 0 : _e.data;
            _this.pagination.per_page = (_g = (_f = result) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.pageSize;
            _this.pagination.pages = (_j = (_h = result) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.totalPages;
            _this.noDataMessage = (!((_l = (_k = _this.dataSource) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.length) && _this.dashboardType === 'ARCHIVE' ?
                { message: "No posts finished or deleted yet.", description: null } :
                !((_o = (_m = _this.dataSource) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.length) && _this.isFiltered ?
                    { message: "No results found.", description: "Adjust your filters or select the \u201CAll\u201D option to view everything." } :
                    { message: "No posts added yet.", description: null });
            _this.loadingPost = false;
        }, function (error) {
            _this.dataSource = new MatTableDataSource([]);
            _this.noDataMessage = { message: 'Loading failed', description: 'Content isn’t loading right now. Refresh to try again.' };
            _this.loadingPost = false;
        });
    };
    PostManagementListComponent.prototype.changedOccurenceFilter = function (occurence) {
        var _a, _b, _c;
        this.ocurrenceFilter = ((_a = occurence) === null || _a === void 0 ? void 0 : _a.value) === 'null' ? null : (_b = occurence) === null || _b === void 0 ? void 0 : _b.value;
        this.ocurrenceFilterValue = (_c = occurence) === null || _c === void 0 ? void 0 : _c.value;
        this.getData();
    };
    Object.defineProperty(PostManagementListComponent.prototype, "allFilter", {
        get: function () {
            var status = {
                status: ['ACTIVE', 'DRAFT', 'FLAGGED_FOR_DELETE', 'CONTACT_SUPPORT'],
                bulkStatus: ['SUCCESS', 'DRAFT', 'FAILED', 'SCHEDULED', 'FLAGGED_FOR_DELETE', 'CONTACT_SUPPORT']
            };
            if (this.isBulkPost) {
                status.bulkStatus.push('PARTIAL');
            }
            return status;
        },
        enumerable: true,
        configurable: true
    });
    PostManagementListComponent.prototype.changedStatusFilter = function (event) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.statusFilterValue = (_a = event) === null || _a === void 0 ? void 0 : _a.value;
        var value = (_c = (_b = event) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.split('.');
        var status = (_d = value) === null || _d === void 0 ? void 0 : _d[0];
        var bulkStatus = (_e = value) === null || _e === void 0 ? void 0 : _e[1];
        this.statusFilter = {
            status: status != 'all' ? [status] : (_f = this.allFilter) === null || _f === void 0 ? void 0 : _f.status,
            bulkStatus: bulkStatus ? [bulkStatus] : (_g = this.allFilter) === null || _g === void 0 ? void 0 : _g.bulkStatus
        };
        this.getData();
    };
    PostManagementListComponent.prototype.edit = function (element) {
        var _this = this;
        var _a;
        this._postS.getPost((_a = element) === null || _a === void 0 ? void 0 : _a._id).subscribe(function (res) {
            var _a, _b;
            _this.editElement.emit((_b = (_a = res) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]);
        });
    };
    PostManagementListComponent.prototype.copyPost = function (element) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        this.loadingPost = true;
        if (((_b = (_a = element) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.media) && ((_e = (_d = (_c = element) === null || _c === void 0 ? void 0 : _c.post) === null || _d === void 0 ? void 0 : _d.media[0]) === null || _e === void 0 ? void 0 : _e.sourceUrl)) { // if the post has media attached to it
            // subscription that should get executed if/when the validations and storage in firestore finish successfuly
            var mediaUrlSubscription_1 = this._storageImageS.multipleMediaUrl$
                .subscribe(function (value) {
                if (value && value.length) {
                    _this._postS.copyPost(element, value[0].url)
                        .subscribe(function (response) {
                        _this.getData();
                        mediaUrlSubscription_1.unsubscribe();
                        _this._storageImageS.reset();
                    }, function (error) {
                        _this.loadingPost = false;
                        _this._snackS.openError('There was an error while copying the post', 2000);
                        mediaUrlSubscription_1.unsubscribe();
                        _this._storageImageS.reset();
                    });
                }
            });
            this._postS.storageImgInFirestore(element)
                .subscribe(function (file) {
                var dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                var inputElement = document.createElement('input');
                inputElement.type = 'file';
                inputElement.files = dataTransfer.files;
                _this._storageImageS.fileChanged(inputElement, false);
            }, function (error) {
                console.error('Error fetching the image:', error);
            });
        }
        else { // if no media is attached to the post
            this._postS.copyPost(element)
                .subscribe(function (response) {
                _this.getData();
            }, function (error) {
                _this.loadingPost = false;
                _this._snackS.openError('There was an error while copying the post', 2000);
            });
        }
        // old functionality
        // this._postS.copyPost(element).subscribe(
        //   res => {
        //     this.getData();
        //   },
        //   err => {
        //     this.loadingPost = true;
        //     this._snackS.openError('There was an error while copying the post', 2000);
        //   }
        // );
    };
    PostManagementListComponent.prototype.getRecurringData = function (element) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        console.log(element);
        var value = '';
        switch ((_b = (_a = element) === null || _a === void 0 ? void 0 : _a.scheduleRules) === null || _b === void 0 ? void 0 : _b.type) {
            case 'DAILY':
                return "On " + this._postS.getDaysUnified((_e = (_d = (_c = element) === null || _c === void 0 ? void 0 : _c.scheduleRules) === null || _d === void 0 ? void 0 : _d.publishRule) === null || _e === void 0 ? void 0 : _e.daysOfWeek);
            case 'WEEKLY':
                return "On " + this._postS.getDaysUnified((_h = (_g = (_f = element) === null || _f === void 0 ? void 0 : _f.scheduleRules) === null || _g === void 0 ? void 0 : _g.publishRule) === null || _h === void 0 ? void 0 : _h.daysOfWeek);
            case 'MONTHLY':
                var value_1 = (_l = (_k = (_j = element) === null || _j === void 0 ? void 0 : _j.scheduleRules) === null || _k === void 0 ? void 0 : _k.publishRule) === null || _l === void 0 ? void 0 : _l.daysOfMonth[0];
                var day = value_1 === -1 ? 'last day' : this._postS.getOrdinalNumbers(value_1);
                return "On the " + day + " of the month";
        }
        return value;
    };
    PostManagementListComponent.prototype.infoAboutStatus = function (element) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.getInfoRejected(element)];
                    case 1:
                        data = _c.sent();
                        if (data) {
                            this._modalS.openInfoModal((_a = data) === null || _a === void 0 ? void 0 : _a.title, (_b = data) === null || _b === void 0 ? void 0 : _b.msg, 'Ok', true, false);
                            this.isLoading = false;
                        }
                        else {
                            this.isLoading = false;
                            this._snackS.openError('There was an error while loading the data. Please try again or contact support');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PostManagementListComponent.prototype.getInfoRejected = function (element) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var _a, _b;
            _this._postS.getInfoRejected((_a = element) === null || _a === void 0 ? void 0 : _a['_id'], (_b = element) === null || _b === void 0 ? void 0 : _b.postInstanceId).subscribe(function (res) {
                var _a, _b, _c;
                var data = { msg: _this._postS.getModalText(res), title: ((_c = (_b = (_a = res) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.type) + " Failed" };
                resolve(data);
            }, function (err) {
                reject(err);
            });
        });
    };
    PostManagementListComponent.prototype.toggleCheckAll = function (event) {
        var _this = this;
        var _a;
        var isBulk = this.isBulkPost;
        var posts = [];
        this.allPostChecked = (_a = event) === null || _a === void 0 ? void 0 : _a.checked;
        if (event.checked) {
            this.dataSource.data.map(function (el) {
                var _a, _b, _c;
                if (isBulk || !isBulk && !((_b = (_a = el) === null || _a === void 0 ? void 0 : _a['scheduleRules']) === null || _b === void 0 ? void 0 : _b.isBulk)) {
                    el['isChecked'] = (_c = event) === null || _c === void 0 ? void 0 : _c.checked;
                    posts.push(el._id);
                    _this.selectedPosts = __spread(new Set(posts));
                }
            });
        }
        else {
            this.dataSource.data.map(function (el) { var _a; return el['isChecked'] = (_a = event) === null || _a === void 0 ? void 0 : _a.checked; });
            this.selectedPosts = [];
        }
    };
    PostManagementListComponent.prototype.selectPost = function (element, event) {
        var _a;
        if ((_a = event) === null || _a === void 0 ? void 0 : _a.checked) {
            this.selectedPosts.push(element._id);
        }
        else {
            var index = this.selectedPosts.findIndex(function (el) { return el === element._id; });
            this.selectedPosts.splice(index, 1);
        }
        var totalOfSelected = this.dataSource.data.filter(function (el) { return el['isChecked']; }).length;
        this.allPostChecked = this.dataSource.data.length === totalOfSelected;
    };
    PostManagementListComponent.prototype.deletePosts = function (element, isMultiplePost) {
        var _this = this;
        var message = (isMultiplePost ?
            "Are you sure you want to delete the selected posts? This action cannot be undone." :
            "Are you sure you want to delete this post? This action cannot be undone.");
        this._modalS.openAlertModal("Attention", message, AlertType.ERROR, null, 'Delete Post', (function (response) {
            if (response) {
                _this.openDeleteDateModal(element, isMultiplePost);
            }
        }), 'accent');
    };
    PostManagementListComponent.prototype.openDeleteDateModal = function (element, isMultiplePost) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        var isRecurring = !isMultiplePost ? (_b = (_a = element) === null || _a === void 0 ? void 0 : _a['scheduleRules']) === null || _b === void 0 ? void 0 : _b.isRecurring : (_d = (_c = this.dataSource.data.find(function (el) { return el['isChecked']; })) === null || _c === void 0 ? void 0 : _c['scheduleRules']) === null || _d === void 0 ? void 0 : _d.isRecurring;
        var dialogRef = this._dialog.open(PostManagementDeleteDateModalComponent, {
            width: '450px',
            data: {
                postIds: isMultiplePost ? element : [(_e = element) === null || _e === void 0 ? void 0 : _e['_id']],
                isMultiplePost: isMultiplePost,
                irRecurring: isRecurring
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res) {
                _this.getData();
            }
        });
        dialogRef.disableClose = true;
    };
    PostManagementListComponent.prototype.retryPost = function (element) {
        var _this = this;
        this._postS.retryPost(element, function () {
            _this.getData();
        });
    };
    PostManagementListComponent.prototype.openStatus = function (element, drawer) {
        var _this = this;
        if (element === void 0) { element = {}; }
        var _a;
        if (this.isStatusOpened) {
            return;
        }
        this.isStatusOpened = true;
        this._postS.getStatusPost((_a = element) === null || _a === void 0 ? void 0 : _a['_id']).subscribe(function (res) {
            var _a, _b, _c, _d, _e;
            _this.highlightCell = (_a = element) === null || _a === void 0 ? void 0 : _a['_id'];
            _this.statusData = (_b = res) === null || _b === void 0 ? void 0 : _b.data;
            _this.postId = (_c = element) === null || _c === void 0 ? void 0 : _c['_id'];
            _this.postTitle = ((_e = (_d = element) === null || _d === void 0 ? void 0 : _d.post) === null || _e === void 0 ? void 0 : _e.title) || null;
            drawer.toggle();
        }, function (err) {
            _this.isStatusOpened = false;
        });
    };
    PostManagementListComponent.prototype.closeStatus = function (needsRefresh, drawer) {
        this.highlightCell = null;
        this.isStatusOpened = false;
        this.statusData = [];
        this.postId = null;
        drawer.toggle();
        this._cdRef.detectChanges();
        if (needsRefresh) {
            this.getData();
        }
    };
    PostManagementListComponent.prototype.shouldHighlightCell = function (element) {
        var _a;
        return this.highlightCell === ((_a = element) === null || _a === void 0 ? void 0 : _a['_id']);
    };
    PostManagementListComponent.prototype.isSelected = function (element) {
        var _a;
        return (_a = element) === null || _a === void 0 ? void 0 : _a.isChecked;
    };
    PostManagementListComponent.prototype.handleReload = function ($event) {
        this.paginate = $event;
        this.getData();
    };
    PostManagementListComponent.prototype.ngOnChanges = function (changes) {
        var _a, _b, _c, _d, _e;
        if ((_b = (_a = changes) === null || _a === void 0 ? void 0 : _a.updateList) === null || _b === void 0 ? void 0 : _b.currentValue) {
            this.updateList = null;
            this.getData();
        }
        if (((_c = changes) === null || _c === void 0 ? void 0 : _c.dashboardType) && ((_e = (_d = changes) === null || _d === void 0 ? void 0 : _d.dashboardType) === null || _e === void 0 ? void 0 : _e.previousValue)) {
            this.statusFilter.status = (this.dashboardType !== 'ARCHIVE' ? this.allFilter.status :
                this.isBulkPost ?
                    ['DELETED', 'FINISHED', 'ACTIVE', 'FLAGGED_FOR_DELETE'] :
                    null);
            this.statusFilter.bulkStatus = this.dashboardType !== 'ARCHIVE' ? this.allFilter.bulkStatus : ["DELETED", "FINISHED"];
            this.displayedColumns = this.dashboardType !== 'ARCHIVE' ? ['image', 'frequency', 'date', 'status', 'action'] : ['image', 'frequency', 'date', 'status'];
            this.dataSource = new MatTableDataSource([]);
            this.getData();
        }
    };
    Object.defineProperty(PostManagementListComponent.prototype, "isFiltered", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            var statusFiltered = (this.dashboardType === 'ARCHIVE' ? ((_b = (_a = this.statusFilter) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.includes('DELETED')) && ((_d = (_c = this.statusFilter) === null || _c === void 0 ? void 0 : _c.status) === null || _d === void 0 ? void 0 : _d.includes('FINISHED')) :
                ((_f = (_e = this.statusFilter) === null || _e === void 0 ? void 0 : _e.status) === null || _f === void 0 ? void 0 : _f.includes('ACTIVE')) && ((_h = (_g = this.statusFilter) === null || _g === void 0 ? void 0 : _g.status) === null || _h === void 0 ? void 0 : _h.includes('DRAFT')) && ((_k = (_j = this.statusFilter) === null || _j === void 0 ? void 0 : _j.status) === null || _k === void 0 ? void 0 : _k.includes('FLAGGED_FOR_DELETE'))
                    && ((_m = (_l = this.statusFilter) === null || _l === void 0 ? void 0 : _l.status) === null || _m === void 0 ? void 0 : _m.includes('CONTACT_SUPPORT')));
            return statusFiltered && this.ocurrenceFilter === null ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManagementListComponent.prototype, "isDeleteButtonEnabled", {
        get: function () {
            /* It will be added later
            const selectedPosts = this.dataSource.data.filter(el => el['isChecked']);
            const firstIsRecurringValue = selectedPosts[0]?.['scheduleRules']?.isRecurring;
            const allAreSame = selectedPosts.every(el => el?.['scheduleRules']?.isRecurring === firstIsRecurringValue);
        
            return !(selectedPosts.length > 0 && allAreSame);*/
            var _a;
            return ((_a = this.dataSource.data.filter(function (el) { var _a; return ((_a = el) === null || _a === void 0 ? void 0 : _a['bulkStatus']) !== 'FLAGGED_FOR_DELETE'; })) === null || _a === void 0 ? void 0 : _a.length) === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PostManagementListComponent.prototype, "countOfSelectedItems", {
        get: function () {
            var _a;
            return (_a = this.dataSource.data.filter(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a['isChecked']; })) === null || _a === void 0 ? void 0 : _a.length;
        },
        enumerable: true,
        configurable: true
    });
    PostManagementListComponent.prototype.refresh = function () { };
    return PostManagementListComponent;
}());
export { PostManagementListComponent };
