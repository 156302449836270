import { __awaiter, __generator } from "tslib";
// dep
import { AfterViewChecked, ChangeDetectorRef, OnDestroy, OnChanges, OnInit, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
// app
import { ReportService } from '../../services/report.service';
import { COMPARISON_LIST, COMPARISON_LIST_DEFAULT, DATAPICKER_REPORT_DEFAULT } from '../../constants/firestore/report';
import { DatesService } from '../../services/dates.service';
import { VAR_MONTH_PICKER_YEAR_AGO } from '../../constants/datePicker';
import { alwaysShowCalendar } from 'src/app/helpers/syncfusion.helpers';
import { QuestionsAnswerService } from 'src/app/services/questions-answer.service';
import { ModalService } from 'src/app/services/modal.service';
import { periodDaysQanda } from 'src/app/constants/periodDaysQanda';
import { LocationService } from 'src/app/services/location.service';
import { SessionService } from 'src/app/services/session.service';
var SlideCreateReportComponent = /** @class */ (function () {
    function SlideCreateReportComponent(reportS, _sessionS, fb, dateS, changeDetector, router, questionsS, modalService, locationS, el, renderer2) {
        this.reportS = reportS;
        this._sessionS = _sessionS;
        this.fb = fb;
        this.dateS = dateS;
        this.changeDetector = changeDetector;
        this.router = router;
        this.questionsS = questionsS;
        this.modalService = modalService;
        this.locationS = locationS;
        this.el = el;
        this.renderer2 = renderer2;
        this.viewModeChecked = 'legacy';
        this.reportData = {};
        this.drawer = {};
        this.isSliderOpened = false;
        this.closeSliderReport = new EventEmitter();
        this.montPicker = JSON.parse(JSON.stringify(VAR_MONTH_PICKER_YEAR_AGO));
        this.step = 0;
        this.stepTotal = 0;
        this.rangeBDisabled = true;
        this.dataPicker = DATAPICKER_REPORT_DEFAULT;
        this.rangeAPeriod = -1;
        this.rangeBPeriod = -1;
        this.compareList = COMPARISON_LIST;
        this.defaultSelected = COMPARISON_LIST_DEFAULT;
        this.accountsReport = [];
        this.metricsChecked = [];
        this.DynamicRangeControl = new FormControl(null);
        this.FrecuencyControl = new FormControl('WeeklyOn', [Validators.required]);
        this.RecipentsControl = new FormControl('', [Validators.email]);
        this.RecipentsEmails = [];
        this.repeatOn = '0';
        this.dynamicRangeOptions = [
            { value: 'LastMonth', displayName: 'Last Month' },
            { value: 'Last30', displayName: 'Last 30 Days' },
            { value: 'Last90', displayName: 'Last 90 Days' },
            { value: 'YTD', displayName: 'Year to Date' }
        ];
        this.minDate = null;
        this.maxDate = null;
        this.metricVerification = false;
        this.showTooltipsOnDisabledDates = false;
    }
    SlideCreateReportComponent.prototype.ngOnInit = function () {
        this.initSteps();
    };
    SlideCreateReportComponent.prototype.ngOnChanges = function () {
        var _a, _b;
        this.reportType = this.reportData.reportType;
        this.reportEdit = null;
        var content = document.getElementsByClassName('content');
        if (content.length > 0) {
            this.renderer2.addClass(content[0], 'pr--0');
        }
        this.initSteps();
        if (!this.isEdit) {
            if (this.reportType === 'keyword') {
                this.handleDateSelected({ startDate: (_a = this.montPicker) === null || _a === void 0 ? void 0 : _a.lastYearStart, endDate: (_b = this.montPicker) === null || _b === void 0 ? void 0 : _b.lastYearEnd });
            }
            else {
                this.handleDateSelected(this.dataPicker);
            }
        }
        else {
            if (this.reportType === 'keyword') {
                this.handleDateSelected(this.dataPicker);
            }
        }
        if (!this.isSliderOpened) {
            this.ngOnDestroy();
        }
    };
    SlideCreateReportComponent.prototype.initSteps = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var reportType = ((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('comparison')) ? 'comparison' : this.reportType;
        this.showTooltipsOnDisabledDates = this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison';
        switch (reportType) {
            case 'keyword':
                this.dynamicRangeOptions = [
                    { value: 'OneMonth', displayName: 'Last Month' },
                    { value: 'Last3Month', displayName: 'Last 3 Months' },
                    { value: 'OneYear', displayName: 'Year to Date' }
                ];
                break;
            case 'comparison':
                this.dynamicRangeOptions = [
                    { value: 'LastMonth-PrevMonth', displayName: 'Last month vs Previous Month' },
                    { value: 'LastMonth-YearAgoMonth', displayName: 'Last Month vs Same Month Last Year' },
                    { value: 'Last90-PrevLast90', displayName: 'Last 90 days vs Previous 90 days' }
                ];
                break;
            case 'qanda':
                this.dynamicRangeOptions = periodDaysQanda;
                break;
        }
        this.maxDateDatePicker = this.viewModeChecked == 'legacy' ? moment('12-31-2022', 'MM-DD-YYYY') : null;
        if ((_b = this.reportData) === null || _b === void 0 ? void 0 : _b.edit) {
            this.reportType = (_c = this.reportData) === null || _c === void 0 ? void 0 : _c.reportType;
            this.isEdit = !!((_d = this.reportData) === null || _d === void 0 ? void 0 : _d.edit);
            this.stepTotal = this.reportType === 'comparison' || this.reportType === 'performance-comparison' ? 3 : 2;
        }
        if ((_e = this.reportData) === null || _e === void 0 ? void 0 : _e.stepLocations) {
            this.step++;
        }
        if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
            var now = new Date().toISOString();
            this.dataPicker.rangeB = { start: now, end: now };
        }
        if (this.isEdit) {
            this.reportEdit = ((_f = this.reportData) === null || _f === void 0 ? void 0 : _f.data) ? (_g = this.reportData) === null || _g === void 0 ? void 0 : _g.data.edit : (_h = this.reportData) === null || _h === void 0 ? void 0 : _h.edit;
            this.reportEdit = this.reportS.convertReportDate(this.reportEdit);
            // FIXME: Fix typing
            // @ts-ignore
            this.selectDate = this.reportS.reportSelectDatesFromReport(this.reportEdit);
            if (this.reportType === 'keyword' && this.dateRange) {
                this.dateRange.startDate = ((_k = (_j = this.reportData) === null || _j === void 0 ? void 0 : _j.edit) === null || _k === void 0 ? void 0 : _k.startDatetime) ? new Date((_m = (_l = this.reportData) === null || _l === void 0 ? void 0 : _l.edit) === null || _m === void 0 ? void 0 : _m.fromDate) : this.montPicker.lastYearStart;
                this.dateRange.endDate = ((_p = (_o = this.reportData) === null || _o === void 0 ? void 0 : _o.edit) === null || _p === void 0 ? void 0 : _p.endDatetime) ? new Date((_r = (_q = this.reportData) === null || _q === void 0 ? void 0 : _q.edit) === null || _r === void 0 ? void 0 : _r.toDate) : this.montPicker.lastYearEnd;
            }
        }
        else {
            this.buildSelectDate();
        }
        this.initForm(this.reportEdit);
    };
    SlideCreateReportComponent.prototype.buildSelectDate = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var dates = this.reportType == 'review' && this.minDate && this.maxDate
            ? { start: this.minDate, end: this.maxDate }
            : this.locationS.buildDatepickerDate(this.reportType, this.maxDate);
        if (!this.isEdit) {
            this.selectDate = {
                start: ((_a = dates) === null || _a === void 0 ? void 0 : _a.start.isBefore(this.minDate)) ? moment(this.minDate) : (_b = dates) === null || _b === void 0 ? void 0 : _b.start,
                end: ((_c = dates) === null || _c === void 0 ? void 0 : _c.end.isAfter(this.maxDate)) ? moment(this.maxDate) : (_d = dates) === null || _d === void 0 ? void 0 : _d.end
            };
        }
        this.dataPicker.range = {
            start: (_f = (_e = this.selectDate) === null || _e === void 0 ? void 0 : _e.start) === null || _f === void 0 ? void 0 : _f.format('YYYY-MM-DD'),
            end: (_h = (_g = this.selectDate) === null || _g === void 0 ? void 0 : _g.end) === null || _h === void 0 ? void 0 : _h.format('YYYY-MM-DD')
        };
        if (this.reportType == 'keyword' && this.minDate && this.maxDate) {
            var start = moment(VAR_MONTH_PICKER_YEAR_AGO.lastYearStart);
            var end = moment(VAR_MONTH_PICKER_YEAR_AGO.lastYearEnd);
            this.montPicker.lastYearStart = start.isBefore(this.minDate) ? this.minDate : VAR_MONTH_PICKER_YEAR_AGO.lastYearStart;
            this.montPicker.lastYearEnd = end.isAfter(this.maxDate) ? this.maxDate : VAR_MONTH_PICKER_YEAR_AGO.lastYearEnd;
        }
        else {
            this.montPicker.lastYearStart = VAR_MONTH_PICKER_YEAR_AGO.lastYearStart;
            this.montPicker.lastYearEnd = VAR_MONTH_PICKER_YEAR_AGO.lastYearEnd;
        }
    };
    SlideCreateReportComponent.prototype.handleDateSelected = function ($event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14;
        var startDate = ((_c = ((_b = (_a = $event) === null || _a === void 0 ? void 0 : _a.range) === null || _b === void 0 ? void 0 : _b.start)) === null || _c === void 0 ? void 0 : _c.includes('T')) ? ((_e = (_d = $event) === null || _d === void 0 ? void 0 : _d.range) === null || _e === void 0 ? void 0 : _e.start).split('T')[0] + "T23:59:59.000Z" : (_g = (_f = $event) === null || _f === void 0 ? void 0 : _f.range) === null || _g === void 0 ? void 0 : _g.start;
        if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
            $event.rangeB.start = ((_k = ((_j = (_h = $event) === null || _h === void 0 ? void 0 : _h.rangeB) === null || _j === void 0 ? void 0 : _j.start)) === null || _k === void 0 ? void 0 : _k.includes('T')) ? ((_m = (_l = $event) === null || _l === void 0 ? void 0 : _l.rangeB) === null || _m === void 0 ? void 0 : _m.start).split('T')[0] + "T23:59:59.000Z" : (_p = (_o = $event) === null || _o === void 0 ? void 0 : _o.rangeB) === null || _p === void 0 ? void 0 : _p.start;
        }
        var dateStart = moment(startDate).utc();
        var dateEnd = moment((_r = (_q = $event) === null || _q === void 0 ? void 0 : _q.range) === null || _r === void 0 ? void 0 : _r.end).utc();
        this.rangeAPeriod = dateEnd.diff(dateStart, 'days');
        if (((_s = $event) === null || _s === void 0 ? void 0 : _s.startDate) && ((_t = $event) === null || _t === void 0 ? void 0 : _t.endDate)) {
            dateStart = (_u = $event) === null || _u === void 0 ? void 0 : _u.startDate;
            dateEnd = (_v = $event) === null || _v === void 0 ? void 0 : _v.endDate;
        }
        if (((_w = $event) === null || _w === void 0 ? void 0 : _w.startDate) && ((_x = $event) === null || _x === void 0 ? void 0 : _x.endDate) && this.reportType === 'keyword' && this.dateRange) {
            this.dateRange.startDate = $event.startDate;
            this.dateRange.endDate = $event.endDate;
        }
        else if (!((_z = (_y = $event) === null || _y === void 0 ? void 0 : _y.range) === null || _z === void 0 ? void 0 : _z.start) && !((_1 = (_0 = $event) === null || _0 === void 0 ? void 0 : _0.range) === null || _1 === void 0 ? void 0 : _1.end) && this.reportType === 'keyword' && this.dateRange) {
            this.dateRange.startDate = ((_3 = (_2 = this.reportData) === null || _2 === void 0 ? void 0 : _2.edit) === null || _3 === void 0 ? void 0 : _3.startDatetime) ? new Date((_5 = (_4 = this.reportData) === null || _4 === void 0 ? void 0 : _4.edit) === null || _5 === void 0 ? void 0 : _5.startDatetime) : (_6 = this.montPicker) === null || _6 === void 0 ? void 0 : _6.lastYearStart;
            this.dateRange.endDate = ((_8 = (_7 = this.reportData) === null || _7 === void 0 ? void 0 : _7.edit) === null || _8 === void 0 ? void 0 : _8.endDatetime) ? new Date((_10 = (_9 = this.reportData) === null || _9 === void 0 ? void 0 : _9.edit) === null || _10 === void 0 ? void 0 : _10.endDatetime) : (_11 = this.montPicker) === null || _11 === void 0 ? void 0 : _11.lastYearEnd;
        }
        this.startDateTime.setValue(dateStart);
        this.endDateTime.setValue(dateEnd);
        // if (this.reportType === 'comparison' || this.reportType ==='performance-comparison') {
        //   const selectChange = new MatSelectChange(null, this.period.value);
        //   this.periodChange(selectChange);
        // }
        if (((_12 = this.form.controls.period) === null || _12 === void 0 ? void 0 : _12.value) == 'month') {
            if (this.isOptionEnabled('month')) {
                this.form.controls.period.setValue('period');
                this.periodChange({ value: 'period' });
            }
        }
        if (this.step > 0) {
            switch ((_13 = this.form.controls.period) === null || _13 === void 0 ? void 0 : _13.value) {
                case 'month':
                    if (this.isOptionEnabled('month')) {
                        this.form.controls.period.setValue('period');
                        this.periodChange({ value: 'period' });
                    }
                    break;
                case 'year':
                    if (this.isOptionEnabled('year')) {
                        this.form.controls.period.setValue('period');
                        this.periodChange({ value: 'period' });
                    }
                    break;
                case 'period':
                    if (this.isOptionEnabled('period')) {
                        this.form.controls.period.setValue('custom');
                        this.periodChange({ value: 'custom' });
                    }
                    else {
                        this.periodChange({ value: 'period' });
                    }
                    break;
            }
        }
        else {
            this.periodChange({ value: (_14 = this.period) === null || _14 === void 0 ? void 0 : _14.value });
        }
    };
    SlideCreateReportComponent.prototype.handleDateBSelected = function ($event) {
        var _a, _b;
        if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
            this.startDateTimeB.setValue($event.rangeB.start);
            this.endDateTimeB.setValue($event.rangeB.end);
            var dateStart = moment((_a = $event) === null || _a === void 0 ? void 0 : _a.rangeB.start).utc();
            var dateEnd = moment((_b = $event) === null || _b === void 0 ? void 0 : _b.rangeB.end).utc();
            this.rangeBPeriod = dateEnd.diff(dateStart, 'days') || -1;
        }
    };
    SlideCreateReportComponent.prototype.daterangepickerOpen = function () {
        alwaysShowCalendar();
    };
    SlideCreateReportComponent.prototype.changeLockDates = function ($event) {
        this.lockDates.setValue($event.checked);
    };
    SlideCreateReportComponent.prototype.changeDynamicDates = function ($event) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.dynamic.setValue($event.checked);
        if ($event.checked) {
            this.DynamicRangeControl.setValidators(Validators.required);
            this.form.updateValueAndValidity();
            (_a = this.period) === null || _a === void 0 ? void 0 : _a.setValue('period');
        }
        else {
            this.DynamicRangeControl.setValidators([]);
            this.DynamicRangeControl.setErrors(null);
            this.form.updateValueAndValidity();
        }
        if (this.reportType == 'performance-comparison') {
            if (!$event.checked) {
                (_b = this.form.controls) === null || _b === void 0 ? void 0 : _b.startDateB.setValidators(Validators.required);
                (_c = this.form.controls) === null || _c === void 0 ? void 0 : _c.endDateB.setValidators(Validators.required);
                this.form.updateValueAndValidity();
            }
            else {
                (_d = this.form.controls) === null || _d === void 0 ? void 0 : _d.startDateB.setValidators([]);
                (_e = this.form.controls) === null || _e === void 0 ? void 0 : _e.endDateB.setValidators([]);
                (_f = this.form.controls) === null || _f === void 0 ? void 0 : _f.startDateB.setErrors(null);
                (_g = this.form.controls) === null || _g === void 0 ? void 0 : _g.endDateB.setErrors(null);
                this.form.updateValueAndValidity();
            }
        }
    };
    SlideCreateReportComponent.prototype.next = function () {
        this.step++;
    };
    SlideCreateReportComponent.prototype.backStep = function (event) {
        this.step = event;
    };
    SlideCreateReportComponent.prototype.periodChange = function ($event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (this.reportType == 'keyword') {
            return;
        }
        var data = this.reportS.periodChange($event.value, this.startDateTime.value, this.endDateTime.value);
        if ($event.value == 'period' || $event.value == 'month') {
            this.difference = data.difference;
        }
        if ((_a = this.reportType) === null || _a === void 0 ? void 0 : _a.includes('comparison')) {
            this.dataPicker.rangeB.start = (_b = data) === null || _b === void 0 ? void 0 : _b.startDateB;
            this.dataPicker.rangeB.end = (_c = data) === null || _c === void 0 ? void 0 : _c.endDateB;
            (_d = this.startDateTimeB) === null || _d === void 0 ? void 0 : _d.setValue((_e = data) === null || _e === void 0 ? void 0 : _e.startDateB);
            (_f = this.endDateTimeB) === null || _f === void 0 ? void 0 : _f.setValue((_g = data) === null || _g === void 0 ? void 0 : _g.endDateB);
            this.rangeBDisabled = (_h = data) === null || _h === void 0 ? void 0 : _h.rangeBDisabled;
        }
        (_j = this.period) === null || _j === void 0 ? void 0 : _j.setValue((_k = $event) === null || _k === void 0 ? void 0 : _k.value);
        this.selectDateB = null;
    };
    SlideCreateReportComponent.prototype.onStepChange = function (event) {
        this.step = event;
        var hasLocation = !!(this.accountsReport.length &&
            this.accountsReport.find(function (a) { return a.locations.length > 0; }));
        if (!hasLocation) {
            this.drawer.opened = true;
            this.modalService.openWarningModal('Heads up', 'This report has no locations selected. Please choose at least 1 location before trying to save the report.');
        }
        // if(!this.form.valid && event > 0) {
        //   this.form.markAllAsTouched()
        // }
    };
    SlideCreateReportComponent.prototype.selectedLocations = function ($event) {
        this.accountsReport = $event;
    };
    SlideCreateReportComponent.prototype.save = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var _s = this._sessionS.getSession(), uid = _s.uid, gid = _s.gid;
        if (this.accountsReport.length == 0) {
            return;
        }
        else {
            this.showLoader = true;
            this.formReport = this.form.value;
            var accounts_1 = this.deleteLabels(this.accountsReport);
            accounts_1 = this.locationS.deleteServiceArea(accounts_1);
            var startDatetimeMoment = moment((_a = this.startDateTime) === null || _a === void 0 ? void 0 : _a.value);
            var startDatetime_1 = this.reportS.getFormattedMomentDate(startDatetimeMoment);
            var endDatetimeMoment = moment(this.endDateTime.value);
            var endDatetime_1 = this.reportS.getFormattedMomentDate(endDatetimeMoment);
            if (this.isEdit) {
                this.reportEdit.accounts = accounts_1;
                this.reportEdit.startDatetime = startDatetime_1;
                this.reportEdit.endDatetime = endDatetime_1;
                this.reportEdit.endDatetime = endDatetime_1;
                this.reportEdit.reportName = (_b = this.formReport) === null || _b === void 0 ? void 0 : _b.reportName;
                this.reportEdit.lockDates = (_c = this.formReport) === null || _c === void 0 ? void 0 : _c.lockDates;
                this.reportEdit.dynamic = (_d = this.formReport) === null || _d === void 0 ? void 0 : _d.dynamic;
                this.reportEdit['updatedAt'] = moment().toISOString();
                if (this.scheduleReport.value) {
                    this.reportEdit = this.configAutomatedReport(this.reportEdit);
                    if (!this.reportEdit)
                        return;
                    this.reportEdit.scheduleReport = (_e = this.formReport) === null || _e === void 0 ? void 0 : _e.scheduleReport;
                    if (this.reportType === 'review' || this.reportType === 'rollup') {
                        this.reportEdit.dynamicRange = this.DynamicRangeControl.value;
                        var dateDynamic = this.reportS.converDynamicDateComparison((_f = this.reportEdit) === null || _f === void 0 ? void 0 : _f.dynamicRange, this.reportType);
                        this.reportEdit.startDatetime = (_g = dateDynamic) === null || _g === void 0 ? void 0 : _g.startDatetime;
                        this.reportEdit.endDatetime = (_h = dateDynamic) === null || _h === void 0 ? void 0 : _h.endDatetime;
                    }
                }
                if (this.reportEdit.dynamic) {
                    this.reportEdit.dynamicRange = this.DynamicRangeControl.value;
                    var dateDynamic = (this.reportType == 'qanda' ? this.questionsS.buildPeriodDate(this.DynamicRangeControl.value) :
                        this.reportS.converDynamicDateComparison(this.reportEdit.dynamicRange, this.reportType));
                    this.reportEdit.startDatetime = (_j = dateDynamic) === null || _j === void 0 ? void 0 : _j.startDatetime;
                    this.reportEdit.endDatetime = (_k = dateDynamic) === null || _k === void 0 ? void 0 : _k.endDatetime;
                }
                if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
                    var date = ((_l = this.startDateTimeB) === null || _l === void 0 ? void 0 : _l.value) || moment().format('YYYY-MM-DD');
                    (_m = this.startDateTimeB) === null || _m === void 0 ? void 0 : _m.setValue(this.reportS.getFormattedStringDate(date));
                    if (this.scheduleReport.value || this.dynamic.value) {
                        var dateDynamic = this.reportS.converDynamicDateComparison(this.reportEdit.dynamicRange, this.reportType);
                        this.reportEdit.startDatetime = (_o = dateDynamic) === null || _o === void 0 ? void 0 : _o.startDatetime;
                        this.reportEdit.endDatetime = (_p = dateDynamic) === null || _p === void 0 ? void 0 : _p.endDatetime;
                        this.reportEdit.startDatetimeB = (_q = dateDynamic) === null || _q === void 0 ? void 0 : _q.startDatetimeB;
                        this.reportEdit.endDatetimeB = (_r = dateDynamic) === null || _r === void 0 ? void 0 : _r.endDatetimeB;
                    }
                    else {
                        var startDateBtimeMoment = moment(this.startDateTimeB.value);
                        var startDateBtime = startDateBtimeMoment['year']() + "-" + (startDateBtimeMoment['month']() + 1) + "-" + startDateBtimeMoment['date']();
                        var endDateBtimeMoment = this.setHours(moment(this.endDateTimeB.value));
                        var endDateBtime = endDateBtimeMoment['year']() + "-" + (endDateBtimeMoment['month']() + 1) + "-" + endDateBtimeMoment['date']();
                        var startDatetimeB = startDateBtime;
                        var endDatetimeB = endDateBtime;
                        this.reportEdit.startDatetimeB = startDatetimeB;
                        this.reportEdit.endDatetimeB = endDatetimeB;
                    }
                    this.reportEdit.metrics = this.metricsChecked;
                    this.reportEdit.period = this.period.value;
                }
                delete this.reportEdit['needToUpgrade'];
                delete this.reportEdit['invalidLocations'];
                delete this.reportEdit['duplicatedLocations'];
                if (this.viewModeChecked != 'new') {
                    this.reportS.update(false, this.reportEdit.gid, this.reportEdit.id, this.reportEdit).then(function () {
                        _this.showLoader = false;
                        _this.closeSliderReport.emit();
                    });
                }
                else {
                    if (this.reportEdit.reportType !== 'comparison' && this.reportEdit.reportType !== 'performance-comparison') {
                        this.reportEdit.metrics = [];
                        this.reportEdit.metricsName = [];
                    }
                    this.reportS.updateReportsInMongo(this.reportEdit['_id'], this.reportEdit).subscribe(function (res) {
                        var _a;
                        if ((_a = res) === null || _a === void 0 ? void 0 : _a.error) {
                            _this.showLoader = false;
                            _this.closeSliderReport.emit();
                        }
                        else {
                            var id = res.reportId || res._id;
                            _this.showLoader = false;
                            _this.closeSliderReport.emit();
                            _this.router.navigateByUrl("/report/" + gid + "/" + id + "/" + _this.reportType).then();
                        }
                    }, function (err) {
                        _this.showLoader = false;
                        _this.closeSliderReport.emit();
                    });
                }
            }
            else {
                this.reportS.getHash().subscribe(function (hashToken) {
                    var report = null;
                    report = {
                        reportName: _this.formReport.reportName,
                        lockDates: _this.formReport.lockDates,
                        dynamic: _this.formReport.dynamic,
                        accounts: accounts_1,
                        reportType: _this.reportType,
                        startDatetime: startDatetime_1,
                        endDatetime: endDatetime_1,
                        createdAt: _this.dateS.nowTimestamp,
                        token: hashToken.token,
                        hash: hashToken.hash,
                        uid: uid,
                        gid: gid,
                        shared: false,
                        sharedOnly: false,
                        scheduleReport: _this.formReport.scheduleReport,
                    };
                    if (_this.scheduleReport.value) {
                        report = _this.configAutomatedReport(report);
                        if (!report)
                            return;
                    }
                    if (_this.dynamic.value) {
                        report.dynamicRange = _this.DynamicRangeControl.value;
                        var dateDynamic = _this.reportType == 'qanda' ? _this.questionsS.buildPeriodDate(_this.DynamicRangeControl.value) : _this.reportS.converDynamicDateComparison(_this.DynamicRangeControl.value, _this.reportType);
                        report.startDatetime = dateDynamic.startDatetime;
                        report.endDatetime = dateDynamic.endDatetime;
                    }
                    if (_this.reportType === 'comparison' || _this.reportType === 'performance-comparison') {
                        _this.startDateTimeB.setValue(_this.reportS.getFormattedStringDate(_this.startDateTimeB.value));
                        if (_this.scheduleReport.value || _this.dynamic.value) {
                            var dateDynamic = _this.reportS.converDynamicDateComparison(report.dynamicRange, _this.reportType);
                            report.startDatetime = dateDynamic.startDatetime;
                            report.endDatetime = dateDynamic.endDatetime;
                            report.startDatetimeB = dateDynamic.startDatetimeB;
                            report.endDatetimeB = dateDynamic.endDatetimeB;
                        }
                        else {
                            var startDateBtimeMoment = moment(_this.startDateTimeB.value);
                            var startDateBtime = _this.reportS.getFormattedMomentDate(startDateBtimeMoment);
                            var endDateBtimeMoment = _this.setHours(moment(_this.endDateTimeB.value));
                            var endDateBtime = _this.reportS.getFormattedMomentDate(endDateBtimeMoment);
                            report.startDatetimeB = startDateBtime;
                            report.endDatetimeB = endDateBtime;
                            report.period = _this.period.value;
                        }
                        report.metrics = _this.metricsChecked;
                    }
                    if (_this.viewModeChecked != 'new') {
                        _this.reportS.save(report.gid, report).then(function (reportSaved) {
                            _this.showLoader = false;
                            _this.closeSliderReport.emit();
                            _this.router.navigateByUrl("/report/" + gid + "/" + reportSaved.id).then();
                        });
                    }
                    else {
                        _this.reportS.saveReportInMongo(report).subscribe(function (res) {
                            var _a;
                            if ((_a = res) === null || _a === void 0 ? void 0 : _a.error) {
                                _this.showLoader = false;
                                _this.closeSliderReport.emit();
                            }
                            else {
                                var id = res.reportId || res._id;
                                _this.closeSliderReport.emit();
                                _this.showLoader = false;
                                _this.router.navigateByUrl("/report/" + gid + "/" + id + "/" + _this.reportType).then();
                            }
                        }, function (err) {
                            _this.showLoader = false;
                            _this.closeSliderReport.emit();
                        });
                    }
                });
            }
        }
    };
    SlideCreateReportComponent.prototype.deleteLabels = function (accounts) {
        accounts.forEach(function (acc) {
            acc.locations.forEach(function (l) {
                var _a;
                if ((_a = l) === null || _a === void 0 ? void 0 : _a.labels) {
                    delete l.labels;
                }
            });
        });
        return accounts;
    };
    SlideCreateReportComponent.prototype.setHours = function (date) {
        return date.set({ 'hours': 23, 'minute': 59, 'second': 59 });
    };
    SlideCreateReportComponent.prototype.configAutomatedReport = function (report) {
        if (!this.FrecuencyControl.valid && this.repeatOn === '' || !this.DynamicRangeControl.valid || this.RecipentsEmails.length === 0) {
            return;
        }
        report.frecuencySelector = this.FrecuencyControl.value;
        report.repeatOn = (this.repeatOn).toString();
        report.dynamicRange = this.DynamicRangeControl.value;
        report.recipentsEmails = this.RecipentsEmails;
        report.shared = true;
        report.domain = this._sessionS.getDomain().xDomainName;
        var date = moment().utc().toDate(), year = date.getFullYear(), month = date.getMonth(), day = date.getDate(), dayOfWeek = date.getDay();
        if (report.frecuencySelector === 'WeeklyOn') {
            if (dayOfWeek >= Number(report.repeatOn)) {
                var daysToDate = 7 - (dayOfWeek - Number(report.repeatOn));
                report.scheduleDate = moment(new Date(year, month, day + daysToDate)).utc().toISOString();
            }
            else {
                var daysToDate = Number(report.repeatOn) - dayOfWeek;
                report.scheduleDate = moment(new Date(year, month, day + daysToDate)).utc().toISOString();
            }
        }
        else {
            if (day >= Number(report.repeatOn)) {
                report.scheduleDate = moment(new Date(year, month + 1, Number(report.repeatOn))).utc().toISOString();
            }
            else {
                report.scheduleDate = moment(new Date(year, month, Number(report.repeatOn))).utc().toISOString();
            }
        }
        return report;
    };
    SlideCreateReportComponent.prototype.initForm = function (report) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var periodValue = report && report.period ? this.compareList.find(function (el) { return el.value == report.period; }) : this.compareList[1];
        if (report) {
            this.accountsReport = report.accounts;
            this.form = this.fb.group({
                reportName: [report.reportName, Validators.required],
                scheduleReport: [report.scheduleReport || false],
                startDate: [report.startDatetime || '', Validators.required],
                endDate: [report.endDatetime || '', Validators.required],
                lockDates: [report.lockDates],
                dynamic: [report.dynamic],
                period: [periodValue.value]
            });
            if (report.scheduleReport) {
                this.RecipentsEmails = report.recipentsEmails;
                this.FrecuencyControl.setValue(report.frecuencySelector);
                this.DynamicRangeControl.setValue(report.dynamicRange);
                this.repeatOn = report.repeatOn;
            }
            else if (report.dynamic) {
                this.DynamicRangeControl.setValue(report.dynamicRange);
            }
            if (report.startDatetime && report.endDatetime) {
                this.dataPicker = {
                    range: { start: report.startDatetime, end: report.endDatetime },
                };
            }
            if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
                this.dataPicker.rangeB = { start: report.startDatetimeB, end: report.endDatetimeB };
            }
        }
        else {
            this.form = this.fb.group({
                reportName: ['', Validators.required],
                scheduleReport: [false],
                startDate: ['', Validators.required],
                endDate: ['', Validators.required],
                lockDates: [false, Validators.required],
                dynamic: [false, Validators.required],
            });
            if (this.reportType == 'qanda') {
                this.DynamicRangeControl.setValue('allTime');
                this.dynamic.setValue(true);
            }
        }
        if (this.reportType == 'qanda') {
            this.startDateTime.setValidators([]);
            this.startDateTime.updateValueAndValidity();
            this.endDateTime.setValidators([]);
            this.endDateTime.updateValueAndValidity();
        }
        if (this.reportType === 'comparison' || this.reportType === 'performance-comparison') {
            if ((_a = report) === null || _a === void 0 ? void 0 : _a.metrics) {
                this.metricsChecked = (_b = report) === null || _b === void 0 ? void 0 : _b.metrics;
            }
            if ((_c = report) === null || _c === void 0 ? void 0 : _c.metricsName) {
                this.metricsChecked = (_d = report) === null || _d === void 0 ? void 0 : _d.metricsName;
            }
            if (report) {
                if (periodValue.value == 'custom') {
                    var dates = {
                        startDatetime: (_e = report) === null || _e === void 0 ? void 0 : _e.startDatetimeB,
                        endDatetime: (_f = report) === null || _f === void 0 ? void 0 : _f.endDatetimeB
                    };
                    this.selectDateB = this.reportS.reportSelectDatesFromReport(dates);
                    this.rangeBDisabled = false;
                }
                this.period.setValue(periodValue.value);
                this.form.addControl('startDateB', new FormControl(report.startDatetimeB, !((_g = report) === null || _g === void 0 ? void 0 : _g.dynamic) ? Validators.required : null));
                this.form.addControl('endDateB', new FormControl(report.endDatetimeB, !((_h = report) === null || _h === void 0 ? void 0 : _h.dynamic) ? Validators.required : null));
            }
            else {
                this.form.addControl('period', new FormControl('period', Validators.required));
                this.form.addControl('startDateB', new FormControl('', Validators.required));
                this.form.addControl('endDateB', new FormControl('', Validators.required));
            }
        }
        if (this.dynamic.value) {
            this.DynamicRangeControl.setValidators(Validators.required);
            this.form.updateValueAndValidity();
        }
    };
    SlideCreateReportComponent.prototype.addNewRecipent = function () {
        if (this.RecipentsControl.valid) {
            this.RecipentsEmails.push(this.RecipentsControl.value);
            this.RecipentsControl.setValue('');
        }
    };
    SlideCreateReportComponent.prototype.deleteRecipent = function (email) {
        var emailRemoved = this.RecipentsEmails.findIndex(function (el) { return el == email; });
        this.RecipentsEmails.splice(emailRemoved, 1);
    };
    Object.defineProperty(SlideCreateReportComponent.prototype, "reportName", {
        get: function () {
            return this.form.get('reportName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "scheduleReport", {
        get: function () {
            return this.form.get('scheduleReport');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "startDateTime", {
        get: function () {
            return this.form.get('startDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "endDateTime", {
        get: function () {
            return this.form.get('endDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "startDateTimeB", {
        get: function () {
            return this.form.get('startDateB');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "period", {
        get: function () {
            return this.form.get('period');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "endDateTimeB", {
        get: function () {
            return this.form.get('endDateB');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "lockDates", {
        get: function () {
            return this.form.get('lockDates');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "dynamic", {
        get: function () {
            return this.form.get('dynamic');
        },
        enumerable: true,
        configurable: true
    });
    SlideCreateReportComponent.prototype.selectedMetrics = function ($event) {
        this.metricsChecked = $event;
    };
    SlideCreateReportComponent.prototype.completeSteeper = function () {
        var _a;
        if (this.reportType !== 'comparison' && this.reportType !== 'performance-comparison') {
            if (this.completeStep1 && this.completeStep2) {
                this.save();
            }
            else {
                this.closeSliderReport.emit();
            }
        }
        else {
            if (this.completeStep1 &&
                this.completeStep2 &&
                !this.completeStep3 &&
                ((_a = this.metricsChecked) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                this.drawer.opened = true;
                this.modalService.openWarningModal('Heads up', 'This report has no metrics selected. Please choose at least 1 metric before trying to save the report.');
                this.step = 2;
                return;
            }
            if (this.completeStep1 && this.completeStep2 && this.completeStep3) {
                this.save();
            }
        }
    };
    SlideCreateReportComponent.prototype.getReportTitle = function (reportType) {
        var _a;
        if (!reportType) {
            return "";
        }
        switch (reportType) {
            case 'qanda':
                reportType = 'Questions And Answers';
                break;
            case 'performance-rollup':
                reportType = 'Performance';
                break;
            default:
                reportType = reportType;
                break;
        }
        reportType = reportType == 'qanda' ? 'Questions And Answers' : reportType;
        return this.toTitleCase((_a = reportType) === null || _a === void 0 ? void 0 : _a.replace('-', ' ')) + ' Report';
    };
    SlideCreateReportComponent.prototype.toTitleCase = function (phrase) {
        return phrase
            .toLowerCase()
            .split(' ')
            .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
            .join(' ');
    };
    Object.defineProperty(SlideCreateReportComponent.prototype, "completeStep1", {
        get: function () {
            var _a;
            return ((_a = this.accountsReport) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "completeStep2", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g;
            if ((_a = this.scheduleReport) === null || _a === void 0 ? void 0 : _a.value) {
                if (((_b = this.FrecuencyControl) === null || _b === void 0 ? void 0 : _b.valid) && this.repeatOn !== '' && ((_c = this.DynamicRangeControl) === null || _c === void 0 ? void 0 : _c.valid) && ((_d = this.RecipentsEmails) === null || _d === void 0 ? void 0 : _d.length) > 0) {
                    return !(((_e = this.FrecuencyControl) === null || _e === void 0 ? void 0 : _e.value) === 'MonthlyOn' && (this.repeatOn == '0' || parseInt(this.repeatOn) > 28));
                }
            }
            else if (this.form.valid && ((_f = this.DynamicRangeControl) === null || _f === void 0 ? void 0 : _f.valid)) {
                // new conditional to check that range A and custom range B periods are equal in length
                if (((_g = this.period) === null || _g === void 0 ? void 0 : _g.value) === 'custom' && this.reportType === 'performance-comparison') {
                    return this.rangeAPeriod === this.rangeBPeriod;
                }
                else {
                    return true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "completeStep3", {
        get: function () {
            var _a;
            return ((_a = this.metricsChecked) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlideCreateReportComponent.prototype, "disabledNextStep", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var datepickerIsClosed = true;
            var datepickerIsClosedComparison = true;
            if (this.step == 1) {
                var datePickerElement = this.el.nativeElement.querySelector('ngx-daterangepicker-material.ng-star-inserted');
                datepickerIsClosed = !datePickerElement ? true : (_c = (_b = (_a = datePickerElement) === null || _a === void 0 ? void 0 : _a.lastChild) === null || _b === void 0 ? void 0 : _b.classList) === null || _c === void 0 ? void 0 : _c.contains('hidden');
                if (this.reportType == 'performance-comparison' && ((_d = this.period) === null || _d === void 0 ? void 0 : _d.value) == 'custom') {
                    var datePickerElementComparison = (_e = document) === null || _e === void 0 ? void 0 : _e.getElementsByClassName('datepicker-rangeB')[0];
                    datepickerIsClosedComparison = (!datePickerElementComparison || ((_j = (_h = (_g = (_f = datePickerElementComparison) === null || _f === void 0 ? void 0 : _f.childNodes[1]) === null || _g === void 0 ? void 0 : _g.childNodes[1]) === null || _h === void 0 ? void 0 : _h.lastChild) === null || _j === void 0 ? void 0 : _j['classList'].contains('hidden')));
                }
            }
            return !(this.step == 0 ? this.completeStep1 : this.step == 1 ? this.completeStep2 && datepickerIsClosed && datepickerIsClosedComparison : this.completeStep3);
        },
        enumerable: true,
        configurable: true
    });
    SlideCreateReportComponent.prototype.doValidationMetrics = function (event) {
        this.metricVerification = event;
    };
    SlideCreateReportComponent.prototype.ngOnDestroy = function () {
        this.isEdit = false;
        this.metricsChecked = [];
        this.reportEdit = null;
        this.RecipentsEmails = [];
        this.accountsReport = [];
        this.step = 0;
        this.reportData = {};
        this.dataPicker = DATAPICKER_REPORT_DEFAULT;
        this.DynamicRangeControl.setValue(null);
        this.reportName.setValue(null);
        this.metricVerification = false;
        this.closeSliderReport.emit();
        this.selectDate = null;
        this.DynamicRangeControl.setErrors(null);
    };
    SlideCreateReportComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    SlideCreateReportComponent.prototype.isDynamicOptionEnabled = function (dyanmic) {
        var result = false;
        // deprecated by Evan's request
        // const dates = this.reportS.converDynamicDateComparison(dyanmic, this.reportType);
        // dates.startDatetime = this.locationS.formatDates(dates.startDatetime);
        // dates.endDatetime = this.locationS.formatDates(dates.endDatetime);
        // if (this.reportType == 'performance-rollup') {
        //   result = !(moment(dates.startDatetime).isSameOrAfter(this.minDate) && moment(dates.endDatetime).isSameOrBefore(this.maxDate));
        // } else if (this.reportType == 'performance-comparison') {
        //   dates.startDatetime = this.locationS.formatDates(dates.startDatetime);
        //   dates.endDatetimeB = this.locationS.formatDates(dates.endDatetimeB);
        //   result = !(
        //     moment(dates.startDatetime).isSameOrAfter(this.minDate) && moment(dates.endDatetime).isSameOrBefore(this.maxDate) && 
        //     moment(dates.startDatetimeB).isSameOrAfter(this.minDate) && moment(dates.endDatetimeB).isSameOrBefore(this.maxDate)
        //   );
        //   return result;
        // }
        return false;
    };
    SlideCreateReportComponent.prototype.isOptionEnabled = function (compare) {
        var result = false;
        var endMontheMoment, endMonthStr, startMonthMoment, startMonthStr, isRangeBAnAccepted;
        var reportStartMoment = moment(this.startDateTime.value);
        var reportStartStr = this.reportS.getFormattedMomentDate(reportStartMoment);
        var reportEndMoment = moment(this.endDateTime.value);
        var reportEndStr = this.reportS.getFormattedMomentDate(reportEndMoment);
        switch (compare) {
            case 'month':
                startMonthMoment = reportStartMoment.startOf('month');
                startMonthStr = this.reportS.getFormattedMomentDate(startMonthMoment);
                endMontheMoment = reportStartMoment.endOf('month');
                endMonthStr = this.reportS.getFormattedMomentDate(endMontheMoment);
                isRangeBAnAccepted = this.isRangeBAnAccepted('month');
                result = !(reportStartStr === startMonthStr && reportEndStr === endMonthStr && isRangeBAnAccepted);
                break;
            case 'year':
                isRangeBAnAccepted = this.isRangeBAnAccepted('year');
                result = !(reportStartMoment.diff(reportEndMoment, 'years') == 0 && isRangeBAnAccepted);
                break;
            case 'period':
                isRangeBAnAccepted = this.isRangeBAnAccepted('period');
                result = !isRangeBAnAccepted;
                break;
        }
        return result;
    };
    SlideCreateReportComponent.prototype.isRangeBAnAccepted = function (period) {
        var datesB = this.reportS.periodChange(period, this.startDateTime.value, this.endDateTime.value);
        return (moment(datesB.startDateB).isAfter(this.minDate) &&
            moment(datesB.endDateB).isBefore(this.maxDate));
    };
    SlideCreateReportComponent.prototype.validateDates = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var accountIds, locationIds_1, dateValidations, dates;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.selectedIndex == 1 && (this.reportType == 'keyword' ||
                            this.reportType == 'performance-rollup' ||
                            this.reportType == 'performance-comparison' ||
                            this.reportType == 'review'))) return [3 /*break*/, 2];
                        this.showLoader = true;
                        accountIds = this.accountsReport.map(function (el) { return el.accountId; });
                        locationIds_1 = [];
                        this.accountsReport.forEach(function (a) { return a.locations.map(function (l) { return locationIds_1.push(l.locationId); }); });
                        return [4 /*yield*/, this.locationS.getDateValidations(this.reportType, accountIds, [this._sessionS.getSession().gid], locationIds_1)];
                    case 1:
                        dateValidations = _a.sent();
                        dates = this.locationS.dateValidation(dateValidations);
                        this.minDate = this.reportType == 'keyword' && dates.minDate ? dates.minDate.startOf('month').toDate() : dates.minDate;
                        this.maxDate = this.reportType == 'keyword' && dates.maxDate ? dates.maxDate.endOf('month').toDate() : dates.maxDate;
                        this.buildSelectDate();
                        this.showLoader = false;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return SlideCreateReportComponent;
}());
export { SlideCreateReportComponent };
