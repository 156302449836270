import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import firebase from 'firebase/app';
import { FirestoreService } from './firestore.service';
import { ZuluToDatePipe } from '../pipes/zulu-to-date.pipe';
import { FIRESTORE_AUTOMATED_REPORTS, GROUPS, REPORTS } from '../constants/firestore/collections';
import { environment } from "@environment";
import { InsightsService } from './insights.service';
import { SnackbarService } from './snackbar.service';
import { DatesService } from './dates.service';
import { LocationService } from './location.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "./firestore.service";
import * as i4 from "./insights.service";
import * as i5 from "@angular/fire/storage";
import * as i6 from "./snackbar.service";
import * as i7 from "./dates.service";
import * as i8 from "./location.service";
import * as i9 from "./session.service";
var ReportService = /** @class */ (function () {
    function ReportService(http, afs, firestoreS, insightS, afsStorage, snackS, dateS, locationS, _sessionS) {
        this.http = http;
        this.afs = afs;
        this.firestoreS = firestoreS;
        this.insightS = insightS;
        this.afsStorage = afsStorage;
        this.snackS = snackS;
        this.dateS = dateS;
        this.locationS = locationS;
        this._sessionS = _sessionS;
    }
    ReportService.prototype._getRef = function (gid) {
        return this.afs.collection(GROUPS).doc(gid).collection(REPORTS);
    };
    ReportService.prototype._getAutomatedRef = function (gid) {
        return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS);
    };
    /**
     * this method get hash and token for public report
     */
    ReportService.prototype.getHash = function () {
        // TODO: Why this check?
        // if (!this.auth.session)
        if (!this._sessionS.hasSession())
            return;
        return this.http.get(environment.apiUrl + "/users/hash");
    };
    // CRUD firestore operations
    ReportService.prototype.get = function (gid, reportId) {
        return this.afs.collection(GROUPS + "/" + gid + "/" + REPORTS).doc(reportId).valueChanges();
    };
    ReportService.prototype.getAutomated = function (gid, reportId) {
        return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS).doc(reportId).valueChanges();
    };
    ReportService.prototype.getById = function (gid, reportId) {
        return this.afs.collection(GROUPS).doc(gid).collection(REPORTS).doc(reportId);
    };
    /**
     * search by uid and gid
     */
    ReportService.prototype.getByGIdAndType = function (gid, type, pageable, next, prev, searchKeywords, order, direction) {
        if (pageable === void 0) { pageable = { size: 10, page: 1 }; }
        if (order === void 0) { order = 'createdAt'; }
        if (direction === void 0) { direction = 'desc'; }
        return this.firestoreS.paginateValuesReports(GROUPS + "/" + gid + "/" + REPORTS, order, direction, gid, type, pageable, next, prev, searchKeywords);
    };
    ReportService.prototype.getByGIdAndTypeCount = function (gid, reportType) {
        return this.afs.collection(GROUPS + "/" + gid + "/" + REPORTS, function (query) { return query.where('gid', '==', gid)
            .where('reportType', '==', reportType); }).get();
    };
    // TODO: Unused, remove
    //   async getLegacy(gid: string, type: string) {
    //     const res = await this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`, ref => 
    //       ref.where('gid', '==', gid)
    //       .where('reportType', '==', type)
    //     ).get().toPromise();
    // 
    //    // console.log(res.forEach(doc => console.log(doc.data())))
    //    return res.docs.length;
    //   }
    ReportService.prototype.getReportsFromMongo = function (gid, reportType, paginate, sortBy, sortOrder, reportName) {
        if (reportName === void 0) { reportName = null; }
        var data = {
            gid: gid,
            reportType: reportType,
            size: paginate.size,
            page: paginate.page,
            sortBy: sortBy,
            sortOrder: sortOrder,
            reportName: reportName
        };
        return this.http.post(environment.apiUrl + "/v3/reports/list", data).toPromise();
    };
    ReportService.prototype.saveReportInMongo = function (report) {
        return this.http.post(environment.apiUrl + "/v3/reports/create", report);
    };
    ReportService.prototype.updateReportsInMongo = function (reportId, report) {
        var data = { updatedData: report };
        report.accounts = this.locationS.deleteServiceArea(report.accounts);
        return this.http.post(environment.apiUrl + "/v3/reports/" + reportId + "/edit", data);
    };
    ReportService.prototype.deleteFromMongo = function (reportId) {
        return this.http.delete(environment.apiUrl + "/v3/reports/" + reportId + "/delete");
    };
    ReportService.prototype.getSearchKeywordsBrands = function (accountId, locationId, gid, reportId, startDate, endDate, period) {
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        if (reportId === void 0) { reportId = null; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        var _a;
        period = period ? (_a = period) === null || _a === void 0 ? void 0 : _a.replace('Comparison', '') : null;
        var data = {
            "accountId": accountId,
            "locationId": locationId,
            "reportId": reportId,
            "gid": gid,
            "startDate": startDate,
            "endDate": endDate,
            "period": period
        };
        return this.http.post(environment.apiUrl + "/v2/locations/keyword-brands", data);
    };
    ReportService.prototype.getPerformanceRollupReports = function (reportId, viewBy, startDate, endDate) {
        if (viewBy === void 0) { viewBy = 'month'; }
        var data = {
            reportId: reportId,
            viewBy: viewBy.toLocaleLowerCase(),
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post(environment.apiUrl + "/v3/reports/insights", data);
    };
    ReportService.prototype.validateDateFormat = function (date) {
        var _a;
        var dateParts = ((_a = date) === null || _a === void 0 ? void 0 : _a.split('-')) || [];
        var year = dateParts[0].padStart(4, '0');
        var month = dateParts[1].padStart(2, '0');
        var day = dateParts[2].padStart(2, '0');
        return year + "-" + month + "-" + day + " 00:00:00";
    };
    ReportService.prototype.resumeExportCsv = function (gid, reportType, sort, dataPicker, filename) {
        var _a, _b, _c, _d, _e, _f;
        var startDatetimeMoment = moment(dataPicker.range.start);
        var endDatetimeMoment = moment(dataPicker.range.end);
        var startDatetime = !startDatetimeMoment.isValid() ? null : startDatetimeMoment['_i'].includes('T') ? (startDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (startDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');
        var endDatetime = !endDatetimeMoment.isValid() ? null : endDatetimeMoment['_i'].includes('T') ? (endDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (endDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');
        if (!!startDatetime) {
            startDatetime = this.validateDateFormat(startDatetime);
        }
        if (!!endDatetime) {
            endDatetime = this.validateDateFormat(endDatetime);
        }
        var accounts = (_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.accountId; });
        accounts = __spread(new Set(accounts));
        var locations = (_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.locations) === null || _d === void 0 ? void 0 : _d.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.locationId; });
        var data = {
            "gids": [gid],
            "accountIds": accounts,
            "locationIds": locations,
            "filter": {
                "startDate": startDatetime,
                "endDate": endDatetime
            },
            "type": reportType,
            "filename": filename
        };
        if (sort) {
            data['sortDesc'] = (((_e = sort) === null || _e === void 0 ? void 0 : _e.direction) !== 'asc');
            data['sortKey'] = (_f = sort) === null || _f === void 0 ? void 0 : _f.sortBy;
        }
        return this.http.post(environment.coreApiUrl + "/export/multilocation", data);
    };
    ReportService.prototype.getTableData = function (gid, reportType, pageable, sort, dataPicker) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var startDatetime, endDatetime, endDatetimeMoment, startDatetimeMoment = null;
        if (dataPicker.range.start) {
            startDatetimeMoment = moment(dataPicker.range.start);
            startDatetime = !startDatetimeMoment.isValid() ? null : ((_a = startDatetimeMoment['_i']) === null || _a === void 0 ? void 0 : _a.includes('T')) ? (_c = ((_b = startDatetimeMoment['_i']) === null || _b === void 0 ? void 0 : _b.split('T')[0])) === null || _c === void 0 ? void 0 : _c.replaceAll('/', '-') : (_e = ((_d = startDatetimeMoment['_i']) === null || _d === void 0 ? void 0 : _d.split(' ')[0])) === null || _e === void 0 ? void 0 : _e.replaceAll('/', '-');
            startDatetime = startDatetime + " 00:00:00";
        }
        if (dataPicker.range.end) {
            endDatetimeMoment = moment(dataPicker.range.end);
            endDatetime = !endDatetimeMoment.isValid() ? null : ((_f = endDatetimeMoment['_i']) === null || _f === void 0 ? void 0 : _f.includes('T')) ? (_h = ((_g = endDatetimeMoment['_i']) === null || _g === void 0 ? void 0 : _g.split('T')[0])) === null || _h === void 0 ? void 0 : _h.replaceAll('/', '-') : (_k = ((_j = endDatetimeMoment['_i']) === null || _j === void 0 ? void 0 : _j.split(' ')[0])) === null || _k === void 0 ? void 0 : _k.replaceAll('/', '-');
            endDatetime = endDatetime + " 00:00:00";
        }
        var accounts = (_m = (_l = dataPicker) === null || _l === void 0 ? void 0 : _l.locations) === null || _m === void 0 ? void 0 : _m.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.accountId; });
        accounts = __spread(new Set(accounts));
        var locations = (_p = (_o = dataPicker) === null || _o === void 0 ? void 0 : _o.locations) === null || _p === void 0 ? void 0 : _p.map(function (l) { var _a; return (_a = l) === null || _a === void 0 ? void 0 : _a.locationId; });
        var data = {
            "gids": [gid],
            "accountIds": accounts,
            "locationIds": locations,
            "filter": {
                "startDate": startDatetime,
                "endDate": endDatetime
            },
            "type": reportType,
            "page": pageable.page,
            "pageSize": pageable.size,
        };
        if (sort) {
            data['sortDesc'] = (sort.direction !== 'asc');
            data['sortKey'] = sort.sortBy;
        }
        return this.http.post(environment.coreApiUrl + "/reports/multilocation", data);
    };
    ReportService.prototype.getReportsByType = function (gid, reportId, reporType, startDate, endDate, viewBy) {
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        if (viewBy === void 0) { viewBy = null; }
        var data = {
            gid: gid,
            reportId: reportId,
            startDate: startDate,
            endDate: endDate
        };
        if (viewBy) {
            data['viewBy'] = viewBy.toLowerCase();
        }
        return this.http.post(environment.apiUrl + "/v3/reports/" + reporType, data);
    };
    ReportService.prototype.getReportDetails = function (reportId) {
        return this.http.post(environment.apiUrl + "/v3/reports/accounts", { reportId: reportId });
    };
    ReportService.prototype.getReportById = function (reportId, isAutomatedReport) {
        if (isAutomatedReport === void 0) { isAutomatedReport = false; }
        var type = isAutomatedReport ? 'automated' : 'default';
        return this.http.get(environment.apiUrl + "/v3/reports/" + reportId + "?type=" + type);
    };
    ReportService.prototype.handleExportCsvFire = function (locations, startDate, endDate, viewBy, reportName) {
        return __awaiter(this, void 0, void 0, function () {
            var filename, filename2, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.insightS.getExportFile(locations, startDate, endDate, viewBy, 'csv', reportName).toPromise()];
                    case 1:
                        filename = (_a.sent()).data;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.afsStorage.ref(filename).getDownloadURL().toPromise()];
                    case 3:
                        filename2 = _a.sent();
                        this.insightS.openDownloadInNewTab(filename2);
                        this.snackS.openSuccess("The file exported successfully");
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.snackS.openError("There was an error in the export");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ReportService.prototype.handleExportCsvMongo = function (reportId, gid, accountId, locationId, startDate, endDate, viewBy, type, period) {
        if (type === void 0) { type = null; }
        if (period === void 0) { period = null; }
        return __awaiter(this, void 0, void 0, function () {
            var startDatetime, endDatetime, filename, filename2, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        startDatetime = this.locationS.formatDates(startDate);
                        endDatetime = this.locationS.formatDates(endDate);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this._downloadReportCsv(reportId, gid, accountId, locationId, startDatetime, endDatetime, viewBy, type, period)];
                    case 2:
                        filename = _a.sent();
                        return [4 /*yield*/, this.afsStorage.ref(filename).getDownloadURL().toPromise()];
                    case 3:
                        filename2 = _a.sent();
                        this.insightS.openDownloadInNewTab(filename2);
                        this.snackS.openSuccess("The file exported successfully");
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.snackS.openError("There was an error in the export");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ReportService.prototype._downloadReportCsv = function (reportId, gid, accountId, locationId, startDate, endDate, viewBy, type, period) {
        if (reportId === void 0) { reportId = null; }
        if (gid === void 0) { gid = null; }
        if (accountId === void 0) { accountId = null; }
        if (locationId === void 0) { locationId = null; }
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        if (viewBy === void 0) { viewBy = null; }
        if (type === void 0) { type = null; }
        if (period === void 0) { period = null; }
        var _a;
        var data = {
            reportId: reportId,
            gid: gid,
            accountId: accountId,
            locationId: locationId,
            startDate: startDate,
            endDate: endDate,
            viewBy: (_a = viewBy) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
            type: type,
            period: period
        };
        return this.http.post(environment.apiUrl + "/v3/reports/export", data).toPromise();
    };
    // TODO: Unused, remove?
    //   downloadReportCsvReview(gid, reportId, startDate, endDate, type): Observable<any> {
    //     const data = {
    //       gid,
    //       reportId, 
    //       startDate, 
    //       endDate, 
    //       type
    //     };
    // 
    //     return this.http.post(`${environment.apiUrl}/v3/reports/export`, data);
    //   }
    ReportService.prototype.save = function (gid, report) {
        return this._getRef(gid).add(report);
    };
    ReportService.prototype.delete = function (gid, reportId) {
        return this._getRef(gid)
            .doc(reportId)
            .delete();
    };
    ReportService.prototype.update = function (automatedReport, gid, reportId, report) {
        if (automatedReport === void 0) { automatedReport = false; }
        if (automatedReport) {
            return this._getAutomatedRef(gid)
                .doc(reportId)
                .update(__assign(__assign({}, report), { updateAt: firebase.firestore.Timestamp.now() }));
        }
        return this._getRef(gid)
            .doc(reportId)
            .update(__assign(__assign({}, report), { updateAt: firebase.firestore.Timestamp.now() }));
    };
    ReportService.prototype.setMerge = function (gid, reportId, report) {
        return this._getRef(gid)
            .doc(reportId)
            .set(report, { merge: true });
    };
    ReportService.prototype.share = function (dataPicker, type, metrics, reportName, showComparison, questionsType, periodDaysValue, compareToValue) {
        var _this = this;
        return this.getHash()
            .pipe(map(function (result) {
            var _a, _b, _c, _d;
            var _e = _this._sessionS.getSession(), gid = _e.gid, uid = _e.uid;
            var report = {
                reportName: reportName || '',
                reportType: type,
                createdAt: firebase.firestore.Timestamp.now(),
                locations: dataPicker.locations,
                gid: gid,
                hash: result.hash,
                token: result.token,
                aggregation: dataPicker.aggregation,
                lockDates: false,
                uid: uid,
                shared: true,
                sharedOnly: true
            };
            if (dataPicker.multiChart) {
                report.multiChart = dataPicker.multiChart;
            }
            if (dataPicker.range) {
                report.startDatetime = (_b = (_a = dataPicker) === null || _a === void 0 ? void 0 : _a.range) === null || _b === void 0 ? void 0 : _b.start;
                report.endDatetime = (_d = (_c = dataPicker) === null || _c === void 0 ? void 0 : _c.range) === null || _d === void 0 ? void 0 : _d.end;
            }
            if (dataPicker.rangeB) {
                if (dataPicker.rangeB.start !== undefined && dataPicker.rangeB.end !== undefined) {
                    report.startDatetimeB = dataPicker.rangeB.start;
                    report.endDatetimeB = dataPicker.rangeB.end;
                }
            }
            if (metrics.length) {
                report.metrics = metrics;
            }
            if (!report.accounts) {
                report.accounts = _this.buildAccounts(dataPicker.locations);
            }
            if (type.includes('keyword') && showComparison) {
                report.showComparison = showComparison;
            }
            if (type.includes('keyword') && compareToValue) {
                report.compareToValue = compareToValue;
            }
            if (type.includes('qanda') && questionsType) {
                report.questionsType = questionsType;
            }
            if (type.includes('qanda') && periodDaysValue) {
                report.dynamicRange = periodDaysValue;
            }
            return report;
        }), switchMap(function (report) {
            var _a, _b;
            return (type != 'performance-insights' && !((_a = type) === null || _a === void 0 ? void 0 : _a.includes('-location')) && !((_b = type) === null || _b === void 0 ? void 0 : _b.includes('-report'))) ?
                _this.save(report.gid, report) :
                _this.saveReportInMongo(report);
        }));
    };
    ReportService.prototype.buildAccounts = function (locations) {
        var gid = this._sessionS.getSession().gid;
        var r = [];
        locations.forEach(function (l) {
            var _a;
            var idx = r.findIndex(function (loc) { var _a; return loc.accountId == ((_a = l) === null || _a === void 0 ? void 0 : _a.accountId); });
            if (idx > -1) {
                r[idx].locations.push(l);
            }
            else {
                r.push({
                    accountId: (_a = l) === null || _a === void 0 ? void 0 : _a.accountId,
                    gid: gid,
                    locations: [l]
                });
            }
        });
        return r;
    };
    ReportService.prototype.fetchByHash = function (hash) {
        return __awaiter(this, void 0, void 0, function () {
            var r, d;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collectionGroup(REPORTS, function (ref) { return ref.where('hash', '==', hash).limit(1); }).get().toPromise()];
                    case 1:
                        r = _a.sent();
                        if (!r.docs.length) {
                            return [2 /*return*/, null];
                        }
                        else {
                            d = r.docs[0];
                            return [2 /*return*/, __assign({ id: d.id }, d.data())];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportService.prototype.reportSelectDatesFromReport = function (report) {
        var selectStart = report.startDatetime;
        var selectEnd = report.endDatetime;
        return { start: moment(selectStart),
            end: moment(selectEnd) };
    };
    ReportService.prototype.converDynamicDateComparison = function (selector, reportType) {
        var _a, _b, _c, _d;
        var dates = this.getDates(selector, reportType);
        var startDatetime = (_a = dates.startDatetime) === null || _a === void 0 ? void 0 : _a.toISOString();
        var endDatetime = (_b = dates.endDatetime) === null || _b === void 0 ? void 0 : _b.toISOString();
        var startDatetimeB = (_c = dates.startDatetimeB) === null || _c === void 0 ? void 0 : _c.toISOString();
        var endDatetimeB = (_d = dates.endDatetimeB) === null || _d === void 0 ? void 0 : _d.toISOString();
        return { startDatetime: startDatetime, endDatetime: endDatetime, startDatetimeB: startDatetimeB, endDatetimeB: endDatetimeB };
    };
    ReportService.prototype.updateDynamicDates = function (report, reportId, automatedReport, viewMode) {
        if (viewMode === void 0) { viewMode = 'new'; }
        var _a, _b, _c, _d;
        if (!((_a = report) === null || _a === void 0 ? void 0 : _a.dynamic) || !((_b = report) === null || _b === void 0 ? void 0 : _b.dynamicRange))
            return;
        var dynamic = (_c = report) === null || _c === void 0 ? void 0 : _c.dynamicRange;
        var dates = this.getDates(dynamic, report.reportType);
        report.startDatetime = moment(dates.startDatetime).utc().toISOString();
        report.endDatetime = moment(dates.endDatetime).utc().toISOString();
        if ((_d = report.reportType) === null || _d === void 0 ? void 0 : _d.includes('comparison')) {
            // FIXME: Fix typing
            // @ts-ignore
            report.startDatetimeB = dates.startDatetimeB;
            // FIXME: Fix typing
            // @ts-ignore
            report.endDatetimeB = dates.endDatetimeB;
        }
        this._updateReport(viewMode, reportId, report, automatedReport);
    };
    ReportService.prototype.getDates = function (selector, reportType) {
        var now = moment();
        var startDatetime = null;
        var endDatetime = null;
        var startDatetimeB = null;
        var endDatetimeB = null;
        var date = now.clone().utc().toDate();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        switch (selector) {
            case 'LastMonth':
                startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, 0).setHours(0, 0, 0));
                break;
            case 'ThisMonth':
                startDatetime = moment(new Date(year, month, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                break;
            case 'Last7':
                startDatetime = moment(new Date(year, month, day - 7).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                break;
            case 'Last30':
                startDatetime = moment(new Date(year, month, day - 30).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                break;
            case 'Last90':
                startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                break;
            case 'LastYear':
                startDatetime = moment(new Date(year - 1, 0, 1));
                endDatetime = moment(new Date(year - 1, 11, 31));
                break;
            case 'YTD':
                // Year-to-Date (beginning of the year to current date)
                startDatetime = moment(new Date(year, 0, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                break;
            case 'OneMonth':
                startDatetime = now.clone().subtract(1, 'month').startOf('month');
                endDatetime = moment(new Date().setDate(0));
                break;
            case 'Last3Month':
                startDatetime = now.clone().subtract(3, 'month').startOf('month');
                endDatetime = moment(new Date().setDate(0));
                break;
            case 'OneYear':
                startDatetime = moment(new Date(year, 0, 1));
                endDatetime = moment(new Date().setDate(0));
                break;
            case 'LastMonth-PrevMonth':
                startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, 0).setHours(0, 0, 0));
                startDatetimeB = moment(new Date(year, month - 2, 1).setHours(0, 0, 0));
                endDatetimeB = moment(new Date(year, month - 1, 0).setHours(0, 0, 0));
                break;
            case 'LastMonth-YearAgoMonth':
                startDatetime = moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
                endDatetime = moment(new Date(year, month, 0).setHours(0, 0, 0));
                startDatetimeB = moment(new Date(year - 1, month - 1, 1).setHours(0, 0, 0));
                endDatetimeB = moment(new Date(year - 1, month, 0).setHours(0, 0, 0));
                break;
            case 'Last90-PrevLast90':
                startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
                startDatetime = this.removeSevenDays(startDatetime, reportType);
                endDatetime = moment(new Date().setHours(0, 0, 0));
                endDatetime = this.removeSevenDays(endDatetime, reportType);
                startDatetimeB = moment(new Date(year, month, day - 180).setHours(0, 0, 0));
                startDatetimeB = this.removeSevenDays(startDatetimeB, reportType);
                endDatetimeB = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
                endDatetimeB = this.removeSevenDays(endDatetimeB, reportType);
                break;
        }
        return { startDatetime: startDatetime,
            endDatetime: endDatetime,
            startDatetimeB: startDatetimeB,
            endDatetimeB: endDatetimeB };
    };
    ReportService.prototype.removeSevenDays = function (date, reportType) {
        var _a, _b;
        if (((_a = reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) || ((_b = reportType) === null || _b === void 0 ? void 0 : _b.includes('comparison')))
            return moment(date).subtract(7, 'days');
        return date;
    };
    ReportService.prototype.addSevenDays = function (date, reportType) {
        var _a, _b;
        if (((_a = reportType) === null || _a === void 0 ? void 0 : _a.includes('rollup')) || ((_b = reportType) === null || _b === void 0 ? void 0 : _b.includes('comparison')))
            return moment(date).add(7, 'days');
        return date;
    };
    ReportService.prototype._updateReport = function (viewMode, reportId, report, automatedReport) {
        if (viewMode == 'new') {
            this.updateReportsInMongo(reportId, __assign(__assign({}, report), { startDatetime: report.startDatetime, endDatetime: report.endDatetime }));
        }
        else {
            this.update(automatedReport, report.gid, reportId, __assign(__assign({}, report), { startDatetime: report.startDatetime, endDatetime: report.endDatetime }));
        }
    };
    ReportService.prototype.reportToDataPicker = function (report, reportId, automatedReport, viewMode, refresh) {
        if (automatedReport === void 0) { automatedReport = false; }
        if (viewMode === void 0) { viewMode = 'new'; }
        if (refresh === void 0) { refresh = false; }
        if (!refresh)
            this.updateDynamicDates(report, reportId, automatedReport, viewMode);
        var dataPicker = {
            range: {
                start: report.startDatetime,
                end: report.endDatetime
            },
            rangeB: null,
            aggregation: 'Month'
        };
        if (report.reportType.includes('comparison') || report.reportType === 'grade') {
            var startMoment = moment(report.startDatetimeB);
            var endMoment = moment(report.endDatetimeB);
            dataPicker.rangeB = {
                start: startMoment.year() + "-" + (startMoment.month() + 1) + "-" + startMoment.date(),
                end: endMoment.year() + "-" + (endMoment.month() + 1) + "-" + endMoment.date()
            };
        }
        var locations = [];
        if (report.accounts) {
            report.accounts.forEach(function (account) {
                locations = locations.concat(account.locations);
            });
        }
        else if (report.locations) {
            locations = report.locations;
        }
        dataPicker.locations = locations;
        return dataPicker;
    };
    ReportService.prototype.selectDatesFromDataPicker = function (dataPicker, rangeB) {
        if (rangeB === void 0) { rangeB = false; }
        var r = rangeB ? dataPicker.rangeB : dataPicker.range;
        return {
            start: moment(r.start).utc(false).utcOffset(0),
            end: moment(r.end).utc(false).utcOffset(0)
        };
    };
    ReportService.prototype.convertReportDate = function (report) {
        if (!report)
            return;
        report.startDatetime = this.convertDate(report.startDatetime);
        report.endDatetime = this.convertDate(report.endDatetime);
        if (report.reportType === 'comparison') {
            report.startDatetimeB = this.convertDate(report.startDatetimeB);
            report.endDatetimeB = this.convertDate(report.endDatetimeB);
        }
        return report;
    };
    ReportService.prototype.convertDate = function (date) {
        return (new ZuluToDatePipe()).transform(date);
    };
    ReportService.prototype.countLocation = function (element) {
        var _a, _b;
        var counter = 0;
        (_b = (_a = element) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.forEach(function (account) {
            var _a, _b;
            counter += (_b = (_a = account) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.length;
        });
        return counter;
    };
    // TODO: Unused, remove?
    // getReports(gid : string, reports) {
    //   const reports$ = [];
    //   reports.forEach(r => {
    //     reports$.push(this.afs.collection<any>(`${GROUPS}/${gid}/${REPORTS}`,
    //       ref => ref.orderBy('reportName')).doc(r.id).snapshotChanges().pipe(
    //         map(report => {
    //           const data = report.payload.data();
    //           if (!data) {
    //             return;
    //           }
    //           // tslint:disable-next-line: no-string-literal
    //           data['id'] = report.payload.id;
    //           return data;
    //         }),
    //     ));
    //   });
    //   return combineLatest(reports$);
    // }
    ReportService.prototype.correctDateAggregate = function (reportDataPicker) {
        var start = moment(reportDataPicker.range.start).utc(true);
        var end = moment(reportDataPicker.range.end).utc(true);
        var diff = end.diff(start, 'days') + 1;
        if (diff >= 90)
            return 'Month';
        else if (diff >= 32)
            return 'Week';
        else if (diff <= 31)
            return 'Day';
        else
            return 'Month';
    };
    ReportService.prototype.periodChange = function (period, startDateTime, endDateTime) {
        var startDate = moment(startDateTime).utc();
        var endDate = moment(endDateTime).utc();
        var data = {
            difference: null,
            startDateB: null,
            endDateB: null,
            rangeBDisabled: true
        };
        switch (period) {
            case 'period':
                data.difference = endDate.diff(startDate, 'days');
                data.endDateB = this.getFormattedMomentDate(startDate.subtract(1, 'days'));
                data.startDateB = this.getFormattedMomentDate(moment(data.endDateB).subtract(data.difference, 'days'));
                break;
            case 'year':
                data.startDateB = this.dateS.momentSubtractYearReport(startDate, 1);
                data.endDateB = this.dateS.momentSubtractYearReport(endDate, 1);
                break;
            case 'month':
                data.difference = endDate.diff(startDate, 'days');
                data.startDateB = this.getFormattedMomentDate(startDate.subtract(1, 'month').startOf('month'));
                data.endDateB = this.getFormattedMomentDate(moment(data.startDateB).endOf('month'));
                break;
            default:
                data.rangeBDisabled = false;
                break;
        }
        return data;
    };
    ReportService.prototype.getFormattedMomentDate = function (date) {
        return date['year']() + "-" + (date['month']() + 1) + "-" + date['date']();
    };
    ReportService.prototype.getFormattedStringDate = function (date) {
        var _a;
        return ((_a = date) === null || _a === void 0 ? void 0 : _a.includes('T')) ? "" + date.split('T')[0] : date;
    };
    ReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.FirestoreService), i0.ɵɵinject(i4.InsightsService), i0.ɵɵinject(i5.AngularFireStorage), i0.ɵɵinject(i6.SnackbarService), i0.ɵɵinject(i7.DatesService), i0.ɵɵinject(i8.LocationService), i0.ɵɵinject(i9.SessionService)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}());
export { ReportService };
