// dep
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';

// app
import { ModalGetAddOn } from './../../modules/modal-get-addon/modal-get-addon.component';
import { UpdateCardComponent } from './../billing/update-card/update-card.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ModalService } from 'src/app/services/modal.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { Pricing } from './../../constants/pricing';
import { ISubscription } from 'src/app/constants/subscription';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';

const mockData = {
  "pricingVersion": 3,    // if <= 2, then old behavior, >= 3 then new behavior
  "packages" : {},
  "pricing": {
    "pkg_core": {  // ALWAYS PRESENT
      "tiers": [
        {
          "locationsQtyMin": 1,
          "locationsQtyMax": 1,
          "price": 30,
          "priceChargeType": "CHARGE_PER_UNIT" /// OR “CHARGE_FLAT” OR “CHARGE_FREE”
        },
        {
          "locationsQtyMin": 2,
          "locationsQtyMax": 5,
          "price": 25,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 6,
          "locationsQtyMax": 10,
          "price": 20,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 11,
          "locationsQtyMax": 25,
          "price": 15,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 26,
          "locationsQtyMax": 50,
          "price": 10,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 50,
          "price": 10,
          "priceChargeType": "CHARGE_PER_UNIT"
        }
      ],
      "description": "Core Package"
    },
    "pkg_bulk_actions": {
      "tiers": [
        {
          "locationsQtyMin": 1,
          "locationsQtyMax": 1,
          "price": 0,
          "priceChargeType": "CHARGE_PER_UNIT" /// OR “CHARGE_FLAT” OR “CHARGE_FREE”
        },
        {
          "locationsQtyMin": 2,
          "locationsQtyMax": 5,
          "price": 20,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 6,
          "locationsQtyMax": 10,
          "price": 15,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 11,
          "locationsQtyMax": 25,
          "price": 10,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 26,
          "locationsQtyMax": 50,
          "price": 5,
          "priceChargeType": "CHARGE_PER_UNIT"
        },
        {
          "locationsQtyMin": 50,
          "price": 5,
          "priceChargeType": "CHARGE_PER_UNIT"
        }
      ],
      "description": "Bulk Actions Add-On"
    }
  }
};

export interface IPricingObject {
  unitPrice: number;
  unitDiscount: number;
  activePacks: string[];
  bulkPrice: number;
  bulkDiscount: number;
  totalPrice: number;
  totalLocations: number;
  trialActive: boolean;
  trialDaysTotal: number;
  trialStart: string;
  trialEnd: string;
  trialProgress: number;
  trialLeft: number;
  coreTiers: IPricingTier[];
  bulkActionsTiers: IPricingTier[];
  billingOverride: boolean;
  invoices: any[];
  nextInvoice: string;
  cards: any[];
}

export interface IPricingTier {
  locationsQtyMin: number;
  locationsQtyMax?: number;
  price: number;
  priceChargeType?: string;
  profileSegment: string;
  isActive: boolean;
  segmentPriceString: string;
}

@Component({
  selector: 'app-billing-new',
  templateUrl: './billing-new.component.html',
  styleUrls: ['./billing-new.component.scss']
})
export class BillingNewComponent implements OnInit {

  public pricingData: Pricing = mockData.pricing; // should hold the real data
  public subscription: ISubscription;
  public isLoading = true;
  public daysLeftInTrial = 0;
  public trialProgress = 0;
  public brandYellow = '#FBBC05';
  readonly listColumns = ['number', 'date', 'status', 'amount', 'actions'] as const
  // we create an IPricingObject object and populate it with the info we require to build the template
  public pricingObject: IPricingObject = {
    unitPrice: 0,
    unitDiscount: 0,
    activePacks: [],
    bulkPrice: 0,
    bulkDiscount: 0,
    totalPrice: 0,
    totalLocations: 0,
    trialActive: false,
    trialDaysTotal: 0,
    trialStart: '',
    trialEnd: '',
    trialProgress: 0,
    trialLeft: 0,
    coreTiers: [],
    bulkActionsTiers: [],
    billingOverride: false,
    invoices: [],
    nextInvoice: '',
    cards: [],
  }


  constructor(
    private _sessionS : SessionService,
    private _subService: SubscriptionService,
    private _paymentService: PaymentsService,
    private _modalService: ModalService,
    private _snack: SnackbarService,
    public navigationS : NavigationService
    ) {}
  
    async ngOnInit(): Promise<void> {
      const {subscription, group} = this._sessionS.getSession();

      this.subscription = subscription;

      if (this.subscription) {

        this.fetchInvoices();
        this.fetchCards();
        
        // dates in this object are prone to cause errors if they are missing in the subscription
        // they shouldn't be missing, but error handling logic should be implemented in the future
        this.pricingObject.billingOverride = this.subscription.billingOverride || false;
        this.pricingObject.nextInvoice = this.subscription.nextDueDate || '';
        this.pricingObject.trialStart = this.subscription.trialStart || '';
        this.pricingObject.trialEnd = this.subscription.trialEnd || '';
        this.pricingObject.trialActive = (this.subscription.status === 'TRIAL');
        this.pricingObject.totalLocations = this.subscription.products?.length || 0;
        if (this.subscription?.packages?.pkg_bulk_actions) {
          this.pricingObject.activePacks.push('pkg_bulk_actions');
        }
        this.getTiers(); // requires totalLocations
        this.pricingObject.unitPrice = this.getPricing(this.pricingObject.totalLocations, 'core');
        this.pricingObject.bulkPrice = this.getPricing(this.pricingObject.totalLocations, 'bulk');
        this.pricingObject.totalPrice = (this.pricingObject.unitPrice - this.pricingObject.unitDiscount + this.pricingObject.bulkPrice) * this.pricingObject.totalLocations;
        

        // const tstart = '2024-06-10 00:00:00.000000'; // for testing
        // const tend = '2024-06-30 00:00:00.000000'; // for testing

        const momentDate1 = moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS");
        const momentDate2 = moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS");
        // const momentDate1 = moment(tend, "YYYY-MM-DD HH:mm:ss.SSSSSS"); // for testing
        // const momentDate2 = moment(tstart, "YYYY-MM-DD HH:mm:ss.SSSSSS"); // for testing

        // Calculate the difference in days
        const trialDuration = momentDate1.diff(momentDate2, 'days');
        
        // if today is after trialEnd then daysLeftInTrial has to be 0

        if (moment().isAfter(moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) {
          // if (moment().isAfter(moment(tend, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) { // for testing
        
          // trialProgress should be 100, because it has finished
          this.pricingObject.trialProgress = 100;
          this.pricingObject.trialLeft = 0;
          
        } else {
          // else, daysLeftInTrial should be equal to trialDuration minus the number of days since trialStart until today

          this.daysLeftInTrial = trialDuration - moment().diff(moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days');
          // this.daysLeftInTrial = trialDuration - moment().diff(moment(tstart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days'); // for testing
          this.pricingObject.trialLeft = this.daysLeftInTrial;

          // trialProgress should be equal to daysLeftInTrial divided by trialDuration multiplied by 100
          this.pricingObject.trialProgress = ((trialDuration - this.daysLeftInTrial) * 100) / trialDuration;
          

        }

        this.isLoading = false;
      } else {
        console.error('no subscription');
        
        this.isLoading = false;
      }
      this.getTiers(); // requires totalLocations
      this.pricingObject.unitPrice = this.getPricing(this.pricingObject.totalLocations, 'core');
      this.pricingObject.bulkPrice = this.getPricing(this.pricingObject.totalLocations, 'bulk');
      this.pricingObject.totalPrice = (this.pricingObject.unitPrice - this.pricingObject.unitDiscount + this.pricingObject.bulkPrice) * this.pricingObject.totalLocations;
      

      // const tstart = '2024-06-10 00:00:00.000000'; // for testing
      // const tend = '2024-06-30 00:00:00.000000'; // for testing

      const momentDate1 = moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS");
      const momentDate2 = moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS");
      // const momentDate1 = moment(tend, "YYYY-MM-DD HH:mm:ss.SSSSSS"); // for testing
      // const momentDate2 = moment(tstart, "YYYY-MM-DD HH:mm:ss.SSSSSS"); // for testing

      // Calculate the difference in days
      const trialDuration = momentDate1.diff(momentDate2, 'days');
      
      // if today is after trialEnd then daysLeftInTrial has to be 0

      if (moment().isAfter(moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) {
        // if (moment().isAfter(moment(tend, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) { // for testing
      
        // trialProgress should be 100, because it has finished
        this.pricingObject.trialProgress = 100;
        this.pricingObject.trialLeft = 0;
        this.pricingObject.trialActive = false;
        
      } else {
        this.pricingObject.trialActive = true;
        // else, daysLeftInTrial should be equal to trialDuration minus the number of days since trialStart until today

        this.daysLeftInTrial = trialDuration - moment().diff(moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days');
        // this.daysLeftInTrial = trialDuration - moment().diff(moment(tstart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days'); // for testing
        this.pricingObject.trialLeft = this.daysLeftInTrial;

        // trialProgress should be equal to daysLeftInTrial divided by trialDuration multiplied by 100
        this.pricingObject.trialProgress = ((trialDuration - this.daysLeftInTrial) * 100) / trialDuration;
        
      }

      this.isLoading = false;

    }
  
  async fetchInvoices(): Promise<void> {
    const {gid} = this._sessionS.getSession()
    const invoices = await this._subService.fetchInvoices(gid) // <-- move this to ngOnInit, its async
    this.pricingObject.invoices = invoices.data.filter(el => ['paid', 'pending', 'pre authorized', 'unpaid', 'canceled'].includes(el.status.toLowerCase()))
    this.pricingObject.invoices.reverse();
    
  }
  
  public async onDownloadInvoicePDF(element): Promise<void> {
    if (!element?.invoicePDF || element.invoicePDF.task?.status !== 'OK') { 
      return; 
    }

    try {
      const invId = element?._id?.$oid || '';
      const blob = await this._subService.getInvoicePDF(invId).toPromise();
      this._downloadFile(blob, `invoice_${invId}.pdf`);
    } catch (error) {
      this._snack.openError('There was an issue downloading the invoice');
    }
  }

  async fetchCards(): Promise<void> {
    const cards = await this._paymentService.getCards(this._sessionS.getSession().gid)
    this.pricingObject.cards = cards || [];
  }

  onDownloadInvoice(): void {
     
  }

  async onAddAddons(): Promise<void> {
    // show add-ons modal
    await this._modalService.openModal(ModalGetAddOn,
      {
        title: 'Add-On: Bulk Actions and Roll-Up Reports',
        message: '',
        buttons: {
          'cancel': 'Maybe Later',
          'accept': 'Get Add-On'
        }
      }, 
      { 
        config: { 
          width: '830px' 
        }
      }
    )
  }

  async onAddCard() : Promise<void> {
    if(!(await this._modalService.openModal(UpdateCardComponent, {})))
      return
    await this._sessionS.refresh()
    await this.fetchCards()
  }

  async handleDefaultSource(card): Promise<void> {
    if(await this._modalService.openConfirmModal("Set as default",
                                               "Are you sure you want use this Credit Card as the default one?",
                                               null, null, "Confirm", false,null, false)) {
        this.applyDefaultSource(card.card); 
      }
  }

  async applyDefaultSource(card): Promise<void> {
    await this._paymentService.setDefaultSource(this._sessionS.getSession().gid, card.id)
    this._snack.openSuccess('Set as default Credit Card succesfully');
    this.fetchCards();
  }

  async handleDeleteSource(card): Promise<void> {
    if(await this._modalService.openConfirmModal("Remove Credit Card",
                                                "Are you sure you want to remove this Credit Card?",
                                                null, null, "Confirm", false, null, false)) {
      this._deleteSource(card.card);
    }
  }

  getTiers(): void {
    // commented until after July 9th deployment
    // const coreTiers = this.subscription?.pricing?.pkg_core?.tiers;
    // const bulkTiers = this.subscription?.pricing?.pkg_bulk_actions?.tiers;

    const coreTiers = this.pricingData.pkg_core.tiers;
    const bulkTiers = this.pricingData.pkg_bulk_actions.tiers;


    coreTiers.forEach(tier => {
      const profileSegment = `${tier.locationsQtyMin}${
        tier.locationsQtyMax && tier.locationsQtyMax > 1 
          ? '-' + tier.locationsQtyMax 
          : !tier.locationsQtyMax 
            ? '+' 
            : ''
      }`;

      const isActive = this.pricingObject.totalLocations >= tier.locationsQtyMin &&
      (tier.locationsQtyMax === undefined || this.pricingObject.totalLocations <= tier.locationsQtyMax);

      const t : IPricingTier = {
        locationsQtyMin: tier.locationsQtyMin,
        locationsQtyMax: tier.locationsQtyMax,
        price: tier.price,
        profileSegment: profileSegment,
        segmentPriceString: `${tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'}`,
        isActive
      }

      this.pricingObject.coreTiers.push(t);
    })

    bulkTiers.forEach(tier => {
      const profileSegment = `${tier.locationsQtyMin}${
        tier.locationsQtyMax && tier.locationsQtyMax > 1 
          ? '-' + tier.locationsQtyMax 
          : !tier.locationsQtyMax 
            ? '+' 
            : ''
      }`;

      const isActive = this.pricingObject.totalLocations >= tier.locationsQtyMin &&
      (tier.locationsQtyMax === undefined || this.pricingObject.totalLocations <= tier.locationsQtyMax);

      const t : IPricingTier = {
        locationsQtyMin: tier.locationsQtyMin,
        locationsQtyMax: tier.locationsQtyMax,
        price: tier.price,
        profileSegment: profileSegment,
        segmentPriceString: `${tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'}`,
        isActive
      }

      this.pricingObject.bulkActionsTiers.push(t);
    })
  }

  getPricing(locations: number, pkgName: string): number {
    switch (pkgName) {
      case 'core':
        for (let tier of mockData?.pricing?.pkg_core?.tiers) {
          if (locations >= tier.locationsQtyMin && 
              (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
            return tier.price;
          }
        }
        break;
        case 'bulk':
          if (this.pricingObject.activePacks.includes('pkg_bulk_actions')) {

            for (let tier of mockData?.pricing?.pkg_bulk_actions?.tiers) {
              if (locations >= tier.locationsQtyMin && 
              (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                return tier.price;
              }
            }
          } else {
            return 0;
          }
          break;
      default:
        return 0;
    }
  }

  private async _deleteSource(card): Promise<void> {
    await this._paymentService.deleteCard(this._sessionS.getSession().gid, card.id);
    this._snack.openSuccess('Removed Credit Card succesfully');
    this.fetchCards();
  }

  private _downloadFile(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

}
