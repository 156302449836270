// dep
import moment from 'moment'


// FIXME: Those dates are evaluated when the app is first started and then no more,
// so if a day passes without closing the app the dates will be wrong. 

export const DATE_PICKER_RANGES = {
  // Today: [moment(), moment()],
  // Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(7, 'days'), moment()],
  'Last 30 Days': [moment().subtract(30, 'days'), moment()],
  'Last 90 Days': [moment().subtract(90, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  'YTD': [moment().startOf('year'), moment()]
};

// TODO: Unused, remove?
// export const DATE_PICKER_RANGES_ONLY_MONTH_AND_YEAR = {
//   'This Month': [moment().startOf('month'), moment().endOf('month')],
//   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
//   'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
//   YTD: [moment().startOf('year'), moment()]
// };


export const DEFAULT_CHART_DATE_SELECT = {
  start: moment().subtract(3, 'month').utc(),
  end: moment().utc()
};

// TODO: Unused, remove?
// export const DEFAULT_DATE_SELECT_LAST_THREE_MONTHS = {
//   start: moment().subtract(3, 'month').startOf('month'),
//   end: moment(new Date(new Date().setDate(0)).toDateString()),
// };
// 
// export const CHART_DATE_SELECT_ONE_YEAR_AGO = {
//   start: moment().subtract(1, 'year').utc(),
//   end: moment().utc()
// };
//
// export const CHART_DATE_SELECT_ONE_YEAR_AGO_COMPLETED = {
//   start: moment().subtract(1, 'year').startOf('month'),
//   end: moment(new Date(new Date().setDate(0)).toDateString()),
// };

export const CHART_DATE_SELECT_ONE_MONTH = {
  start: moment().startOf('month').utc(),
  end: moment().endOf('month').utc()
}

// TODO: Unused, remove?
// export const VAR_MONTH_PICKER = {
//   start: 'Year',
//   depth: 'Year',
//   format: 'MMM yyyy',
//   today: new Date(new Date().toDateString()),
//   monthStart: new Date(new Date(new Date().setDate(1)).toDateString()),
//   monthEnd: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),
//   lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
//   lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
//   yearStart: new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString()),
//   yearEnd: new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString()),
//   thisYearStart: new Date(new Date(new Date().getFullYear(), 0, 1).toDateString()),
//   lastTheeMonthsStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setDate(1)).toDateString()),
//   lastTheeMonthsEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
// }

export const VAR_MONTH_PICKER_YEAR_AGO = {
  start: 'Year',
  depth: 'Year',
  format: 'MMM yyyy',
  today: new Date(new Date().toDateString()),
  monthStart: new Date(new Date(new Date().setDate(1)).toDateString()),
  monthEnd: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),
  lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
  lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
  yearStart: new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString()),
  yearEnd: new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString()),
  thisYearStart: new Date(new Date(new Date().getFullYear(), 0, 1).toDateString()),
  lastYearStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 13)).setDate(1)).toDateString()),
  lastYearEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
}