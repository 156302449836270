// dep
import { Routes } from '@angular/router';

// app
import { MainComponent } from './modules/main/main.component';
import { AccountsComponent } from './modules/accounts/accounts.component';
import { LocationsComponent } from './modules/locations/locations.component';
import { RollupReportComponent } from './modules/rollup-report/rollup-report.component';
import { ReviewReportsComponent } from './modules/review-reports/review-reports.component';
import { ComparisonReportsComponent } from './modules/comparison-reports/comparison-reports.component';
import { RevenueReportsComponent } from './modules/revenue-reports/revenue-reports.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { MetaGuardService } from './services/meta-guard.service';
import { RedirectGuardService as RedirectGuard } from './services/redirect-guard.service';
import { LocationFreeGuardService as LocationFreeGuard } from './services/location-guard.service';
import { BlankLayoutComponent, DefaultLayoutComponent } from './layouts';
import { UpgradeComponent } from './modules/upgrade/upgrade.component';
import { ReviewReportInsightComponent } from './modules/review-report-insight/review-report-insight.component';
import { RegisterComponent } from './modules/register/register.component';
import { RevenueReportInsightComponent } from './modules/revenue-report-insight/revenue-report-insight.component';
import { ComparisonReportInsightComponent } from './modules/comparison-report-insight/comparison-report-insight.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardInsightsComponent } from './modules/dashboard-insights/dashboard-insights.component';
import { DashboardPerformanceInsightComponent } from './modules/dashboard-performance-insight/dashboard-performance-insight.component';
import { DashboardReviewsComponent } from './modules/dashboard-reviews/dashboard-reviews.component';
import { DashboardPostsComponent } from './modules/dashboard-posts/dashboard-posts.component';
import { DashboardLocationComponent } from './modules/dashboard-location/dashboard-location.component';
import { DashboardGradeComponent } from './modules/dashboard-grade/dashboard-grade.component';
import { DashboardWidgetComponent } from './modules/dashboard-widget/dashboard-widget.component';
//import { DashboardReviewLinkComponent } from './modules/dashboard-review-link/dashboard-review-link.component';
import { DashboardSearchComponent } from './modules/dashboard-search/dashboard-search.component';
import { DashboardWorkshopComponent } from './modules/dashboard-workshop/dashboard-workshop.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { ReviewAutoResponderComponent } from './modules/review-auto-responder/review-auto-responder.component';
import { BulkPostComponent } from './modules/bulk-post/bulk-post.component';
import { ExternalGradeComponent } from './modules/external-grade/external-grade.component';
import { TextReaderComponent } from './modules/text-reader/text-reader.component';
import { VerifyEmailComponent } from './modules/verify-email/verify-email.component';
import { LocationInfoListComponent } from './modules/location-info-list/location-info-list.component';

import { DashboardServicesComponent } from './modules/dashboard-services/dashboard-services.component';
import { DashboardPhotosComponent } from './modules/dashboard-photos/dashboard-photos.component';
import { DashboardMenuComponent } from './modules/dashboard-menu/dashboard-menu.component';
import { SearchKeywordsComponent } from './modules/search-keywords/search-keywords-report/search-keywords.component';
import { SearchKeywordsViewComponent } from './modules/search-keywords/search-keywords-view/search-keywords-view.component';
import { QuestionsAnswerComponent } from './modules/questions-answer/questions-answer.component';
import { QuestionsAnswersViewComponent } from './modules/questions-answer/questions-answers-view/questions-answers-view.component';
import { ProfilesComponent } from './modules/profiles/profiles.component';
import { BulkActionsGuardService } from './services/bulk-actions-guard.service';

// TODO: Move to lazy-loading
import { UsersManagementComponent } from './settings/users-management/users-management.component';
import { BillingGuardService } from './services/billing-guard.service';
import { BillingComponent } from './settings/billing/billing.component';
import { PricingGuardService } from './services/pricing-guard.service';
import { BillingNewComponent } from './settings/billing-new/billing-new.component';
import { WidgetComponent } from './widget/components/widget.component';

// canActivate guards notes:
//   - guards will prevent the full page to be loaded until they resolve, not only 
//     the related child component, so the loader spinner won't work.
//   - mutiple async guards will be resolved concurrently

export const appRoutes: Routes = [
  //-------------------------
  // Redirect / => /login
  //-------------------------
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },

  //----------------------------------------------------------
  // Logged-OFF routes (only requires Domain to be loaded)
  // NO shared container component.
  //----------------------------------------------------------
  {
    path: 'widget/:gid/:accountId/:locationId/review',
    // loadChildren: './widget/widget.module#WidgetModule'
    // loadChildren: () => WidgetModule
    component: WidgetComponent
  },
  {
    path: 'verify',
    component: VerifyEmailComponent
  },
  {
    path: 'text-reader/:text',
    component: TextReaderComponent
  },
  {
    path: 'report/:gid/:id/:reportType/shared',
    component: ReportsComponent
  },
  {
    path: 'report/automated/:gid/:automatedReport/:reportType/shared',
    component: ReportsComponent,
  },
  {
    path: 'reports/:hash/auth',
    component: ReportsComponent,
  },
  {
    path: 'grade',
    component: ExternalGradeComponent,
    data: { title: 'Grader' }
  },
      
  
  //----------------------------------------------------------
  // Logged-OFF routes (only requires Domain to be loaded)
  // BlankLayoutComponent as the shared container component.
  //----------------------------------------------------------
  {
    path: '',
    component: BlankLayoutComponent, 
    children : [
      {
        path: 'login',
        data: { title: 'Login' },
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        data: { title: 'Forgot password' },
        component: ForgotPasswordComponent
      },
      {
        path: 'register',
        data: { title: 'Register', robots: 'noindex, nofollow' },
        canActivate: [MetaGuardService],
        component: RegisterComponent,
      }
    ]
  },

  //----------------------------------------------------------
  // Logged-in routes
  // DashboardLayoutComponent as the shared container component.
  //----------------------------------------------------------
  {
    path: 'account/:accountId/location/:locationId',
    component: DashboardLayoutComponent, // container component
    data : {
       allowedSessionTypes : ['NORMAL']
    },
    canActivate: [AuthGuard],

    children: [
      {
        path: 'workshop',
        component: DashboardWorkshopComponent,
        data: { title: 'Workshop' },
        canActivate: [LocationFreeGuard],
      },
      {
        path: 'keywords',
        component: SearchKeywordsComponent,
        data: { 
          title: 'Keywords',
          isReport: false
        },
        //loadChildren: () => SearchKeywordsModule
      },
      {
        path: 'insights',
        component: DashboardPerformanceInsightComponent,
        data: { title: 'Insights' }
      },
      {
        path: 'legacy-insights',
        component: DashboardInsightsComponent,
        data: { title: 'Insights' }
      },
      {
        path: 'reviews',
        component: DashboardReviewsComponent,
        data: { title: 'Reviews' }
      },
      {
        path: 'qanda',
        component: QuestionsAnswerComponent,
        data: { title: 'Q & A' },
      },
      {
        path: 'posts',
        component: DashboardPostsComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Posts' }
      },
      {
        path: 'post-management',
        component: DashboardPostsComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Posts' }
      },
      {
        path: 'location-info',
        component: DashboardLocationComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Location Info' }
      },
      {
        path: 'services',
        component: DashboardServicesComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Services' }
      },
      {
        path: 'menu',
        component: DashboardMenuComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Menu' }
      },
      {
        path: 'photos',
        component: DashboardPhotosComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Photos' }
      },
      {
        path: 'grade',
        component: DashboardGradeComponent,
        data: { title: 'Grade' }
      }, {
        path: 'widget',
        component: DashboardWidgetComponent,
        canActivate: [LocationFreeGuard],
        data: { title: 'Review Widget' }
      }, 
      // TODO: Unused route, remove. 
      // {
      //   path: 'review-link-generator',
      //   component: DashboardReviewLinkComponent,
      //   canActivate: [LocationFreeGuard],
      //   data: { title: 'Review Link Generator' }
      // },
      {
        path: 'search',
        component: DashboardSearchComponent,
        data: { title: 'Search' }
      },
    ]
  },


  //----------------------------------------------------------
  // Logged-IN routes
  // DefaultLayoutComponent as the shared container component.
  // TODO: Some of these use canActivate guards that awaits for the
  // session, so the page is not rendered until the session finishes
  // loading, and the spinner is not shown. 
  //----------------------------------------------------------
  {
    path: 'settings',
    component: DefaultLayoutComponent,
    data: {
      pageNeedsSession : true,
      allowedSessionTypes : ['NORMAL']
    },
    canActivate: [AuthGuard],

    // This was configured as lazy loading using:
    //   loadChildren: './settings/settings.module#SettingsModule',
    // But was fragile as the 'ng' build watcher sometimes compiled it and
    // sometimes not, causing a browser runtime error like
    //   "Error: Cannot find module './settings/settings.module'"
    // Also the 'ng serve` command sometimes didn't watch the settings.module.ts,
    // so changing it didn't trigger a recompilation until you also changed
    // 'app.router.ts'
    // Also this was an old lazy-loading syntax, on angular >=9 change to:
    //   loadChildren : () => import('./settings/settings.module').then(m => m.SettingsModule)
    // loadChildren : () => SettingsModule, 
    // Moved to eager loading for now, until upgrading Angular version.

    children : [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'users',
        data: { title: 'User Management' },
        component: UsersManagementComponent
      },
      {
        path: 'billing',
        data: { title: 'Plans & Billing' },
        canActivate: [BillingGuardService],
        component: BillingComponent
      },
      {
        path: 'plan',
        data: { title: 'Plan and Billing' },
        canActivate: [PricingGuardService],
        component: BillingNewComponent
      }
    ]
  },

  {
    path: '',
    component: DefaultLayoutComponent, // top-level component
    data: {
      title: '',
      // Wait the session to be available before instancing/
      // rendering the child component. Will show the spinner
      // meanwhile.
      pageNeedsSession : true,
      allowedSessionTypes : ['NORMAL']
    },
    canActivate: [AuthGuard],

    children: [
      {
        path: 'home',
        component: MainComponent
      },
      {
        path: 'accounts',
        component: AccountsComponent,
        data: { title: 'Accounts' }
      },
      {
        path: 'report/:gid/:id',
        component: ReportsComponent,
        data: { title: 'Report' }
      },

      {
        path: 'report/:gid/:id/:reportType',
        component: ReportsComponent,
        data: { title: 'Report' }
      },
      {
        path: 'reports/:id/keywords',
        component: SearchKeywordsComponent,
        data: {
          title: 'Keywords',
          isReport: true
        }
      },
      {
        path: 'accounts/:accountObjectId/locations',
        component: LocationsComponent,
        data: { title: 'Locations' }
      },
      {
        path: 'profiles',
        component: ProfilesComponent,
        data: { title: 'Profiles' }
      },
      {
        path: 'location/:locationId/workshop',
        component: DashboardWorkshopComponent,
        canActivate: [RedirectGuard, LocationFreeGuard]
      },
      {
        path: 'local-posts',
        component: BulkPostComponent,
        data: { title: 'Bulk Post Management' },
        canActivate: [BulkActionsGuardService]
      },
      {
        path: 'posts-management',
        component: BulkPostComponent,
        data: { title: 'Bulk Post Management' },
        canActivate: [BulkActionsGuardService]
      },
      {
        path: 'local-bulk',
        component: LocationInfoListComponent,
        data: { title: 'Bulk Location Info' },
        canActivate: [BulkActionsGuardService]
      },
      {
        path: 'qanda',
        component: QuestionsAnswersViewComponent,
        data: { title: 'Q & A' },
        canActivate: [BulkActionsGuardService]
      },
      {
        path: 'review-assistant',
        component: ReviewAutoResponderComponent,
        data: { title: 'Review Assistant' }
      },
      {
        path: 'rollup-report',
        component: RollupReportComponent,
        data: { title: 'Legacy Rollup Report' },
      },
      {
        path: 'performance-rollup-report',
        component: RollupReportComponent,
        data: { title: 'Performance Rollup Report' },
        canActivate: [BulkActionsGuardService]
      },
      {
        path: 'review-report',
        component: ReviewReportsComponent,
        data: { title: 'Review Report' },
      },
      {
        path: 'comparison-report',
        component: ComparisonReportsComponent,
        data: { title: 'Comparison Report' },
      },
      {
        path: 'performance-comparison-report',
        component: ComparisonReportsComponent,
        data: { title: 'Comparison Report' }
      },
      {
        path: 'performance-revenue-report',
        component: RevenueReportsComponent,
        data: { title: 'Revenue Report' }
      },
      {
        path: 'revenue-report',
        component: RevenueReportsComponent,
        data: { title: 'Revenue Report' }
      },
      {
        path: 'keywords',
        component: SearchKeywordsViewComponent,
        data: {
          title: 'Keywords',
          isReport: true
        }
      },
      {
        path: 'upgrade',
        component: UpgradeComponent
      },

    ]
  },

  //----------------------------------------------------------
  // Logged-OFF / Logged-IN routes
  // DefaultLayoutComponent as the shared container component.
  //----------------------------------------------------------
  {
    path: '',
    component: DefaultLayoutComponent, // top-level component
    data: {
      title: '',
      pageNeedsSession : false
    },
    children: [
      {
        path: 'report/:gid/:id/shared',
        component: ReportsComponent
      },      
      {
        // TODO: Unused route/component?
        path: 'review-report-insight',
        component: ReviewReportInsightComponent
      },
      {
        // TODO: Unused route/component?
        path: 'revenue-report-insight',
        component: RevenueReportInsightComponent
      },
      {
        // TODO: Unused route/component?
        path: 'comparison-report-insight',
        component: ComparisonReportInsightComponent
      },
      {
        // TODO: Unused route/component?
        path: 'auto-responder',
        component: ReviewAutoResponderComponent
      },
    ]
  },

  //---------------------------------------
  // Redirect * => /login (fallback route)
  //---------------------------------------
  { path: '**', 
    redirectTo: '/login' 
  }
];
