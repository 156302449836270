import { __assign, __awaiter, __generator, __read, __spread, __values } from "tslib";
// import { AngularFirestore, } from '@angular/fire/firestore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FirestoreService } from './firestore.service';
import { map } from 'rxjs/operators';
import { ReviewsService } from './reviews.service';
// import { AuthService } from './auth.service';
import { environment } from '@environment';
import { PAGEABLE_DEFAULT } from '../constants/api-response';
import { locationRefsToPaths } from '../constants/firestore/location-object';
import * as i0 from "@angular/core";
import * as i1 from "./firestore.service";
import * as i2 from "./reviews.service";
import * as i3 from "@angular/common/http";
var ProtocolService = /** @class */ (function () {
    function ProtocolService(fs, 
    // private afs: AngularFirestore,
    reviewS, http) {
        this.fs = fs;
        this.reviewS = reviewS;
        this.http = http;
    }
    ProtocolService.prototype.verifyProtocol = function (protocol, protocol_id) {
        if (protocol_id === void 0) { protocol_id = null; }
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var exist, duplicatedProtocol, evaluatedProtocols, duplicatedProtocol_1, response_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.http.get(environment.apiUrl + "/v2/protocol/" + protocol.gid + "/verify_protocol?stars=" + protocol.stars + "&withComment=" + protocol.withComment + "&withOutComment=" + protocol.withOutComment).toPromise()];
                    case 1:
                        exist = _c.sent();
                        duplicatedProtocol = null;
                        if (((_b = (_a = exist) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) == 0) {
                            return [2 /*return*/, { result: true, duplicatedProtocol: null }]; // it doesn't exist any protocols that match with these condition
                        }
                        else {
                            evaluatedProtocols = [];
                            duplicatedProtocol_1 = null;
                            response_1 = [];
                            if (protocol_id !== null) { // if we are editing an existing protocol
                                evaluatedProtocols = exist.data.filter(function (el) { return el.protocolId !== protocol_id; });
                            }
                            else {
                                evaluatedProtocols = exist.data;
                            }
                            evaluatedProtocols.map(function (snap_protocol) {
                                var e_1, _a;
                                var _loop_1 = function (account) {
                                    var e_2, _a;
                                    if (protocol.accounts.some(function (p) { return p.accountId == account.accountId; })) {
                                        var _loop_2 = function (location_1) {
                                            if (protocol.accounts.some(function (p) { return p.locations.some(function (lp) { return lp.locationId == location_1.locationId; }); })) {
                                                if (protocol.withComment === true) {
                                                    if (protocol.triggers.keywords.length === 0 && snap_protocol.triggers.keywords.length === 0) {
                                                        // both protocols have zero keywords
                                                        duplicatedProtocol_1 = snap_protocol;
                                                        response_1.push(false);
                                                    }
                                                    else if (protocol.triggers.keywords.some(function (k) { return snap_protocol.triggers.keywords.some(function (sk) { return k === sk; }); })) {
                                                        // if both protocols have at least one same keyword
                                                        duplicatedProtocol_1 = snap_protocol;
                                                        response_1.push(false);
                                                    }
                                                }
                                                else {
                                                    duplicatedProtocol_1 = snap_protocol;
                                                    response_1.push(false);
                                                }
                                            }
                                        };
                                        try {
                                            for (var _b = (e_2 = void 0, __values(account.locations)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                                var location_1 = _c.value;
                                                _loop_2(location_1);
                                            }
                                        }
                                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                        finally {
                                            try {
                                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                            }
                                            finally { if (e_2) throw e_2.error; }
                                        }
                                    }
                                };
                                try {
                                    for (var _b = __values(snap_protocol.accounts), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        var account = _c.value;
                                        _loop_1(account);
                                    }
                                }
                                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_1) throw e_1.error; }
                                }
                                response_1.push(true);
                            });
                            // we return the final evaluation, where we check for duplicated protocols
                            return [2 /*return*/, { result: response_1.every(function (r) { return r == true; }), duplicatedProtocol: duplicatedProtocol_1 }];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProtocolService.prototype.save = function (protocol, scan) {
        if (scan === void 0) { scan = false; }
        return __awaiter(this, void 0, void 0, function () {
            var body, response, data_protocol;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        protocol.scan = scan;
                        body = { protocol: protocol };
                        return [4 /*yield*/, this.http.post(environment.apiUrl + "/v2/protocol/" + protocol.gid + "/add", body).toPromise()];
                    case 1:
                        response = _a.sent();
                        data_protocol = response.data;
                        return [4 /*yield*/, this.reviewS.protocolValidateReviews(data_protocol.gid, data_protocol.protocolId)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ProtocolService.prototype.update = function (protocol, id) {
        return __awaiter(this, void 0, void 0, function () {
            var body, data_protocol;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = { protocol: protocol };
                        return [4 /*yield*/, this.http.post(environment.apiUrl + "/v2/protocol/update/" + id, body).toPromise()];
                    case 1:
                        data_protocol = _a.sent();
                        return [2 /*return*/, data_protocol];
                }
            });
        });
    };
    ProtocolService.prototype.delete_reviews_to_reply = function (gid, protocol_id) {
        return this.http.delete(environment.apiUrl + "/v2/reviews/gid/" + gid + "/protocol/" + protocol_id + "/reply").toPromise();
    };
    ProtocolService.prototype.check_protocol = function (gid, protocol_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reviewS.protocolValidateReviews(gid, protocol_id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ProtocolService.prototype.get_all_flex = function (gid, value) {
        return this.http.get(environment.apiUrl + "/v2/protocol/" + gid + "/all_flex?scan=" + value).toPromise();
    };
    // YAM: MIGRATE!!
    ProtocolService.prototype.getProtocolsPaginate = function (count, pageable, actions) {
        return this.fs.formatPagination(count, pageable, actions);
    };
    ProtocolService.prototype.paginate = function (gid, pageable, next, prev, values, where) {
        if (values === void 0) { values = false; }
        return this.http.get(environment.apiUrl + "/v2/protocol/" + gid + "/all?page=" + pageable.page + "&pageSize=" + pageable.size + "&prev=" + prev + "&next=" + next + "&value=" + values + "&where=" + where);
    };
    ProtocolService.prototype.delete = function (protocolId) {
        return this.http.delete(environment.apiUrl + "/v2/protocol/" + protocolId).toPromise();
    };
    ProtocolService.prototype.getLocationNamesProtocolLog = function () {
        return this.http.get(environment.apiUrl + "/v2/reviews/protocol_log/name").pipe(map(function (response) {
            var _a;
            return ((_a = response) === null || _a === void 0 ? void 0 : _a.data) ? __spread(response.data.map(function (l) { return (__assign({}, l.location)); })) :
                [];
        }));
    };
    ProtocolService.prototype.getAccountsProtocols = function () {
        return this.http.get(environment.apiUrl + "/v2/reviews/search/accounts")
            .pipe(map(function (response) { var _a; return ((_a = response) === null || _a === void 0 ? void 0 : _a.data) || []; }));
    };
    ProtocolService.prototype.getLocationsProtocols = function (accountId) {
        return this.http.get(environment.apiUrl + "/v2/reviews/account/" + accountId + "/search/locations")
            .pipe(map(function (response) { var _a; return ((_a = response) === null || _a === void 0 ? void 0 : _a.data) || []; }));
    };
    ProtocolService.prototype.getProtocolLog = function (pageable, filter) {
        var params = PAGEABLE_DEFAULT;
        if (pageable) {
            params = new HttpParams()
                .set('page', pageable.page.toString())
                .set('size', pageable.size.toString());
        }
        return this.http.post(environment.apiUrl + "/v2/reviews/protocol_log", __assign({}, filter), {
            params: params
        }).pipe(map(function (response) { var _a; return ((_a = response) === null || _a === void 0 ? void 0 : _a.data) || []; }));
    };
    ProtocolService.prototype.getProtocolReport = function (locations, pageable) {
        var params = PAGEABLE_DEFAULT;
        if (pageable) {
            params = new HttpParams()
                .set('page', pageable.page.toString())
                .set('size', pageable.size.toString());
        }
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/protocols-report", body, {
            params: params
        }).pipe(map(function (response) { return response || null; }));
    };
    ProtocolService.prototype.getProtocolDataCounters = function (locations) {
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/data-protocols", body)
            .pipe(map(function (response) { var _a; return ((_a = response) === null || _a === void 0 ? void 0 : _a.data) || []; }));
    };
    ProtocolService.prototype.getProtocolDataChartCounters = function (locations, dataPicker) {
        var params = new HttpParams()
            .set('startDateTime', dataPicker.range.start)
            .set('endDateTime', dataPicker.range.end)
            .set('aggregate', dataPicker.aggregation.toLowerCase());
        var body = { locations: locationRefsToPaths(locations) };
        return this.http.post(environment.apiUrl + "/v2/reviews/protoco-report-data", body, { params: params }).pipe(map(function (response) { return response.data; }));
    };
    ProtocolService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProtocolService_Factory() { return new ProtocolService(i0.ɵɵinject(i1.FirestoreService), i0.ɵɵinject(i2.ReviewsService), i0.ɵɵinject(i3.HttpClient)); }, token: ProtocolService, providedIn: "root" });
    return ProtocolService;
}());
export { ProtocolService };
