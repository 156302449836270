// dep
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import * as _ from 'lodash';

// app
import { LocationService } from '../../services/location.service';
import SavedLocation from '../../constants/firestore/saved-location';
import { SubscriptionService } from '../../services/subscription.service';
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ObservationService } from '../../services/observation.service';
import { ModalService } from '../../services/modal.service';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import * as constants_locations from '../../constants/firestore/account-location'
import * as constants_plans from '../../constants/firestore/plan'
import { PaginatorComponent } from '../charts/paginator/paginator.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationID, LocationRef } from 'src/app/constants/firestore/location-object';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { markedPositions } from 'src/app/helpers/utils.helpers';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: [ './locations.component.scss']
})
export class LocationsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('paginator', { static: false }) paginatorComponent: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  public STATUS_CHANGE_PLAN         = constants_plans.STATUS_CHANGE_PLAN;
  public LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
  // public authOk           = constants_locations.VERIFICATION_OK;
  public authUnauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
  public authUnauthorized_2 = constants_locations.VERIFICATION_UNAUTHORIZED_2;
  public authNotFound     = constants_locations.VERIFICATION_NOT_FOUND;
  public authNotFound_2   = constants_locations.VERIFICATION_NOT_FOUND_2;
  public accountError     = constants_locations.VERIFICATION_ACCOUNT_ERROR;
  public verifRequired    = constants_locations.VERIFICATION_REQUIRED;

  public totalLocations: SavedLocation[] = [];
  public filteredLocationIds: LocationID[] = [];
  public filteredOptions: Observable<any>;
  // public countLocations: number; // TODO: Unused, remove.
  public locations: any[] = [];
  public accountsOptions = [];
  public selectedAccount: object
  public labelAccountsFiltered: string;
  public filterAccountsControl = new FormControl('');
  public accountObjectId: string;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<SavedLocation[]>;
  public allLocationCheck = false;
  public allChecked = false;
  // public checkedLocations; // TODO: Unused, remove. 
  public listCheckLocations: (Pick<SavedLocation, 'locationId' | 'subscriptionType' | 
                                                  'pendingChange' | 'locationName' | 
                                                  'location'> & {checked : boolean})[] = [];
  public upgradeCheckSelect = false;
  public downgradeCheckSelect = false;
  public manualPage: number;
  public errorMessage: boolean;
  public locationsUser : LocationID[] = []; 
  public textSearch = null;
  public size = 10;
  public page = 1;
  public pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public selectedSearch = new FormControl();
  public filtered = false;
  public searchText: string;
  public loadingTable$   = new BehaviorSubject(true);
  public NotFoundSearch$ = new BehaviorSubject(false);
  public timeout: ReturnType<typeof setTimeout> | null = null
  public errorAccount = false;

  private _hasAccountsFilter = false
  private _refreshTimerStarted = false
  private _paginate : Pageable
  // private _previousPageable: Pageable = null;

  session$ = this._sessionS.session$

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _detectStrategy: ChangeDetectorRef,
    private _spinnerService: SpinnerService,
    private _snack: SnackbarService,
    private _locationService: LocationService,
    private _accountService: AccountService,
    private _apiSubscription: SubscriptionService,
    private _obsService: ObservationService,
    private _modalService: ModalService,
    private _angularLocation: AngularLocation,
    private _sessionS : SessionService
  ) {
    super(); 

    this._subscribeSafe(this.session$, _ => this._updateTable());

    // Reload the locations table when we trigger a location plan change from this
    // component or the plan change is externally started by TrialMessageComponent
    let is_first_value = true
    this._subscribeSafe(this._locationService.someLocationChanged$, 
      _ => {
        if(is_first_value)
          // Avoid running _init() on component creation, as ngOnInit already
          // runs it.
          is_first_value = false
        else
          this._init()
      }
    )
  }

  ngOnInit(): void {
    this._subscribeSafe(this._locationService.getPaginate(),
      res => this._paginate = res
    )

    this._init();

    this._subscribeSafe(this._router.events, event => {
      if (event instanceof NavigationEnd) {
        this.loadingTable$.next(true);
        this.accountObjectId = event.url.split('/')[2]
        this.filterAccountsByAccountId();
        this._init();
      }
    });
  }

  ngAfterViewInit(): void {
    this.manualPage = 1;
    this.errorMessage = false;
    this._detectStrategy.detectChanges();
  }

  ngOnDestroy(): void {
    this._locationService.reset();
    super.ngOnDestroy();
  }

  resetListSelect(): void {
    this.listCheckLocations = []
  }

  
   async onAddAccount(): Promise<void> {
     await this._router.navigate(['accounts'])
     this._obsService.sendAddAccount()
   }

  async openDeleteDialog(location : SavedLocation): Promise<void> {
    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect ${location?.locationName} from ${domainCompany}?`, 
      `Note: this only disconnects the profile from ${domainCompany}; it does not delete your business profile from Google Maps. You can always re-add your business profile to ${domainCompany}.`)) {
      return;
    }
    
    const {gid} = this._sessionS.getSession();
     
    try {
      this._spinnerService.loading$.next(true);
      const accountWasDeleted = await this._locationService.deleteLocation(gid, this.accountObjectId, location);
      if(accountWasDeleted) {
        await this._router.navigate(['/accounts']);
        return;
      } else {
        this.listCheckLocations = [];
        this._init()
        return;
      }

    } catch(err) {
      console.error('Error deleting locations', err);
    } finally {
      this._spinnerService.loading$.next(false);
    }
  }

  private _initPaginator(): void {
    this._paginate = { page: 1, size: 10 };
    this.paginatorComponent.reset();
    // this._previousPageable = null;
  }

  // apply filter from search
  applyFilter($event: string): void {
    this.NotFoundSearch$.next(false);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

       if (!$event) {
         this.filtered = false;
         this.filteredLocationIds = [];
         this.loadingTable$.next(true);
         this._initPaginator();
         this._getData(this._paginate);
       } else {
         if ($event[$event.length - 1] !== ' ') {
           if (!this.filtered) 
              this._initPaginator();
           this.filtered = true;
           this.loadingTable$.next(true);
           const text = $event.toLowerCase();
           this.searchText = text;
           this._updateData(this._paginate, text)
         }
       }

     }, 350);
  }

  private async _getData($event: Pageable, locationsIds : LocationID[] = []): Promise<void> {
    // TODO: Probably called multiple times in parallel, implement coalescing. 
    const {gid, isAdmin} = this._sessionS.getSession(); 
    if (!isAdmin) {
      locationsIds = this.locationsUser;
    }

    try {
      const r = await this._locationService.fetchAccountLocationsPaginated(gid, this.accountObjectId, $event, locationsIds);

      // this._previousPageable = { size: r['perPage'], 
      //                            page: r['currentPage'] };
      this.pagination = {
        items:    r['items'],
        per_page: r['perPage'],
        page:     r['currentPage'],
        hasNext:  r['currentPage'] < r['totalPages'],
        hasPrev:  r['currentPage'] > 1,
        pages:    r['totalPages'],
        total:    r['totalItems']
      };
      this.locations = this._formatData(r['items']);
      // this.countLocations = r['totalItems']; // TODO: Unused, remove.
      this._updateTable();
    } catch (err) {
      console.error(err);
    } finally {
      this._spinnerService.loading$.next(false);
    }

  }

  private async _updateData($event: Pageable, text: string): Promise<void> {
    const {gid} = this._sessionS.getSession(); 

    const response = await this._locationService.getLocationsIdsByStringQuery(gid, text);
    const filteredAccount = response?.accounts?.find(account => account.accountId === this.accountObjectId) || null
    this.filteredLocationIds = filteredAccount?.locations?.map(location => location.locationId) || []
    const locationIds = this.filteredLocationIds
    if (locationIds.length > 0) {
      this._getData($event, locationIds)
    } else {
      this.NotFoundSearch$.next(true)
      this.loadingTable$.next(false)
    }
  }

  setElementSwitch(element): string {
    let returnStatus = '';
    if (element?.location?.locationState?.isVerified === false) {
      returnStatus = this.verifRequired;
    } else if (element?.lastCheck?.status && element.lastCheck.status !== 'OK') {
      returnStatus = (
        element === 'NOT AUTHORIZED' || element === 'NOT_AUTHORIZED' ? 'NOT_AUTHORIZED' :
        element === 'NOT FOUND' || element === 'NOT_FOUND' ? 'NOT_FOUND' : element.lastCheck.status
      );
    } else if (element?.errorLog) {
      returnStatus = 'errorLog';
    } 
    console.log(element?.location?.locationName, returnStatus);
    return returnStatus;
  }

  // check for nested objects
  private _nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this._nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }


  toggleCheckAll(check: MatCheckboxChange) : void {
    this.allChecked = check.checked;
    this._getTotalLocations();
    this.locations.forEach(location => {
      if (this.allChecked) {
        const isChecked = this.listCheckLocations.find(l => l.locationId == location.locationId);
        if (!isChecked) {
          this.listCheckLocations.push({
            locationId: location.locationId,
            subscriptionType: location.subscriptionType,
            pendingChange: location.pendingChange,
            locationName: location.locationName,
            location: location.location,
            checked: this.allChecked
          });
        }
      } else {
        this.listCheckLocations = [];
        this.allLocationCheck = false;
        // Uncheck location if it's checked.
        this.listCheckLocations = this.listCheckLocations.filter(l => l.locationId !== location.locationId);
      }
      location.isChecked = this.allChecked
    });
    this._isUpgradeDowngradeActions();
  }

  private _getTotalLocations() : void {
    // FIXME: Multiple subscriptions!
    this._subscribeSafe(this._locationService.accountAllLocations$, 
     locations => {
      if (this.filteredLocationIds.length > 0)
        this.totalLocations = locations.filter(l => this.filteredLocationIds.includes(l.locationId))  
      else
        this.totalLocations = locations
    });
  }

  selectLocation(location : SavedLocation, check: MatCheckboxChange) : void {
    const isChecked = this.listCheckLocations.find(l => l.locationId == location.locationId);

    if (isChecked) {
      this.listCheckLocations = this.listCheckLocations.filter(l => l.locationId !== location.locationId);
    } else {
      this.listCheckLocations.push({
        locationId: location.locationId,
        pendingChange: location.pendingChange,
        subscriptionType: location.subscriptionType,
        locationName: location.locationName,
        location: location.location,
        checked: check.checked
      });
    }

    this._isUpgradeDowngradeActions();
  }

  private _isUpgradeDowngradeActions() : void {
    if (this.listCheckLocations.length > 0) {
      this.upgradeCheckSelect   = !!this.listCheckLocations.find(l => l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)
      this.downgradeCheckSelect = !!this.listCheckLocations.find(l => l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC || 
                                                                      l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE)
    } else {
      this.upgradeCheckSelect   = false
      this.downgradeCheckSelect = false
    }
  }

  private _generateBulkSubscriptionErrorMessage(unverifiedLocations, locationsChange): string {
    let message = `<div class='txt--left'>
    The following location(s) need to be verified in your Google Business Profile before you can update the subscription(s).
      <div>
      <br>
        <ul>`;    
    unverifiedLocations.forEach((location) => {
      message += `<div>${location?.locationName}</div><li>${this._locationService.formatAddress(location?.location?.address)}</li>`;
    });
  
    message += `</ul>
      </div>`;

    if (locationsChange.length >= 1){
      message += "Would you like to proceed with updating the other locations?"
    }
    message += "</div>"
  
    return message;
  }

  // Function change to plan locations
  public async changePlan(locations : LocationsComponent['listCheckLocations'][0] | LocationsComponent['listCheckLocations']) : Promise<void> {
    if (this._sessionS.getSession().isMember) {
      this._modalService.openWarningModalNoPermissions();
      return;
    }
    let locationsChange : LocationRef[]= []
    const unverifiedLocations = []
    if (!Array.isArray(locations)) {
      if (!this._validateVerifiedStatus(locations as any, true))
        return
    
      locationsChange = [{ locationId: (locations as any).locationId, 
                            accountId:  this.accountObjectId }];
    }
    else {
      locations.forEach(location => {
        if (location?.location?.locationState?.isVerified == false){
          unverifiedLocations.push(location)
        }else {
          locationsChange.push({ locationId: location as any, 
                                 accountId:  this.accountObjectId })
        }
      });
    }

    if (unverifiedLocations.length >= 1 ){
      const ret = await this._modalService.openAlertModal("Verification required in Google Business Profile",
        this._generateBulkSubscriptionErrorMessage(unverifiedLocations, locationsChange),
        AlertType.ERROR,
        null,
        'Ok',
        () => { return true }
      )

      if (!ret || locationsChange.length === 0 ) {
         return
      }
    }

    await this._apiSubscription.flowChangeLocationsPlan(locationsChange)
    // locationService.someLocationsChanged will fire if a plan change was made
  }

  async refreshLocation(locationId: string): Promise<void> {
    if (this.filtered) 
      this.applyFilter(this.searchText);
    try {
      await this._locationService.locationRefreshAllDeps(this.accountObjectId, locationId, this._sessionS.getSession().gid)
    } finally {
      if (this.filtered) 
        this.applyFilter(this.searchText);
    }
  }

  refreshSelected(): void {
    this._snack.openInfo("The selected locations are being refreshed. Please wait while this is completed.");
    let count_refreshed = 0;
    this.listCheckLocations.forEach(async (l) => {
      await this._locationService.locationRefreshAllDeps(this.accountObjectId, l.locationId, this._sessionS.getSession().gid);
      count_refreshed++;
    });

    if (count_refreshed === this.listCheckLocations.length) {
      this._snack.openSuccess("The selected locations was updated");
      this.resetListSelect()
    }

    if (this.filtered) 
      this.applyFilter(this.searchText);
  }

  public async deleteSelected() : Promise<void> {
    if (this.listCheckLocations.length === 1) {
      await this.openDeleteDialog(_.head(this.listCheckLocations));
      return;
    } 

    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect these business profiles from ${domainCompany}?`, 
      `Note: this only disconnects the profiles from ${domainCompany}; it does not delete your business profile from Google Maps. You can always re-add your business profiles to ${domainCompany}.`)) {
        return;
    }

    try {
      this._spinnerService.loading$.next(true);
      const {gid} = this._sessionS.getSession();
      let accountWasDeleted = false
      for(const e of markedPositions(this.listCheckLocations)) {
        accountWasDeleted = await this._locationService.deleteLocation(gid, this.accountObjectId, e.value, 
                                                                       {notifyChange       : e.isLast, 
                                                                        deleteEmptyAccount : e.isLast});
      }

      this.locations = this.locations.filter(l => !this.listCheckLocations.find(lc => lc.locationId === l.locationId));
      this.listCheckLocations = []
      this.allChecked = false
      if(accountWasDeleted) 
        await this._router.navigate(['/accounts'])

      // this._init() will be called by someLocationsChanged subscription. 
    } finally {
      this._spinnerService.loading$.next(false);
    }

  }

  async getAccountsFilter() : Promise<void> {
    try {
      const r = await this._accountService.getAccountPaginate(this._sessionS.getSession().gid, {page: 1, size: 1000}, [])
      this.accountsOptions = [...r.items]
      this.filterAccountsByAccountId()
      this._hasAccountsFilter = true
    } catch (e) {
      console.error(e)
    } finally {
      this._spinnerService.loading$.next(false)
    }
  }

  selectAll(): void {
    if (!this.allLocationCheck) {
      this.allLocationCheck = true;
      this.totalLocations.forEach(location => {
        const result = this.listCheckLocations.find(l => l.locationId == location.locationId);
        if (!result) {
          this.listCheckLocations.push({
            locationId: location.locationId,
            pendingChange: location.pendingChange,
            subscriptionType: location.subscriptionType,
            locationName: location.locationName,
            checked: this.allChecked,
            location: location.location,
          });
        }
      });

      this.locations.forEach(location => {
        this.allChecked = true;
        location.isChecked = this.allChecked
      });
    } else {
      this.listCheckLocations = [];
      this.allLocationCheck = false;
      this.locations.forEach(location => {
        this.allChecked = false;
        location.isChecked = this.allChecked
      });
    }
  }

  private _formatData(locations: SavedLocation[]): SavedLocation[] {
    const {isTrial} = this._sessionS.getSession();

    const existSelected = this.listCheckLocations.length > 0;
    locations = locations.filter(Boolean);
    for (const location of locations) {
      if (location) {
        let status = '';
        if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
          status = (isTrial ? 
                    LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL : 
                    LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
        } else {
          status = location.subscriptionType
        }
        
        location['subscriptionStatus'] = status;

        if(existSelected)
          location.isChecked = !!this.listCheckLocations.find(l => l.locationId === location.locationId)
        
      }
    }
    this.loadingTable$.next(false);

    return locations;
  }

  handleReload($event: Pageable): void {
    this.loadingTable$.next(true);
    this._locationService.setPaginate($event);
    this._paginate = $event;
    if (this.filtered) {
      this.applyFilter(this.searchText);
    } else {
      this._getData($event);
    }
  }

  goLocation(accountId: string, location: SavedLocation): void {
    if (!this._validateVerifiedStatus(location))
      return
    this._router.navigate(['/account', accountId, 'location', location.locationId, 'insights'])
  }

  private _validateVerifiedStatus(location: Pick<SavedLocation, 'location'>, typeIsSubscription = false) : boolean {
    if (location?.location?.locationState?.isVerified == false){

      this._modalService.openErrorModal(
        "Verification required in Google Business Profile",
        `<div class='txt--left'>
        ${typeIsSubscription ? 
          'In order to update your profile subscription, you need to verify your location.' :
          'This location requires verification, which prevents access to your dashboard.' }
        <div>
          <br>Step 1: Log in to your Google Business Profile and verify your location.
          <br>Step 2: Once verified, return and click the refresh button.
        </div>
      </div>`
      )

      return false
    }
    return true
  }

  goNewTab(accountId: string, location: Pick<SavedLocation, 'locationId' | 'location'>, event?: Event): void {
    if (event) 
      event.stopPropagation()
    
    if (!this._validateVerifiedStatus(location))
      return
    
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/account', accountId, 'location', location.locationId, 'insights'])
    );
    window.open(url, '_blank');
  }

  goNewTabSelected(): void {
    for (const location of this.listCheckLocations) 
      this.goNewTab(this.accountObjectId, location)
  }

  getNextDueDate(): string {
    const {nextDueDate} = this._sessionS.getSession().subscription;
     if(nextDueDate) {
      const d = this._locationService.formatDates(nextDueDate).split('-');
      return `${d[1]}/${d[2]}/${d[0]}`;
    }
     return '-';
  }

  async cancelChangePlan(location : LocationsComponent['listCheckLocations'][0]): Promise<void> {
    if(await this._modalService.openConfirmModal('Cancel Listing Plan Change',
                                                 (`This location is scheduled to change subscription plan to ${location.pendingChange.nextPlan} `+
                                                  `on ${this.getNextDueDate()}. `+
                                                  `Do you want to cancel this scheduled change and keep the location as ${location.subscriptionType}?`)))
      try {
        this._spinnerService.loading$.next(true)

        const locationCancel = { locationId: location.locationId, 
                                 accountId: this.accountObjectId }
        if(!await this._apiSubscription.applyChangePlan([locationCancel], location.subscriptionType))
          return

        await this._getData(this._paginate);
        await this._locationService.fetchAccountLocationsAndEmit(this._sessionS.getSession().gid, this.accountObjectId);
      } catch(err) {
        console.error(err);
      } finally {
        this._spinnerService.loading$.next(false)
      }
  }

  filterChanged(account): void {
    this.listCheckLocations = [];
    this.allLocationCheck = true;
    this.selectAll();
    this.loadingTable$.next(true);
    this.accountObjectId = account[0].accountId;
    this._router.navigate([`accounts/${this.accountObjectId}/locations`]);

  } 

  filterAccountsByAccountId(): void {
    const matchingAccount = this.accountsOptions?.find((account) => account?.accountId === this.accountObjectId);
    this.selectedAccount = matchingAccount || null
    this.labelAccountsFiltered = matchingAccount?.account?.accountName || null
  }

  private _updateTable(): void {
    // data for table
    const session = this._sessionS.getSession();

    this.displayedColumns = ['id', 'company']
    
    if (session.subscription.pricingVersion < 3) {
      this.displayedColumns.push('subscription');
    }

    if (session.isAdmin) {
      this.displayedColumns.push('actions');
    }


    // if (this.auth.isAdmin) {
    //   this.displayedColumns = ['id', 'company', 'subscription', 'actions'];
    // } else {
    //   this.displayedColumns = ['id', 'company', 'subscription'];
    // }

    this.dataSource = new MatTableDataSource<SavedLocation[]>(this.locations);
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this._nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  
  private _init() {
    this.filtered = false;
    this.textSearch = '';
    const errorAccount = this._route.snapshot.queryParamMap.get("errorAccount")
    this.errorAccount = (errorAccount?.toLowerCase() === "true")

    if (errorAccount !== null) {
      const url = this._router.url.split('?')[0]
      this._angularLocation.go(url)
    }
    
    this.accountObjectId = this._route.snapshot.paramMap.get('accountObjectId');

    const {gid, user} = this._sessionS.getSession();
    this.locationsUser = (user.accounts || []).find(acc => 
                            acc.accountId === this.accountObjectId)?.locations.map(loc => loc.locationId) || [];

    // TODO: 5s forced delay, why? (single shot)
    if(!this._refreshTimerStarted) {
      this._refreshTimerStarted = true
      this._subscribeSafe(timer(5000), async () => {
        try {
          await this._getData(this._paginate)
          await this._locationService.fetchAccountLocationsAndEmit(gid, this.accountObjectId);
          this._updateTable()
        } finally {
          this._refreshTimerStarted = false
        }
      })
    }

    if (!this._hasAccountsFilter)
      this.getAccountsFilter()
  }
}
