// dep
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MatCheckboxChange, MatDialog, MatDrawer, MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

// app
import { PostService } from '../../../services/post.service';
import { BulkPost, LocalPost } from '../../../v3.models/posts.models';
import { Pagination } from '../../../constants/api-response';
import { GROUP_SUBSCRIPTION_TYPE } from '../../../constants/firestore/account-location';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AlertType } from 'src/app/components/alert.component';
import { ModalService } from 'src/app/services/modal.service';
import { StorageImageService } from 'src/app/services/storage-image.service';
import { PostManagementDeleteDateModalComponent } from 'src/app/modules/post-management/post-management-delete-date-modal/post-management-delete-date-modal.component';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-post-management-list',
  templateUrl: './post-management-list.component.html',
  styleUrls: ['./post-management-list.component.scss']
})
export class PostManagementListComponent implements OnInit, OnChanges {
  @Input() ColumnsSize: number[] = [5, 40, 10, 10, 5, 5, 10];
  @Input() dashboardType: string;
  @Input() accountId: string;
  @Input() locationId: string;
  @Input() focus = new BehaviorSubject(0);
  @Input() postDrawer: any = {};
  @Input() updateList = false;
  @Input() isImpersonating = false;
  @Input() isMember = false;
  
  @Output() isSpinner = new EventEmitter<boolean>()
  @Output() selected  = new EventEmitter<number>()
  @Output() editElement = new EventEmitter<{}>();
  
  public displayedColumns = ['image', 'frequency', 'date', 'status', 'action'];
  public isLoading = false;
  public loadingPost = true;
  public isBulkPost = true;
  public allPostChecked = false;
  public dataSource = new MatTableDataSource<LocalPost | BulkPost>([]);
  public paginate = {page: 1, size: 10};
  public pagination: Pagination;
  public sort = {
    sortBy: 'updatedAt',
    direction: 'desc',
  };
  public statusFilterValue = 'all';
  public statusFilter = {status: [], bulkStatus: []};
  public ocurrenceFilterValue = 'null';
  public ocurrenceFilter = null;
  public noDataMessage = { message: 'No Posts Added Yet', description: null };
  public GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
  public statusData = [];
  public postId = null;
  public isStatusOpened = false;
  public highlightCell: string;
  public postTitle = null;
  public selectedPosts = [];

  private _gid: string;

  session$ = this._sessionS.session$

  constructor(
    private _sessionS : SessionService,
    private _postS: PostService,
    private _storageImageS: StorageImageService,
    private _cdRef: ChangeDetectorRef,
    private _snackS: SnackbarService,
    private _modalS: ModalService,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._gid = this._sessionS.getSession().gid;
    this.isBulkPost = (this.dashboardType !== 'LOCAL_POST')
    this.statusFilter = this.allFilter;
    this._cdRef.detectChanges();
    this.getData();
  }

  getStatusClass(status: string): string {
    status = status.toUpperCase();
    let statusClass = 'status--gray';

    if(status === 'SCHEDULED') {
      statusClass = 'status--blue';
    } else if(status === 'PARTIAL' || status === 'FLAGGED_FOR_DELETE' || status === 'CONTACT_SUPPORT') {
      statusClass = 'status--yellow';
    } else if(status === 'SUCCESS') {
      statusClass = 'status--green';
    } else if(status === 'FAILED'|| status === 'REJECTED' || status === 'DELETED' || status === 'FINISHED') {
      statusClass = 'status--red';
    }

    return statusClass
  } 

  statusLabel(status): string {
    return this._postS.getStatusLabel(status);
  }

  getData(): void {
    this.loadingPost = true;
    this.dataSource = new MatTableDataSource<BulkPost>([]);

    this._postS.getPostList(this._gid, this.isBulkPost, this.paginate, this.sort, this.statusFilter, this.ocurrenceFilter, this.accountId, this.locationId).subscribe(
      result => {
        result?.data?.items.forEach(el => el.is_checked = false);
        this.dataSource = new MatTableDataSource<BulkPost>(result?.data?.items);
        this.pagination = result?.data;
        this.pagination.per_page = result?.data?.pageSize;
        this.pagination.pages = result?.data?.totalPages;

        this.noDataMessage = (
          !this.dataSource?.data?.length && this.dashboardType === 'ARCHIVE' ? 
          { message:`No posts finished or deleted yet.`, description: null } :
          !this.dataSource?.data?.length && this.isFiltered ? 
          { message: `No results found.`, description: `Adjust your filters or select the “All” option to view everything.` } :
          { message: `No posts added yet.`, description: null }
        );

        this.loadingPost = false;
      },
      error => {
        this.dataSource = new MatTableDataSource<BulkPost>([]);
        this.noDataMessage = { message: 'Loading failed', description: 'Content isn’t loading right now. Refresh to try again.' };
        this.loadingPost = false;
      }
    )
  }

  changedOccurenceFilter(occurence): void {
    this.ocurrenceFilter = occurence?.value === 'null' ? null : occurence?.value;
    this.ocurrenceFilterValue = occurence?.value;
    this.getData();
  }

  get allFilter(): {status, bulkStatus} {
    const status = {
      status: ['ACTIVE','DRAFT', 'FLAGGED_FOR_DELETE', 'CONTACT_SUPPORT'],
      bulkStatus: ['SUCCESS', 'DRAFT', 'FAILED', 'SCHEDULED', 'FLAGGED_FOR_DELETE', 'CONTACT_SUPPORT']
    };

    if (this.isBulkPost) {
      status.bulkStatus.push('PARTIAL')
    }

    return status;
  }

  changedStatusFilter(event): void {
    this.statusFilterValue = event?.value;
    const value = event?.value?.split('.');
    const status = value?.[0];
    const bulkStatus  = value?.[1];
    this.statusFilter = {
      status: status != 'all' ? [status] : this.allFilter?.status, 
      bulkStatus: bulkStatus ? [bulkStatus] : this.allFilter?.bulkStatus
    };
    this.getData();
  }

  edit(element: LocalPost | BulkPost): void {
    this._postS.getPost(element?._id).subscribe(
      res => {
        this.editElement.emit(res?.data?.[0]);
      }
    );
  }

  copyPost(element: any): void  {
    this.loadingPost = true;
    if (element?.post?.media &&element?.post?.media[0]?.sourceUrl) { // if the post has media attached to it
      // subscription that should get executed if/when the validations and storage in firestore finish successfuly
      const mediaUrlSubscription = this._storageImageS.multipleMediaUrl$
      .subscribe(value => {
        if(value && value.length) {
          this._postS.copyPost(element, value[0].url)
          .subscribe(
            response => {
              this.getData()
              mediaUrlSubscription.unsubscribe();
              this._storageImageS.reset();
            }, error => {
              this.loadingPost = false;
              this._snackS.openError('There was an error while copying the post', 2000);
              mediaUrlSubscription.unsubscribe();
              this._storageImageS.reset();
            }
          )
        }
      })
        
      this._postS.storageImgInFirestore(element)
      .subscribe(file => {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const inputElement = document.createElement('input') as HTMLInputElement;
        inputElement.type = 'file';
        inputElement.files = dataTransfer.files;
        this._storageImageS.fileChanged(inputElement, false);
      }, error => {
        console.error('Error fetching the image:', error);
      })
    } else { // if no media is attached to the post
      this._postS.copyPost(element)
      .subscribe(
        response => {
          this.getData()
        }, error => {
          this.loadingPost = false;
          this._snackS.openError('There was an error while copying the post', 2000);
        }
      )
    }

    
    // old functionality
    // this._postS.copyPost(element).subscribe(
    //   res => {
    //     this.getData();
    //   },
    //   err => {
    //     this.loadingPost = true;
    //     this._snackS.openError('There was an error while copying the post', 2000);
    //   }
    // );
  }

  getRecurringData(element): string { 
    console.log(element)
    const value = '';
    
    switch(element?.scheduleRules?.type) {
      case 'DAILY':
        return `On ${this._postS.getDaysUnified(element?.scheduleRules?.publishRule?.daysOfWeek)}`;
      case 'WEEKLY':
        return `On ${this._postS.getDaysUnified(element?.scheduleRules?.publishRule?.daysOfWeek)}`
      case 'MONTHLY':
        const value = element?.scheduleRules?.publishRule?.daysOfMonth[0];
        const day = value === -1 ? 'last day' : this._postS.getOrdinalNumbers(value)
        return `On the ${day} of the month`;
    }

    return value
  }

  async infoAboutStatus(element) {
    this.isLoading = true;
    const data =  await this.getInfoRejected(element);

    if(data) {
      this._modalS.openInfoModal(
        data?.title,
        data?.msg,
        'Ok',
        true,
        false
      );
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this._snackS.openError('There was an error while loading the data. Please try again or contact support')
    }
  }

  getInfoRejected(element):Promise<any> {
    return new Promise((resolve, reject) => {
        this._postS.getInfoRejected(element?.['_id'], element?.postInstanceId).subscribe(
            res => {
              const data = {msg: this._postS.getModalText(res), title: `${res?.data?.[0]?.type} Failed`};
              resolve(data);
            },
            err => {
              reject(err);
            }
        );
    });
  } 

  toggleCheckAll(event: MatCheckboxChange): void {
    const isBulk = this.isBulkPost;
    let posts = [];
    this.allPostChecked = event?.checked;
    if(event.checked) {
      this.dataSource.data.map(el => {
        if (isBulk || !isBulk && !el?.['scheduleRules']?.isBulk) {
          el['isChecked'] = event?.checked;
          posts.push(el._id);
          this.selectedPosts = [...new Set(posts)];
        }
      })
    } else {
      this.dataSource.data.map(el => el['isChecked'] = event?.checked);
      this.selectedPosts = [];
    }
  }

  selectPost(element, event: MatCheckboxChange) {
    if (event?.checked) {
      this.selectedPosts.push(element._id);
    } else {
      const index = this.selectedPosts.findIndex(el => el === element._id);
      this.selectedPosts.splice(index, 1);
    }

    const totalOfSelected = this.dataSource.data.filter(el => el['isChecked']).length;
    this.allPostChecked = this.dataSource.data.length === totalOfSelected;
  }

  deletePosts(element: {}, isMultiplePost: boolean): void {
    const message = (
      isMultiplePost ? 
      `Are you sure you want to delete the selected posts? This action cannot be undone.` :
      `Are you sure you want to delete this post? This action cannot be undone.`
    );

    this._modalS.openAlertModal(
      `Attention`,
      message,
      AlertType.ERROR,
      null,
      'Delete Post',
      ((response) => {
        if (response) {
          this.openDeleteDateModal(element, isMultiplePost)
        }
      }),
      'accent'
    );
  }

  openDeleteDateModal(element: {}, isMultiplePost: boolean): void {
    const isRecurring = !isMultiplePost ? element?.['scheduleRules']?.isRecurring : this.dataSource.data.find(el => el['isChecked'])?.['scheduleRules']?.isRecurring;
    
    const dialogRef = this._dialog.open(PostManagementDeleteDateModalComponent, {
      width: '450px',
      data: {
        postIds: isMultiplePost ? element : [element?.['_id']],
        isMultiplePost: isMultiplePost,
        irRecurring: isRecurring
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getData();
      }
    });

    dialogRef.disableClose = true;
  }

  retryPost(element: any) {
    this._postS.retryPost(element,() => {
      this.getData(); 
    });
  }

  openStatus(element:any = {}, drawer: MatDrawer): void {
    if (this.isStatusOpened) { return }
    this.isStatusOpened = true;
    this._postS.getStatusPost(element?.['_id']).subscribe(
      res => {
        this.highlightCell = element?.['_id'];
        this.statusData = res?.data;
        this.postId = element?.['_id'];
        this.postTitle = element?.post?.title || null;
        drawer.toggle();
      },
      err => { 
        this.isStatusOpened = false;
      }
    )
  }
  
  closeStatus(needsRefresh, drawer: MatDrawer): void {
    this.highlightCell = null;
    this.isStatusOpened = false;
    this.statusData = [];
    this.postId = null;
    drawer.toggle();
    this._cdRef.detectChanges();

    if(needsRefresh) {
      this.getData();
    }
  }

  shouldHighlightCell(element): boolean { 
    return this.highlightCell === element?.['_id'];
  }

  isSelected(element): boolean {
    return element?.isChecked;
  }

  handleReload($event) {
    this.paginate = $event;
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.updateList?.currentValue) {
      this.updateList = null;
      this.getData();
    }

    if(changes?.dashboardType && changes?.dashboardType?.previousValue) {
      this.statusFilter.status = (
        this.dashboardType !== 'ARCHIVE' ? this.allFilter.status :
        this.isBulkPost ?
        ['DELETED', 'FINISHED','ACTIVE', 'FLAGGED_FOR_DELETE'] :
        null
      );

      this.statusFilter.bulkStatus = this.dashboardType !== 'ARCHIVE' ? this.allFilter.bulkStatus : ["DELETED", "FINISHED"];
      this.displayedColumns = this.dashboardType !== 'ARCHIVE' ? ['image', 'frequency', 'date', 'status', 'action'] : ['image', 'frequency', 'date', 'status'];
      this.dataSource = new MatTableDataSource<LocalPost | BulkPost>([]);

      this.getData();
    }
  }

  get isFiltered(): boolean {
    const statusFiltered = (
      this.dashboardType === 'ARCHIVE' ? this.statusFilter?.status?.includes('DELETED') && this.statusFilter?.status?.includes('FINISHED') :
      this.statusFilter?.status?.includes('ACTIVE') && this.statusFilter?.status?.includes('DRAFT') && this.statusFilter?.status?.includes('FLAGGED_FOR_DELETE')
      && this.statusFilter?.status?.includes('CONTACT_SUPPORT')
    );
    return statusFiltered && this.ocurrenceFilter === null ? false : true

  }

  get isDeleteButtonEnabled(): boolean {
    /* It will be added later
    const selectedPosts = this.dataSource.data.filter(el => el['isChecked']);
    const firstIsRecurringValue = selectedPosts[0]?.['scheduleRules']?.isRecurring;
    const allAreSame = selectedPosts.every(el => el?.['scheduleRules']?.isRecurring === firstIsRecurringValue);

    return !(selectedPosts.length > 0 && allAreSame);*/

   return this.dataSource.data.filter(el => el?.['bulkStatus'] !=='FLAGGED_FOR_DELETE')?.length === 0
  }

  get countOfSelectedItems(): number {
    return this.dataSource.data.filter(el => el?.['isChecked'])?.length;
  }

  refresh(): void {}

}