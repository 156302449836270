// app
import { MainComponent } from './modules/main/main.component';
import { AccountsComponent } from './modules/accounts/accounts.component';
import { LocationsComponent } from './modules/locations/locations.component';
import { RollupReportComponent } from './modules/rollup-report/rollup-report.component';
import { ReviewReportsComponent } from './modules/review-reports/review-reports.component';
import { ComparisonReportsComponent } from './modules/comparison-reports/comparison-reports.component';
import { RevenueReportsComponent } from './modules/revenue-reports/revenue-reports.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { MetaGuardService } from './services/meta-guard.service';
import { RedirectGuardService as RedirectGuard } from './services/redirect-guard.service';
import { LocationFreeGuardService as LocationFreeGuard } from './services/location-guard.service';
import { BlankLayoutComponent, DefaultLayoutComponent } from './layouts';
import { UpgradeComponent } from './modules/upgrade/upgrade.component';
import { ReviewReportInsightComponent } from './modules/review-report-insight/review-report-insight.component';
import { RegisterComponent } from './modules/register/register.component';
import { RevenueReportInsightComponent } from './modules/revenue-report-insight/revenue-report-insight.component';
import { ComparisonReportInsightComponent } from './modules/comparison-report-insight/comparison-report-insight.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardInsightsComponent } from './modules/dashboard-insights/dashboard-insights.component';
import { DashboardPerformanceInsightComponent } from './modules/dashboard-performance-insight/dashboard-performance-insight.component';
import { DashboardReviewsComponent } from './modules/dashboard-reviews/dashboard-reviews.component';
import { DashboardPostsComponent } from './modules/dashboard-posts/dashboard-posts.component';
import { DashboardLocationComponent } from './modules/dashboard-location/dashboard-location.component';
import { DashboardGradeComponent } from './modules/dashboard-grade/dashboard-grade.component';
import { DashboardWidgetComponent } from './modules/dashboard-widget/dashboard-widget.component';
//import { DashboardReviewLinkComponent } from './modules/dashboard-review-link/dashboard-review-link.component';
import { DashboardSearchComponent } from './modules/dashboard-search/dashboard-search.component';
import { DashboardWorkshopComponent } from './modules/dashboard-workshop/dashboard-workshop.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { ReviewAutoResponderComponent } from './modules/review-auto-responder/review-auto-responder.component';
import { BulkPostComponent } from './modules/bulk-post/bulk-post.component';
import { ExternalGradeComponent } from './modules/external-grade/external-grade.component';
import { TextReaderComponent } from './modules/text-reader/text-reader.component';
import { VerifyEmailComponent } from './modules/verify-email/verify-email.component';
import { LocationInfoListComponent } from './modules/location-info-list/location-info-list.component';
import { DashboardServicesComponent } from './modules/dashboard-services/dashboard-services.component';
import { DashboardPhotosComponent } from './modules/dashboard-photos/dashboard-photos.component';
import { DashboardMenuComponent } from './modules/dashboard-menu/dashboard-menu.component';
import { SearchKeywordsComponent } from './modules/search-keywords/search-keywords-report/search-keywords.component';
import { SearchKeywordsViewComponent } from './modules/search-keywords/search-keywords-view/search-keywords-view.component';
import { QuestionsAnswerComponent } from './modules/questions-answer/questions-answer.component';
import { QuestionsAnswersViewComponent } from './modules/questions-answer/questions-answers-view/questions-answers-view.component';
import { ProfilesComponent } from './modules/profiles/profiles.component';
import { BulkActionsGuardService } from './services/bulk-actions-guard.service';
// TODO: Move to lazy-loading
import { UsersManagementComponent } from './settings/users-management/users-management.component';
import { BillingGuardService } from './services/billing-guard.service';
import { BillingComponent } from './settings/billing/billing.component';
import { PricingGuardService } from './services/pricing-guard.service';
import { BillingNewComponent } from './settings/billing-new/billing-new.component';
import { WidgetComponent } from './widget/components/widget.component';
var ɵ0 = { title: 'Grader' }, ɵ1 = { title: 'Login' }, ɵ2 = { title: 'Forgot password' }, ɵ3 = { title: 'Register', robots: 'noindex, nofollow' }, ɵ4 = {
    allowedSessionTypes: ['NORMAL']
}, ɵ5 = { title: 'Workshop' }, ɵ6 = {
    title: 'Keywords',
    isReport: false
}, ɵ7 = { title: 'Insights' }, ɵ8 = { title: 'Insights' }, ɵ9 = { title: 'Reviews' }, ɵ10 = { title: 'Q & A' }, ɵ11 = { title: 'Posts' }, ɵ12 = { title: 'Posts' }, ɵ13 = { title: 'Location Info' }, ɵ14 = { title: 'Services' }, ɵ15 = { title: 'Menu' }, ɵ16 = { title: 'Photos' }, ɵ17 = { title: 'Grade' }, ɵ18 = { title: 'Review Widget' }, ɵ19 = { title: 'Search' }, ɵ20 = {
    pageNeedsSession: true,
    allowedSessionTypes: ['NORMAL']
}, ɵ21 = { title: 'User Management' }, ɵ22 = { title: 'Plans & Billing' }, ɵ23 = { title: 'Plan and Billing' }, ɵ24 = {
    title: '',
    // Wait the session to be available before instancing/
    // rendering the child component. Will show the spinner
    // meanwhile.
    pageNeedsSession: true,
    allowedSessionTypes: ['NORMAL']
}, ɵ25 = { title: 'Accounts' }, ɵ26 = { title: 'Report' }, ɵ27 = { title: 'Report' }, ɵ28 = {
    title: 'Keywords',
    isReport: true
}, ɵ29 = { title: 'Locations' }, ɵ30 = { title: 'Profiles' }, ɵ31 = { title: 'Bulk Post Management' }, ɵ32 = { title: 'Bulk Post Management' }, ɵ33 = { title: 'Bulk Location Info' }, ɵ34 = { title: 'Q & A' }, ɵ35 = { title: 'Review Assistant' }, ɵ36 = { title: 'Legacy Rollup Report' }, ɵ37 = { title: 'Performance Rollup Report' }, ɵ38 = { title: 'Review Report' }, ɵ39 = { title: 'Comparison Report' }, ɵ40 = { title: 'Comparison Report' }, ɵ41 = { title: 'Revenue Report' }, ɵ42 = { title: 'Revenue Report' }, ɵ43 = {
    title: 'Keywords',
    isReport: true
}, ɵ44 = {
    title: '',
    pageNeedsSession: false
};
// canActivate guards notes:
//   - guards will prevent the full page to be loaded until they resolve, not only 
//     the related child component, so the loader spinner won't work.
//   - mutiple async guards will be resolved concurrently
export var appRoutes = [
    //-------------------------
    // Redirect / => /login
    //-------------------------
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    //----------------------------------------------------------
    // Logged-OFF routes (only requires Domain to be loaded)
    // NO shared container component.
    //----------------------------------------------------------
    {
        path: 'widget/:gid/:accountId/:locationId/review',
        // loadChildren: './widget/widget.module#WidgetModule'
        // loadChildren: () => WidgetModule
        component: WidgetComponent
    },
    {
        path: 'verify',
        component: VerifyEmailComponent
    },
    {
        path: 'text-reader/:text',
        component: TextReaderComponent
    },
    {
        path: 'report/:gid/:id/:reportType/shared',
        component: ReportsComponent
    },
    {
        path: 'report/automated/:gid/:automatedReport/:reportType/shared',
        component: ReportsComponent,
    },
    {
        path: 'reports/:hash/auth',
        component: ReportsComponent,
    },
    {
        path: 'grade',
        component: ExternalGradeComponent,
        data: ɵ0
    },
    //----------------------------------------------------------
    // Logged-OFF routes (only requires Domain to be loaded)
    // BlankLayoutComponent as the shared container component.
    //----------------------------------------------------------
    {
        path: '',
        component: BlankLayoutComponent,
        children: [
            {
                path: 'login',
                data: ɵ1,
                component: LoginComponent
            },
            {
                path: 'forgot-password',
                data: ɵ2,
                component: ForgotPasswordComponent
            },
            {
                path: 'register',
                data: ɵ3,
                canActivate: [MetaGuardService],
                component: RegisterComponent,
            }
        ]
    },
    //----------------------------------------------------------
    // Logged-in routes
    // DashboardLayoutComponent as the shared container component.
    //----------------------------------------------------------
    {
        path: 'account/:accountId/location/:locationId',
        component: DashboardLayoutComponent,
        data: ɵ4,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'workshop',
                component: DashboardWorkshopComponent,
                data: ɵ5,
                canActivate: [LocationFreeGuard],
            },
            {
                path: 'keywords',
                component: SearchKeywordsComponent,
                data: ɵ6,
            },
            {
                path: 'insights',
                component: DashboardPerformanceInsightComponent,
                data: ɵ7
            },
            {
                path: 'legacy-insights',
                component: DashboardInsightsComponent,
                data: ɵ8
            },
            {
                path: 'reviews',
                component: DashboardReviewsComponent,
                data: ɵ9
            },
            {
                path: 'qanda',
                component: QuestionsAnswerComponent,
                data: ɵ10,
            },
            {
                path: 'posts',
                component: DashboardPostsComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ11
            },
            {
                path: 'post-management',
                component: DashboardPostsComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ12
            },
            {
                path: 'location-info',
                component: DashboardLocationComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ13
            },
            {
                path: 'services',
                component: DashboardServicesComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ14
            },
            {
                path: 'menu',
                component: DashboardMenuComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ15
            },
            {
                path: 'photos',
                component: DashboardPhotosComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ16
            },
            {
                path: 'grade',
                component: DashboardGradeComponent,
                data: ɵ17
            }, {
                path: 'widget',
                component: DashboardWidgetComponent,
                canActivate: [LocationFreeGuard],
                data: ɵ18
            },
            // TODO: Unused route, remove. 
            // {
            //   path: 'review-link-generator',
            //   component: DashboardReviewLinkComponent,
            //   canActivate: [LocationFreeGuard],
            //   data: { title: 'Review Link Generator' }
            // },
            {
                path: 'search',
                component: DashboardSearchComponent,
                data: ɵ19
            },
        ]
    },
    //----------------------------------------------------------
    // Logged-IN routes
    // DefaultLayoutComponent as the shared container component.
    // TODO: Some of these use canActivate guards that awaits for the
    // session, so the page is not rendered until the session finishes
    // loading, and the spinner is not shown. 
    //----------------------------------------------------------
    {
        path: 'settings',
        component: DefaultLayoutComponent,
        data: ɵ20,
        canActivate: [AuthGuard],
        // This was configured as lazy loading using:
        //   loadChildren: './settings/settings.module#SettingsModule',
        // But was fragile as the 'ng' build watcher sometimes compiled it and
        // sometimes not, causing a browser runtime error like
        //   "Error: Cannot find module './settings/settings.module'"
        // Also the 'ng serve` command sometimes didn't watch the settings.module.ts,
        // so changing it didn't trigger a recompilation until you also changed
        // 'app.router.ts'
        // Also this was an old lazy-loading syntax, on angular >=9 change to:
        //   loadChildren : () => import('./settings/settings.module').then(m => m.SettingsModule)
        // loadChildren : () => SettingsModule, 
        // Moved to eager loading for now, until upgrading Angular version.
        children: [
            {
                path: '',
                redirectTo: 'users',
                pathMatch: 'full'
            },
            {
                path: 'users',
                data: ɵ21,
                component: UsersManagementComponent
            },
            {
                path: 'billing',
                data: ɵ22,
                canActivate: [BillingGuardService],
                component: BillingComponent
            },
            {
                path: 'plan',
                data: ɵ23,
                canActivate: [PricingGuardService],
                component: BillingNewComponent
            }
        ]
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: ɵ24,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',
                component: MainComponent
            },
            {
                path: 'accounts',
                component: AccountsComponent,
                data: ɵ25
            },
            {
                path: 'report/:gid/:id',
                component: ReportsComponent,
                data: ɵ26
            },
            {
                path: 'report/:gid/:id/:reportType',
                component: ReportsComponent,
                data: ɵ27
            },
            {
                path: 'reports/:id/keywords',
                component: SearchKeywordsComponent,
                data: ɵ28
            },
            {
                path: 'accounts/:accountObjectId/locations',
                component: LocationsComponent,
                data: ɵ29
            },
            {
                path: 'profiles',
                component: ProfilesComponent,
                data: ɵ30
            },
            {
                path: 'location/:locationId/workshop',
                component: DashboardWorkshopComponent,
                canActivate: [RedirectGuard, LocationFreeGuard]
            },
            {
                path: 'local-posts',
                component: BulkPostComponent,
                data: ɵ31,
                canActivate: [BulkActionsGuardService]
            },
            {
                path: 'posts-management',
                component: BulkPostComponent,
                data: ɵ32,
                canActivate: [BulkActionsGuardService]
            },
            {
                path: 'local-bulk',
                component: LocationInfoListComponent,
                data: ɵ33,
                canActivate: [BulkActionsGuardService]
            },
            {
                path: 'qanda',
                component: QuestionsAnswersViewComponent,
                data: ɵ34,
                canActivate: [BulkActionsGuardService]
            },
            {
                path: 'review-assistant',
                component: ReviewAutoResponderComponent,
                data: ɵ35
            },
            {
                path: 'rollup-report',
                component: RollupReportComponent,
                data: ɵ36,
            },
            {
                path: 'performance-rollup-report',
                component: RollupReportComponent,
                data: ɵ37,
                canActivate: [BulkActionsGuardService]
            },
            {
                path: 'review-report',
                component: ReviewReportsComponent,
                data: ɵ38,
            },
            {
                path: 'comparison-report',
                component: ComparisonReportsComponent,
                data: ɵ39,
            },
            {
                path: 'performance-comparison-report',
                component: ComparisonReportsComponent,
                data: ɵ40
            },
            {
                path: 'performance-revenue-report',
                component: RevenueReportsComponent,
                data: ɵ41
            },
            {
                path: 'revenue-report',
                component: RevenueReportsComponent,
                data: ɵ42
            },
            {
                path: 'keywords',
                component: SearchKeywordsViewComponent,
                data: ɵ43
            },
            {
                path: 'upgrade',
                component: UpgradeComponent
            },
        ]
    },
    //----------------------------------------------------------
    // Logged-OFF / Logged-IN routes
    // DefaultLayoutComponent as the shared container component.
    //----------------------------------------------------------
    {
        path: '',
        component: DefaultLayoutComponent,
        data: ɵ44,
        children: [
            {
                path: 'report/:gid/:id/shared',
                component: ReportsComponent
            },
            {
                // TODO: Unused route/component?
                path: 'review-report-insight',
                component: ReviewReportInsightComponent
            },
            {
                // TODO: Unused route/component?
                path: 'revenue-report-insight',
                component: RevenueReportInsightComponent
            },
            {
                // TODO: Unused route/component?
                path: 'comparison-report-insight',
                component: ComparisonReportInsightComponent
            },
            {
                // TODO: Unused route/component?
                path: 'auto-responder',
                component: ReviewAutoResponderComponent
            },
        ]
    },
    //---------------------------------------
    // Redirect * => /login (fallback route)
    //---------------------------------------
    { path: '**',
        redirectTo: '/login'
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44 };
