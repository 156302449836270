import { __awaiter, __generator, __values } from "tslib";
// dep
import * as moment from 'moment';
import { OnInit } from '@angular/core';
// app
import { ModalGetAddOn } from './../../modules/modal-get-addon/modal-get-addon.component';
import { UpdateCardComponent } from './../billing/update-card/update-card.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ModalService } from 'src/app/services/modal.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
var mockData = {
    "pricingVersion": 3,
    "packages": {},
    "pricing": {
        "pkg_core": {
            "tiers": [
                {
                    "locationsQtyMin": 1,
                    "locationsQtyMax": 1,
                    "price": 30,
                    "priceChargeType": "CHARGE_PER_UNIT" /// OR “CHARGE_FLAT” OR “CHARGE_FREE”
                },
                {
                    "locationsQtyMin": 2,
                    "locationsQtyMax": 5,
                    "price": 25,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 6,
                    "locationsQtyMax": 10,
                    "price": 20,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 11,
                    "locationsQtyMax": 25,
                    "price": 15,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 26,
                    "locationsQtyMax": 50,
                    "price": 10,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 50,
                    "price": 10,
                    "priceChargeType": "CHARGE_PER_UNIT"
                }
            ],
            "description": "Core Package"
        },
        "pkg_bulk_actions": {
            "tiers": [
                {
                    "locationsQtyMin": 1,
                    "locationsQtyMax": 1,
                    "price": 0,
                    "priceChargeType": "CHARGE_PER_UNIT" /// OR “CHARGE_FLAT” OR “CHARGE_FREE”
                },
                {
                    "locationsQtyMin": 2,
                    "locationsQtyMax": 5,
                    "price": 20,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 6,
                    "locationsQtyMax": 10,
                    "price": 15,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 11,
                    "locationsQtyMax": 25,
                    "price": 10,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 26,
                    "locationsQtyMax": 50,
                    "price": 5,
                    "priceChargeType": "CHARGE_PER_UNIT"
                },
                {
                    "locationsQtyMin": 50,
                    "price": 5,
                    "priceChargeType": "CHARGE_PER_UNIT"
                }
            ],
            "description": "Bulk Actions Add-On"
        }
    }
};
var BillingNewComponent = /** @class */ (function () {
    function BillingNewComponent(_sessionS, _subService, _paymentService, _modalService, _snack, navigationS) {
        this._sessionS = _sessionS;
        this._subService = _subService;
        this._paymentService = _paymentService;
        this._modalService = _modalService;
        this._snack = _snack;
        this.navigationS = navigationS;
        this.pricingData = mockData.pricing; // should hold the real data
        this.isLoading = true;
        this.daysLeftInTrial = 0;
        this.trialProgress = 0;
        this.brandYellow = '#FBBC05';
        this.listColumns = ['number', 'date', 'status', 'amount', 'actions'];
        // we create an IPricingObject object and populate it with the info we require to build the template
        this.pricingObject = {
            unitPrice: 0,
            unitDiscount: 0,
            activePacks: [],
            bulkPrice: 0,
            bulkDiscount: 0,
            totalPrice: 0,
            totalLocations: 0,
            trialActive: false,
            trialDaysTotal: 0,
            trialStart: '',
            trialEnd: '',
            trialProgress: 0,
            trialLeft: 0,
            coreTiers: [],
            bulkActionsTiers: [],
            billingOverride: false,
            invoices: [],
            nextInvoice: '',
            cards: [],
        };
    }
    BillingNewComponent.prototype.ngOnInit = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var _d, subscription, group, momentDate1_1, momentDate2_1, trialDuration_1, momentDate1, momentDate2, trialDuration;
            return __generator(this, function (_e) {
                _d = this._sessionS.getSession(), subscription = _d.subscription, group = _d.group;
                this.subscription = subscription;
                if (this.subscription) {
                    this.fetchInvoices();
                    this.fetchCards();
                    // dates in this object are prone to cause errors if they are missing in the subscription
                    // they shouldn't be missing, but error handling logic should be implemented in the future
                    this.pricingObject.billingOverride = this.subscription.billingOverride || false;
                    this.pricingObject.nextInvoice = this.subscription.nextDueDate || '';
                    this.pricingObject.trialStart = this.subscription.trialStart || '';
                    this.pricingObject.trialEnd = this.subscription.trialEnd || '';
                    this.pricingObject.trialActive = (this.subscription.status === 'TRIAL');
                    this.pricingObject.totalLocations = ((_a = this.subscription.products) === null || _a === void 0 ? void 0 : _a.length) || 0;
                    if ((_c = (_b = this.subscription) === null || _b === void 0 ? void 0 : _b.packages) === null || _c === void 0 ? void 0 : _c.pkg_bulk_actions) {
                        this.pricingObject.activePacks.push('pkg_bulk_actions');
                    }
                    this.getTiers(); // requires totalLocations
                    this.pricingObject.unitPrice = this.getPricing(this.pricingObject.totalLocations, 'core');
                    this.pricingObject.bulkPrice = this.getPricing(this.pricingObject.totalLocations, 'bulk');
                    this.pricingObject.totalPrice = (this.pricingObject.unitPrice - this.pricingObject.unitDiscount + this.pricingObject.bulkPrice) * this.pricingObject.totalLocations;
                    momentDate1_1 = moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                    momentDate2_1 = moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                    trialDuration_1 = momentDate1_1.diff(momentDate2_1, 'days');
                    // if today is after trialEnd then daysLeftInTrial has to be 0
                    if (moment().isAfter(moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) {
                        // if (moment().isAfter(moment(tend, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) { // for testing
                        // trialProgress should be 100, because it has finished
                        this.pricingObject.trialProgress = 100;
                        this.pricingObject.trialLeft = 0;
                    }
                    else {
                        // else, daysLeftInTrial should be equal to trialDuration minus the number of days since trialStart until today
                        this.daysLeftInTrial = trialDuration_1 - moment().diff(moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days');
                        // this.daysLeftInTrial = trialDuration - moment().diff(moment(tstart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days'); // for testing
                        this.pricingObject.trialLeft = this.daysLeftInTrial;
                        // trialProgress should be equal to daysLeftInTrial divided by trialDuration multiplied by 100
                        this.pricingObject.trialProgress = ((trialDuration_1 - this.daysLeftInTrial) * 100) / trialDuration_1;
                    }
                    this.isLoading = false;
                }
                else {
                    console.error('no subscription');
                    this.isLoading = false;
                }
                this.getTiers(); // requires totalLocations
                this.pricingObject.unitPrice = this.getPricing(this.pricingObject.totalLocations, 'core');
                this.pricingObject.bulkPrice = this.getPricing(this.pricingObject.totalLocations, 'bulk');
                this.pricingObject.totalPrice = (this.pricingObject.unitPrice - this.pricingObject.unitDiscount + this.pricingObject.bulkPrice) * this.pricingObject.totalLocations;
                momentDate1 = moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                momentDate2 = moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                trialDuration = momentDate1.diff(momentDate2, 'days');
                // if today is after trialEnd then daysLeftInTrial has to be 0
                if (moment().isAfter(moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) {
                    // if (moment().isAfter(moment(tend, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) { // for testing
                    // trialProgress should be 100, because it has finished
                    this.pricingObject.trialProgress = 100;
                    this.pricingObject.trialLeft = 0;
                    this.pricingObject.trialActive = false;
                }
                else {
                    this.pricingObject.trialActive = true;
                    // else, daysLeftInTrial should be equal to trialDuration minus the number of days since trialStart until today
                    this.daysLeftInTrial = trialDuration - moment().diff(moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days');
                    // this.daysLeftInTrial = trialDuration - moment().diff(moment(tstart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days'); // for testing
                    this.pricingObject.trialLeft = this.daysLeftInTrial;
                    // trialProgress should be equal to daysLeftInTrial divided by trialDuration multiplied by 100
                    this.pricingObject.trialProgress = ((trialDuration - this.daysLeftInTrial) * 100) / trialDuration;
                }
                this.isLoading = false;
                return [2 /*return*/];
            });
        });
    };
    BillingNewComponent.prototype.fetchInvoices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, invoices;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this._subService.fetchInvoices(gid)]; // <-- move this to ngOnInit, its async
                    case 1:
                        invoices = _a.sent() // <-- move this to ngOnInit, its async
                        ;
                        this.pricingObject.invoices = invoices.data.filter(function (el) { return ['paid', 'pending', 'pre authorized', 'unpaid', 'canceled'].includes(el.status.toLowerCase()); });
                        this.pricingObject.invoices.reverse();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.onDownloadInvoicePDF = function (element) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var invId, blob, error_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!((_a = element) === null || _a === void 0 ? void 0 : _a.invoicePDF) || ((_b = element.invoicePDF.task) === null || _b === void 0 ? void 0 : _b.status) !== 'OK') {
                            return [2 /*return*/];
                        }
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        invId = ((_d = (_c = element) === null || _c === void 0 ? void 0 : _c._id) === null || _d === void 0 ? void 0 : _d.$oid) || '';
                        return [4 /*yield*/, this._subService.getInvoicePDF(invId).toPromise()];
                    case 2:
                        blob = _e.sent();
                        this._downloadFile(blob, "invoice_" + invId + ".pdf");
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _e.sent();
                        this._snack.openError('There was an issue downloading the invoice');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.fetchCards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cards;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._paymentService.getCards(this._sessionS.getSession().gid)];
                    case 1:
                        cards = _a.sent();
                        this.pricingObject.cards = cards || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.onDownloadInvoice = function () {
    };
    BillingNewComponent.prototype.onAddAddons = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // show add-ons modal
                    return [4 /*yield*/, this._modalService.openModal(ModalGetAddOn, {
                            title: 'Add-On: Bulk Actions and Roll-Up Reports',
                            message: '',
                            buttons: {
                                'cancel': 'Maybe Later',
                                'accept': 'Get Add-On'
                            }
                        }, {
                            config: {
                                width: '830px'
                            }
                        })];
                    case 1:
                        // show add-ons modal
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.onAddCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openModal(UpdateCardComponent, {})];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.fetchCards()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.handleDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openConfirmModal("Set as default", "Are you sure you want use this Credit Card as the default one?", null, null, "Confirm", false, null, false)];
                    case 1:
                        if (_a.sent()) {
                            this.applyDefaultSource(card.card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.applyDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._paymentService.setDefaultSource(this._sessionS.getSession().gid, card.id)];
                    case 1:
                        _a.sent();
                        this._snack.openSuccess('Set as default Credit Card succesfully');
                        this.fetchCards();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.handleDeleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openConfirmModal("Remove Credit Card", "Are you sure you want to remove this Credit Card?", null, null, "Confirm", false, null, false)];
                    case 1:
                        if (_a.sent()) {
                            this._deleteSource(card.card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype.getTiers = function () {
        // commented until after July 9th deployment
        // const coreTiers = this.subscription?.pricing?.pkg_core?.tiers;
        // const bulkTiers = this.subscription?.pricing?.pkg_bulk_actions?.tiers;
        var _this = this;
        var coreTiers = this.pricingData.pkg_core.tiers;
        var bulkTiers = this.pricingData.pkg_bulk_actions.tiers;
        coreTiers.forEach(function (tier) {
            var profileSegment = "" + tier.locationsQtyMin + (tier.locationsQtyMax && tier.locationsQtyMax > 1
                ? '-' + tier.locationsQtyMax
                : !tier.locationsQtyMax
                    ? '+'
                    : '');
            var isActive = _this.pricingObject.totalLocations >= tier.locationsQtyMin &&
                (tier.locationsQtyMax === undefined || _this.pricingObject.totalLocations <= tier.locationsQtyMax);
            var t = {
                locationsQtyMin: tier.locationsQtyMin,
                locationsQtyMax: tier.locationsQtyMax,
                price: tier.price,
                profileSegment: profileSegment,
                segmentPriceString: "" + (tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'),
                isActive: isActive
            };
            _this.pricingObject.coreTiers.push(t);
        });
        bulkTiers.forEach(function (tier) {
            var profileSegment = "" + tier.locationsQtyMin + (tier.locationsQtyMax && tier.locationsQtyMax > 1
                ? '-' + tier.locationsQtyMax
                : !tier.locationsQtyMax
                    ? '+'
                    : '');
            var isActive = _this.pricingObject.totalLocations >= tier.locationsQtyMin &&
                (tier.locationsQtyMax === undefined || _this.pricingObject.totalLocations <= tier.locationsQtyMax);
            var t = {
                locationsQtyMin: tier.locationsQtyMin,
                locationsQtyMax: tier.locationsQtyMax,
                price: tier.price,
                profileSegment: profileSegment,
                segmentPriceString: "" + (tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'),
                isActive: isActive
            };
            _this.pricingObject.bulkActionsTiers.push(t);
        });
    };
    BillingNewComponent.prototype.getPricing = function (locations, pkgName) {
        var e_1, _a, e_2, _b;
        var _c, _d, _e, _f, _g, _h;
        switch (pkgName) {
            case 'core':
                try {
                    for (var _j = __values((_e = (_d = (_c = mockData) === null || _c === void 0 ? void 0 : _c.pricing) === null || _d === void 0 ? void 0 : _d.pkg_core) === null || _e === void 0 ? void 0 : _e.tiers), _k = _j.next(); !_k.done; _k = _j.next()) {
                        var tier = _k.value;
                        if (locations >= tier.locationsQtyMin &&
                            (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                            return tier.price;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_k && !_k.done && (_a = _j.return)) _a.call(_j);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                break;
            case 'bulk':
                if (this.pricingObject.activePacks.includes('pkg_bulk_actions')) {
                    try {
                        for (var _l = __values((_h = (_g = (_f = mockData) === null || _f === void 0 ? void 0 : _f.pricing) === null || _g === void 0 ? void 0 : _g.pkg_bulk_actions) === null || _h === void 0 ? void 0 : _h.tiers), _m = _l.next(); !_m.done; _m = _l.next()) {
                            var tier = _m.value;
                            if (locations >= tier.locationsQtyMin &&
                                (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                                return tier.price;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_m && !_m.done && (_b = _l.return)) _b.call(_l);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                else {
                    return 0;
                }
                break;
            default:
                return 0;
        }
    };
    BillingNewComponent.prototype._deleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._paymentService.deleteCard(this._sessionS.getSession().gid, card.id)];
                    case 1:
                        _a.sent();
                        this._snack.openSuccess('Removed Credit Card succesfully');
                        this.fetchCards();
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingNewComponent.prototype._downloadFile = function (blob, filename) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    return BillingNewComponent;
}());
export { BillingNewComponent };
