import { __awaiter, __generator } from "tslib";
// dep
import { AfterViewChecked, ChangeDetectorRef, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, ReplaySubject, forkJoin } from 'rxjs';
// app
import { ReviewsService } from '../../services/reviews.service';
import { LocationService } from '../../services/location.service';
import { ReportService } from '../../services/report.service';
import { SnackbarService } from '../../services/snackbar.service';
import { EventMessageService } from 'src/app/services/event-message.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { LoadingService } from '../../services/loading.service';
import { LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";
import { EventNames } from '../../constants/event-names';
import { SessionService } from 'src/app/services/session.service';
// TODO: Unused, remove
// export interface ReviewKeywords {
//   id: number;
//   text: string;
//   weight: number;
//   score: number;
// }
var DashboardReviewsComponent = /** @class */ (function () {
    function DashboardReviewsComponent(_route, _reviewsS, _locationS, _sessionS, _reportS, _cdRef, _snackS, _em, _modalService, _subscriptionService, _loadingService) {
        this._route = _route;
        this._reviewsS = _reviewsS;
        this._locationS = _locationS;
        this._sessionS = _sessionS;
        this._reportS = _reportS;
        this._cdRef = _cdRef;
        this._snackS = _snackS;
        this._em = _em;
        this._modalService = _modalService;
        this._subscriptionService = _subscriptionService;
        this._loadingService = _loadingService;
        this.isShared = false;
        this.lockDates = false;
        this.reportType = 'review';
        this.viewModeChecked = 'legacy';
        this.minDate = null;
        this.maxDate = null;
        this.exporting = false;
        this.reviewsStats = null;
        this.isReportListAccordionOpen = false;
        this.refreshFilter = new ReplaySubject(1);
        this.resume = new Subject();
        // progress events
        this.isProgressResume = true;
        this.isProgressDistribution = true;
        this.loadingMuchData = false;
        this.loadingAvereageAndReview = false;
        this.rating = null;
        this.first = true;
        this.reviewsData = {};
        this.averageData = {};
        this.locationId = this._route.snapshot.parent.params.locationId;
        this.accountId = this._route.parent.snapshot.params.accountId;
        this.locations = [{ accountId: this.accountId,
                locationId: this.locationId }];
    }
    DashboardReviewsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dateValidations, dates, subscriptionType;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.reportDataPicker) return [3 /*break*/, 1];
                        this.gid = this._route.snapshot.params.gid;
                        this.isReport = true;
                        this.locations = this.reportDataPicker.locations;
                        this.reportDataPicker.aggregation = this._reportS.correctDateAggregate(this.reportDataPicker);
                        this.dataPicker = this.reportDataPicker;
                        this.selectDate = this._reportS.selectDatesFromDataPicker(this.dataPicker);
                        return [3 /*break*/, 4];
                    case 1:
                        this.gid = this._sessionS.getSession().gid;
                        return [4 /*yield*/, this._locationS.getDateValidations('review', [this.accountId], [this.gid], [this.locationId])];
                    case 2:
                        dateValidations = _a.sent();
                        dates = this._locationS.dateValidation(dateValidations);
                        this.minDate = dates.minDate;
                        this.maxDate = dates.maxDate;
                        return [4 /*yield*/, this._locationS.fetchMultipleLocations(this.gid, [{ accountId: this.accountId,
                                    locationId: this.locationId }], ['subscriptionType'])];
                    case 3:
                        subscriptionType = (_a.sent())[0].subscriptionType;
                        this.selectDate = (this.minDate && this.maxDate && (subscriptionType != LOCATION_SUBSCRIPTION_TYPE.FREE &&
                            subscriptionType != LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) ?
                            { start: this.minDate,
                                end: this.maxDate } :
                            this._locationS.buildDatepickerDate(this.reportType, this.maxDate));
                        _a.label = 4;
                    case 4:
                        this._em.getEmitter().subscribe(function (res) {
                            if (res.name != EventNames.EVENT_DATEPICKER_SUBSCRIPTION) {
                                return;
                            }
                            var group = _this._sessionS.getSession().group;
                            var dateDismiss = group.dismissModalDatePicker || new Date();
                            if (new Date() >= dateDismiss) {
                                _this._modalService.openModal(FeaturedDatePickerComponent, {
                                    accountId: _this.accountId,
                                    locationId: _this.locationId
                                }, { onClose: function (res) {
                                        if (!res)
                                            return;
                                        console.log('DashboardReviewsComponent upgrade suscription');
                                        _this._subscriptionService.flowChangeLocationsPlan([_this.locations[0]]);
                                    } });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * this method handle event change in date picker and get data
     */
    DashboardReviewsComponent.prototype.handleChange = function ($event) {
        if (this.isReport && this.first) {
            this.getReviewData(this.dataPicker);
            this.first = false;
        }
        else {
            this.dataPicker = $event;
            this.getReviewData(this.dataPicker);
        }
        this.refreshFilter.next(this.dataPicker);
    };
    // TODO: Refactor against protocol-report.component.ts / resumeManyLocations
    /**
     * this method get all data of charts when change date picker
     */
    DashboardReviewsComponent.prototype.getReviewData = function (dataPicker, refresh) {
        var _this = this;
        if (refresh === void 0) { refresh = false; }
        var _a, _b;
        this.isProgressResume = true;
        this.isProgressDistribution = true;
        this.loadingAvereageAndReview = true;
        if (!this.locations || !this.dataPicker) {
            return;
        }
        this.dataPicker.aggregation = ((_a = this.report) === null || _a === void 0 ? void 0 : _a.aggregation) || ((_b = dataPicker) === null || _b === void 0 ? void 0 : _b.aggregation);
        var url = this.isReport ? this._reviewsS.getRatingsAndReviewsReportData(this.reportId, this.dataPicker) : this._reviewsS.getRatingsAndReviewsData(this.accountId, this.gid, this.locationId, this.dataPicker);
        var accountsId = !this.isReport ? [this.accountId] : this.report.accounts.map(function (item) { var _a; return (_a = item) === null || _a === void 0 ? void 0 : _a.accountId; });
        var locationsId = !this.isReport ? [this.locationId] : this.report.accounts.flatMap(function (item) { var _a; return (_a = item) === null || _a === void 0 ? void 0 : _a.locations.map(function (location) { return location.locationId; }); });
        forkJoin([
            this._locationS.getReviewSummary(this.gid, accountsId, locationsId),
            this._reviewsS.ratingDistribution(this.locations).take(2),
            url
        ]).subscribe(function (result) {
            var _a, _b, _c, _d, _e, _f, _g;
            _this.reviewsStats = {
                averageRating: (_a = result[0]) === null || _a === void 0 ? void 0 : _a.totalAverageRating,
                totalReviewCount: (_b = result[0]) === null || _b === void 0 ? void 0 : _b.totalReviewCount,
                answered: (_c = result[0]) === null || _c === void 0 ? void 0 : _c.totalAnswered,
                notAnswered: (_d = result[0]) === null || _d === void 0 ? void 0 : _d.totalNotAnswered,
                googleResume: {
                    totalReviewCount: (_e = result[0]) === null || _e === void 0 ? void 0 : _e.googleResume_totalReviewCount
                }
            };
            _this.differenceAverage = (_f = result[0]) === null || _f === void 0 ? void 0 : _f.difference_totalAverage;
            _this.differenceTotal = (_g = result[0]) === null || _g === void 0 ? void 0 : _g.difference_totalTotal;
            _this.rating = result[1].data;
            _this._loadingService.reportAdvanced(1, 'Rating Distribution');
            _this.reviewsData = {
                data: result[2].reviews,
                labels: result[2].dates,
                total: result[2].total_reviews
            };
            _this.averageData = {
                data: result[2].avg_rating,
                labels: result[2].dates,
                total: result[2].total_avg
            };
            _this._loadingService.reportAdvanced(1, 'Review Summary');
            refresh && _this._snackS.openSuccess('Finished refreshing! ', 2000);
            _this.loadingAvereageAndReview = false;
            _this.isProgressDistribution = false;
            _this.isProgressResume = false;
        }, function (error) {
            _this.isProgressDistribution = false;
            _this.isProgressResume = false;
        });
    };
    DashboardReviewsComponent.prototype.ngAfterViewChecked = function () {
        this.detectChanges();
    };
    DashboardReviewsComponent.prototype.detectChanges = function () {
        if (!this._cdRef.destroyed)
            this._cdRef.detectChanges();
    };
    DashboardReviewsComponent.prototype.ngOnDestroy = function () {
        var _a, _b, _c;
        (_a = this.ratingDistribution$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.keywordsByStat$) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        (_c = this.googleReviewsWithLastMonth$) === null || _c === void 0 ? void 0 : _c.unsubscribe();
        // FIXME: use this.resume.complete()
        this.resume.unsubscribe();
    };
    DashboardReviewsComponent.prototype.handleRefresh = function () {
        this.getReviewData(this.dataPicker, true);
    };
    DashboardReviewsComponent.prototype.isExporting = function (value) {
        this.exporting = value;
    };
    DashboardReviewsComponent.prototype.exportCSV = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var reportId, accountId, locationId, type;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (event != 'csv' && event != 'csv-review')
                            return [2 /*return*/];
                        reportId = this.isReport ? this.reportId : null;
                        accountId = !this.isReport ? this.accountId : null;
                        locationId = !this.isReport ? this.locationId : null;
                        this.isProgressCSV = true;
                        type = event === 'csv' ? 'review' : 'review_list';
                        return [4 /*yield*/, this._reportS.handleExportCsvMongo(reportId, this.gid, accountId, locationId, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation, type)];
                    case 1:
                        _a.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DashboardReviewsComponent;
}());
export { DashboardReviewsComponent };
